<template>
  <div class="ImageComp">
    <div class="ImageComp__img">
      <img 
        :src="require('./images/'+ indice +'.png')" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImageComp',
  props: ['indice'],
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.ImageComp
  color orange
  text-align center

  &__img
    position relative
    height 35vh

    &.temporaire
      width 35vh
      border-radius 50%
      background rgba(#a5a5a5, .8)

    img
      display block
      height 100%

</style>
