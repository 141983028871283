import Settings from '@/config/Settings'
import { Mesh, MeshBuilder, StandardMaterial, Texture, Vector3, Curve3 } from 'babylonjs'
import 'babylonjs-loaders'

export default {

  data: () => ({

    coef: Settings.matrix.coef,
    square_size: Settings.matrix.square_size,
    width: Settings.matrix.scene_size.width,
    height: Settings.matrix.scene_size.height,
    column: Settings.matrix.column,
    start: Settings.matrix.start,
    row: Settings.matrix.row,
    crx: Settings.matrix.correctif_x,
    cry: Settings.matrix.correctif_y,
    orx: 0,
    ory: 0,

  }),

  created() {
  },

  methods: {

    createGround(attr, image, scene) {
      var material = new StandardMaterial('', scene)
      if(image) {
          var texture = new Texture(image, scene)  
          material.diffuseTexture = texture
          material.diffuseTexture.hasAlpha = true
          material.opacityTexture = texture
          material.emissiveTexture = texture
      }
      var mesh = new MeshBuilder.CreateGround('', attr, scene) 
          mesh.material = material       
      return mesh
    },

    getCurveLine(path) {
      var points3d = []
      _.each(this.$path, (pt, i)=>{ 
        points3d.push(this.getPoint3d(pt)) 
      })
      return new Curve3.CreateCatmullRomSpline(points3d, 50)
    },

    getPos(pt) {

      let ix = pt[0]
      let iy = pt[1]

      if(pt[1] <= 26 && pt[0] <=40)
        iy += 2

      if(pt[0] >= 40 && pt[0] <= 42 && pt[1] <= 25)
        ix += 2
    
      const x = -((((ix*this.square_size)/this.coef)+((this.square_size/2/this.coef)))-((this.column/2)*this.square_size)/this.coef)
      const y = ((((iy*this.square_size)/this.coef)+((this.square_size/2/this.coef)))-((this.row/2)*this.square_size)/this.coef)
    
      // console.log(pt,x,y);

      return [x,y]
    },

    getPoint3d (pt) {
      const pos = this.getPos(pt)
      return new Vector3(pos[0], 0, pos[1])
    },

  }
}





