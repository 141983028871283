<script>
import Mixin from '@/core/mixins/CameraFinal'
import { Vector2, Viewport } from 'babylonjs'
import 'babylonjs-loaders'
import Settings from '@/config/Settings'

export default {
    name: 'CameraBellHowell',
    mixins: [ Mixin ],
    render: h => h(),
    data: ()=>({
        fov: .8, // 10mm = 1.6 | 12.5mm = 1.5| 30mm  = 1.4| 35mm = 1.3| 50mm = .8 | 75mm = .6| 100mm = .4
    }),
    computed: {
      iris() { return this.$store.getters['user/iris'] },
    },
    watch:{
      iris(nw, od){

        console.log(nw);

        if(nw == 'close')
          this.showIris()
        else 
          this.hideIris()
      }
    },
    beforeDestroy() {
      if(this.$tween)
        this.$tween.kill()
      this.hideIris()
      this.$processing.vignetteEnabled = false
      this.$processing.contrast = 1
    },
    created() {
      this.$engine = this.$parent.$engine
      this.$camera.rotation.x = 15 * (Math.PI/180)
      this.$camera.fov = this.fov
      if(Settings.scene_translations[this.lang][this.$route.params.scene] == 'jeu') {
        this.$nextTick(()=>{
          this.$target.position.z = -1.5
          this.$target.position.y = 2.5
        })
      }
    },
    mounted() {

      // console.log('Camera Bell & Howell '+ this.iris);

      this.$nextTick(()=>{
        this.$store.commit('user/SHOW_AVATAR', false)
        this.muteAnimation(true)
        this.updateCanvas()
        this.addPostProcess()
        // document.addEventListener('keypress', this.onKeyPress)
      })

    },
    methods: {

      onKeyPress() {
        this.showIris()
      },

      hideIris() {
        this.$processing.vignetteWeight = 0
        this.$processing.vignetteStretch = 0
      },

      showIris() {
        const obj = { x: 0 }
        this.$tween = TweenMax.to(obj, 3, { 
          x: 200, 
          ease: Linear.easeOut,
          onUpdate: ()=>{
            this.$processing.vignetteWeight = obj.x
            this.$processing.vignetteStretch = obj.x*Math.round(window.innerHeight/window.innerWidth)
          } })
        TweenMax.delayedCall(4, ()=>this.hideIris())
      },

      addPostProcess() {

        const baw = new BABYLON.BlackAndWhitePostProcess("baw", 1.0, this.$camera)
        const blur = new BABYLON.BlurPostProcess("blur", new Vector2(1.0, 1.0), 1.0, 1.0, this.$camera)
              // blur.kernel = 12
              blur.kernel = 2

              //blur.direction.x = 1.2
              //blur.direction.y = 1.2

        const grain = new BABYLON.GrainPostProcess("grain", 1, this.$camera)
              grain.intensity = 5
              grain.animated = true 

        // const tone = new BABYLON.TonemapPostProcess("tone", BABYLON.TonemappingOperator.Photographic, 1, this.$camera)
        //       tone._options = 1

        this.$processing = new BABYLON.ImageProcessingPostProcess("processing", 1.0, this.$camera)
        this.$processing.vignetteEnabled = true
        this.$processing.contrast = 1.8

        // this.$processing.vignetteWeight = 200
        // this.$processing.vignetteStretch = 200

      },
    },
}
</script>


