import '@babel/polyfill'
import _ from 'lodash'
import Vue from 'vue'
import Axios from 'axios'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import VueLazyload from 'vue-lazyload'
import Settings from '@/config/Settings'

import App from '@/App.vue'
import store from '@/vuex/Store'
import { mappingRoutes } from '@/Route.js'
import TranslationMixin from '@/core/mixins/Translation'
import CommonsMixin from '@/mixins/Commons'
import LangMixin from '@/core/mixins/Lang'
// import VueAnalytics from 'vue-analytics'
import VueGtag from "vue-gtag"

import '@/core/js/Copyright'
import { TweenMax, CSSPlugin, TimelineMax, Expo, Back, ScrollToPlugin, DirectionalRotationPlugin, BezierPlugin } from 'gsap/all'
// because of three shaking
const gsapPlugins = [ TweenMax, CSSPlugin, TimelineMax, Expo, Back, ScrollToPlugin, DirectionalRotationPlugin, BezierPlugin ]

import '@/commons/js/gsap/ThrowPropsPlugin'
import 'gsap-babylon-plugin'

const axiosOrigin = Axios.create({ baseURL: '/wp-json/origin/v1' })
Vue.prototype.$origin = axiosOrigin

const axios = Axios.create({ baseURL: '/wp-json/acf/v3' })
Vue.prototype.$http = axios
Vue.use(VueRouter)
Vue.use(Meta,{ keyName: 'head' })
Vue.use(VueLazyload)
Vue.mixin(TranslationMixin)
Vue.mixin(CommonsMixin)
Vue.use(VueGtag, { config: { id: "G-GVN91MP06E" } })

const init = async () => {

  window.__ENV = {}
  window.__ENV['ROUTES'] = [{
    id: 40,
    name: "experience",
    component: "experience/Experience",
    en: "/shoot-your-own-scene/:introduction?/:scene?/:camera?/:items?/:path?/:process?/:single?/:double?",
    fr: "/tourne-ton-film/:introduction?/:scene?/:camera?/:items?/:path?/:process?/:single?/:double?"
  }]

  const router = new VueRouter({
    mode: 'history',
    routes: mappingRoutes(window.__ENV['ROUTES'])
  })

  new Vue({
    el: '#App',
    store,
    router,
    mixins: [ LangMixin ],
    translations: {
      en: { title: 'My Camera and Me', subtitle: 'Shoot your own Scene' },
      fr: { title: 'Ma caméra et moi', subtitle: 'Tourne ton film' },
    },
    head() {
      return {
        title: this.trans('subtitle'),
        titleTemplate: '%s | '+ this.trans('title'),
      }
    },
    data: () => ({
      width: window.outerWidth,
      height: window.outerHeight
    }),
    beforeCreate() {
      this.$store.commit('app/LANG_CHANGED', this.$route.meta.lang)
      this.$store.commit('app/SET_FROM_ROUTE', this.$route)
      this.$store.commit('app/SET_TO_ROUTE', this.$route)
    },
    created() {
      window.addEventListener('resize', this.onResize)
      this.$sizes =  {
        phone: 767,
        tabletPortrait: 768,
        tablet: 1024,
      }
    },
    mounted() {
      console.log(this.$route);
      this.onResize()
    },
    methods: {
      onResize() {
        this.width = window.outerWidth
        this.height = window.outerHeight
      },
      below(type) { return this.width <= this.$sizes[type] },
      above(type) { return this.width >= this.$sizes[type] }
    },
    render: h => h(App)
  })
}

init()
