 <template>
  <div class="CameraWitness" ref="witness">
    <div ref="container" class="CameraWitness__container"> 
      <div ref="mask" class="CameraWitness__mask"></div>
    </div>
    <div class="CameraWitness__footer"><h6 class="center">{{ trans('txt') }}</h6></div>
  </div>
</template>

<script>

import { FreeCamera, Camera, Vector3, Viewport } from 'babylonjs'
import 'babylonjs-loaders'
import Settings from '@/config/Settings'

export default {

  name: 'CameraWitness',
  data: () => ({
    actived: false,
    witness: 'lens',
  }),

  translations: {
    en: { txt: "This is what you will see through the lens." },
    fr: { txt: "Voici ce que tu filmes à travers l'objectif." },
  },

  beforeDestroy() { 
    this.$witness.position.y = 0
    this.$scene.activeCameras = []
    // // this.$scene.activeCamera = this.$camera
    // // this.$camera.viewport = new BABYLON.Viewport(0, 0, 1, 1)
  },

  watch: {

    '$parent.actived'(nw, od) {
      if(!this.actived)
        this.actived = true
      else if(nw) {
        this.updateViewport()
        this.hideMask()
      }
      else 
        this.showMask()
    },
    actived(nw, od) {  
      TweenMax.to(this.$parent.$refs.block_right, .5, { 
        x: 0, 
        ease: Power2.easeOut, 
        delay: .5, 
        onComplete: ()=>{ 
          this.updateViewport()
          this.hideMask()
         }
      })
    }
  },

  created() {  

    this.$scene = this.$parent.$parent.$babylon.scene
    this.$camera = this.$parent.$parent.$babylon.camera
    this.$scene.activeCameras.push(this.$camera)
    this.$witness = this.$scene.getCameraByName('lens') 

    if(Settings.scene_translations[this.lang][this.$route.params.scene] == 'jeu') {
      this.$nextTick(()=>{
        this.$witness.position.y = 1
      })
    }

    // console.log(this.$witness.position);
    // // this.$lens = this.$scene.getMeshByName('cameraman_lens')
    // // TweenMax.to(this.$lens, 1, { babylon: { rotationX: 67, rotationY: 0, y: 15 } })
  },

  mounted() {

    // // const size = window.innerWidth*.36/2
    // // const margin = (size < 484) ? size : 484
    // // const ix = margin/1.5
    // // this.$vx = .8-(ix/window.innerWidth)

    // this.updateViewport()

    // const viewport = this.updateViewport()

    // this.$witness.viewport = new BABYLON.Viewport(viewport.x, viewport.y, viewport.width, viewport.height)
    // this.$witness.viewport = new BABYLON.Viewport(0, 0, 0, 0)

    this.resetViewport()

    this.$scene.activeCameras.push(this.$witness)
    TweenMax.set(this.$parent.$refs.block_right,  { x: window.innerWidth*.5 })

    // this.$tween = new TimelineMax({})
    //     .set(this.$el, { css:{ width: (window.innerWidth*.2)+2, height: (window.innerHeight*.2)+54}})
    //     .from(this.$el, .5, { alpha: 0, ease: Linear.easeNone, onComplete: ()=>{ this.$scene.activeCameras.push(this.$witness) }})
    //     .to(this.$refs.mask, .5, { alpha: 0, ease: Linear.easeNone, delay: .5 })
  },

  methods:{

    showMask() {
      TweenMax.to(this.$refs.mask, .25, { opacity: 1, ease: Linear.easeNone, onComplete: ()=>{ this.resetViewport() } })
    },

    hideMask() {
      TweenMax.to(this.$refs.mask, .25, { opacity: 0, ease: Linear.easeNone })
    },

    getViewportPos(bounding) {
      const min = 0
      const pos = {}
      _.each(['x', 'y', 'width', 'height'],  type =>{
        const max = type == 'x' || type == 'width' ? window.innerWidth : window.innerHeight
        let val = bounding[type]
        if(type == 'x')
          val += 1
        if(type == 'y')
          val = window.innerHeight - val - bounding.height + 1
        pos[type] = (val - min) / (max - min)
      })
      return pos
    },
    updateViewport() {
      const bounding = this.$refs['container'].getBoundingClientRect()
      const viewport = this.getViewportPos(bounding)
      this.$witness.viewport = new BABYLON.Viewport(viewport.x, viewport.y, viewport.width, viewport.height)   
    },
    resetViewport() {
       this.$witness.viewport = new BABYLON.Viewport(0, 0, 0, 0) 
    },

    onClick() {
    //   return
    //   this.witness = (this.witness == 'lens') ? 'cameraman' : 'lens'
    //   this.indice = (this.witness == 'lens') ? '+' : '-'
    //   this.updateWitness(this.witness)
    }, 
    // updateWitness(indice) {
    //   this.$scene.activeCameras = []
    //   if(indice === 'lens') {
    //     this.$scene.activeCameras.push(this.$camera)
    //     this.$scene.activeCameras.push(this.$witness)
    //     this.$witness.viewport = new BABYLON.Viewport(this.$vx, .8, .2, .2)
    //     this.$camera.viewport = new BABYLON.Viewport(0, 0, 1, 1)
    //   } else {
    //     this.$scene.activeCameras.push(this.$witness)
    //     this.$scene.activeCameras.push(this.$camera)
    //     this.$camera.viewport = new BABYLON.Viewport(this.$vx, .8, .2, .2)
    //     this.$witness.viewport = new BABYLON.Viewport(0, 0, 1, 1)
    //   }
    // },
  }

}
</script>

<style lang="stylus">

@import '~@/config/Settings'

.CameraWitness
  width calc(36vw * 0.6)
  max-width calc(484px * 0.6)

  &__container
    height calc(36vw * .6)
    max-height calc(484px * .6)
    border 1px solid orange
    position relative

    &__mask 
      background blue-dark
      width 100%
      height 100%

  &__footer
    width calc(100% + 2)
    border 1px solid orange
    border-top none
    padding 24px 48px

  &__mask
    position absolute
    background-color #0e0b5c
    width 100%
    height 100%
 
</style>






















