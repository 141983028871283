<script>

import { Mesh, SceneLoader, FreeCamera, Vector3, StandardMaterial, Texture, MeshBuilder } from 'babylonjs'
import 'babylonjs-loaders'

export default {

  name: 'Cameraman',
  render: h => h(),
  data: ()=>({
    angle: 2.4,
    current: 'idle',
    used: false,
    standby: null
  }),
  beforeDestroy() {
    _.each(this.$meshes, m => m.dispose())
  },

  created() {

    this.$meshes = []
    this.$scene = this.$parent.$scene
    this.$container = this.$parent.createMesh('', this.$scene)
    this.$container.parent = this.$parent.$cameraman

    this.$container.position.y = .175

    this.$buddy = this.$parent.createMesh('buddy', this.$scene)
    this.$buddy.parent = this.$container

    this.$avatar = this.avatar['babylon'].instantiateModelsToScene()
    this.$avatar.rootNodes[0].parent = this.$buddy
    this.$container.isVisible = false

    this.$target = this.$parent.createMesh('cameraman_target', this.$scene)
    this.$target.parent = this.$container

    this.$tracking = this.$parent.createMesh('cameraman_tracking', this.$scene)
    this.$tracking.parent = this.$container
    this.$tracking.position.x = 0
    this.$tracking.position.z = -1.25
    this.$tracking.position.y = 2

    const tracking = new FreeCamera('tracking', new Vector3(0, 0, 0), this.$scene)
          tracking.parent = this.$tracking
          tracking.minZ = .1

    this.$scene_tracking = this.$parent.createMesh('scene_tracking', this.$scene)
    this.$scene_tracking.position.x = 0
    this.$scene_tracking.position.z = 0
    this.$scene_tracking.position.y = 2

    this.$camera_scene_tracking = new FreeCamera('camera_scene_tracking', new Vector3(0, 0, 0), this.$scene)
    this.$camera_scene_tracking.parent = this.$scene_tracking
    this.$camera_scene_tracking.minZ = .1

    this.$lens = this.$parent.createMesh('cameraman_lens', this.$scene)
    this.$lens.parent = this.$container
    this.$lens.position.x = 0
    this.$lens.position.z = 1
    this.$lens.position.y = 2

    const lens = new FreeCamera('lens', new Vector3(0, 0, 0), this.$scene)
          lens.parent = this.$lens
          lens.rotation.x = 15 * (Math.PI/180)
  },

  mounted() {

    // document.addEventListener('keypress', this.onKeyPress)  

    const rename = {
      'Idle' : 'idle',
      'Cycle' : 'cycle',
      'Spin' : 'spin',
      'Pan' : 'pan',
      'Jog' : 'jog',
      'Jump' : 'jump',
      'CrouchDown' : 'down',
      'CrouchUp' : 'up',
      'IntoCycle' : 'intocycle',
      'IdlePose' : 'idle',
      'WalkFront' : 'front',
      'WalkLeft' : 'left',
      'WalkRight' : 'right',
      'WalkBack' : 'back',
      'WalkfFront' : 'front',
      'StepLeft' : 'left',
      'StepRight' : 'right'
    }

    // console.log(this.$avatar);

    _.each(this.$avatar.animationGroups, (a)=>{
      // console.log(a.name);
      this['$'+ rename[a.name] +'_anime'] = a
    })

    if(this.camera.id != 52)
      this.$idle_anime.play()
    // else
    //   this.resetPan()

    // this.$idle_anime = this.$avatar.animationGroups.find(a => a.name === 'Idle')
    // this.$idle_anime.play(true)

    // this.$left_anime = this.$avatar.animationGroups.find(a => a.name === 'WalkLeft')
    // this.$right_anime = this.$avatar.animationGroups.find(a => a.name === 'WalkRight')
    // this.$front_anime = this.$avatar.animationGroups.find(a => a.name === 'WalkFront')
    // this.$back_anime = this.$avatar.animationGroups.find(a => a.name === 'WalkBack')

    // BOLEX :
    // ------------------
    // WalkfFront

    // GOPRO : 
    // ------------------
    // Jog
    // WalkFront
    // WalkBack
    // StepLeft
    // StepRight
    // IdlePose
    // Crouch_Up
    // Crouch_Down
    // Jump

    // PORTAPAK :
    // ------------------
    // 90Left_Out
    // 90Left_Into
    // 90Right_Out
    // 90Right_Into
    // WalkLeft
    // WalkRight
    // WalkFront

    // CINEMATOGRAPHE 
    // ------------------
    // Idle
    // Cycle
    // IntoCycle

  },

  methods: {

    onKeyPress() {
      this.resetPan()
    },

    showAvatar() {

      // console.log('showAvatar');

      _.each(this.$avatar.rootNodes[0].getChildMeshes(), c => {
        c.isVisible = true
      })
    },

    hideAvatar() {

      // console.log('hideAvatar');

      _.each(this.$avatar.rootNodes[0].getChildMeshes(), c => {
        c.isVisible = false
      })
    },

    resetPan() {
      // console.log('resetPan');
      this.$pan_anime.pause()
      this.$pan_anime.play()
      this.$pan_anime.goToFrame(1)
      this.$pan_anime.stop()
    },

    startRecording() {
      // this.$avatar.animationGroups[1].play(true)
    },

    addElements() {
      this.$circle = this.createGround({ width: 1.02, height: 1.02 }, require('@/commons/img/circle.png'), this.$scene)
      this.$circle.parent = this.$container
      this.$circle.position.z = .05
      this.$circle.position.y = 0
    },

    removeElements() {
      if(this.$circle) this.$circle.dispose()
    },

    createGround(attr, image, scene) {
      let material = new StandardMaterial('', scene)
      if(image) {
          let texture = new Texture(image, scene)  
          material.diffuseTexture = texture
          material.diffuseTexture.hasAlpha = true
          material.opacityTexture = texture
          material.emissiveTexture = texture
      }
      let mesh = new MeshBuilder.CreateGround('', attr, scene) 
          mesh.material = material       
      return mesh
    },

    onMotion(nw) {
      const res = nw.split("_")
      const from = res[0]
      const to = res[1]
      const obj = { from: 1, to: 0 }
      this.current = to
      this.$tween = TweenMax.to(obj, 1, { 
        from: 0,
        to: 1,
        onStart: ()=>{
          this.used = true
          this['$'+ to +'_anime'].play(true)
        },
        onUpdate: ()=>{
          this['$'+ from +'_anime'].setWeightForAllAnimatables(obj.from)
          this['$'+ to +'_anime'].setWeightForAllAnimatables(obj.to)
        },
        onComplete: ()=>{   
          this['$'+ from +'_anime'].stop()

          if(to == 'up'
          || to == 'down')
            this['$'+ to +'_anime'].stop()

          if(this.standby) {
            this.onMotion(this.standby)
            this.standby = null
          } 
          else 
            this.used = false
        }
      })
    },
  },

  computed: {
    avatar() { return this.$store.getters['user/avatar'] },
    userScene() { return this.$store.getters['user/scene'] },
    camera() { return this.$store.getters['user/camera'] },
    motion() { return this.$store.getters['user/motion'] },
    show_avatar() { return this.$store.getters['user/show_avatar'] },
  },

  watch: {

    show_avatar(nw, od) {

      // console.log('show_avatar '+ nw);

      if(nw)
        this.showAvatar()
      else
        this.hideAvatar()
    },

    motion(nw, od) {
      if(this.camera.id == 86) {
        this.$spin_anime.play(true)
        return
      }
      else if(this.camera.id == 52) {

        // TweenMax.delayedCall(1, ()=>this.$pan_anime.play(true))
        // this.$cycle_anime.play(true)

        TweenMax.delayedCall(.5, ()=>{
          this.$pan_anime.start(false, 1, 1, 485)
        })

        // this.onMotion('pan')

        return
      }
      if(this.used) {
        this.standby = nw
        return
      }
      this.onMotion(nw)
    },
    '$route': {
      immediate: true,
      handler() {

        const angle = {
          86: { name: "CameraCinematographe", value: -180 },
          52: { name: "CameraBellHowell", value: -200 }, 
          56: { name: "CameraBolex", value: -180 }, 
          96: { name: "CameraPortapak", value: -180 }, 
          94: { name: "CameraAaton", value: -180 },
          88: { name: "CameraGoPro", value: -180 }
        }
        this.$nextTick(()=>{

          TweenMax.delayedCall(0.1, ()=>{ this.$buddy.rotation.y = angle[this.camera.id].value * (Math.PI/180) })

          // if(this.$route.params.single != undefined) 
          //   this.hideAvatar()
          // else 
          //   this.showAvatar()

          if(this.camera.id == 52) {
            this.$pan_anime.stop()
            this.resetPan()
          }
          if(this.$route.params.items && !this.$route.params.path) 
            this.addElements()
          else 
            this.removeElements()
          
          if(this.$route.params.process
          || this.$route.params.items && !this.$route.params.path) 
            this.$avatar.animationGroups[1].pause()
        })
      }
    },
  },

}
</script>




















