import Vue from 'vue'
import Cookies from 'js-cookie'
import DefaultLanguage from '@/core/js/DefaultLanguage'
import Settings from '@/config/Settings'

const route = {
  name: null,
  meta: {
    lang: DefaultLanguage,
    name: null
  }
}

export default {
  namespaced: true,
  state: {
    lang: DefaultLanguage,
    mode: Settings.mode,
    menuIsOpen: false,
    fullScreenVideo: {
      isOpen: false,
      video: {},
      cb: null,
      type: 'video',
      hideBg: false
    },
    route: {
      from: route,
      to: route
    }
  },
  mutations: {
    'OPEN_FULLSCREENVIDEO' (state, payload) {
      state.fullScreenVideo.isOpen = true
      state.fullScreenVideo.video = payload.video
      state.fullScreenVideo.cb = payload.cb
      state.fullScreenVideo.type = payload.type || 'video'
      state.fullScreenVideo.hideBg = payload.hideBg
    },
    'CLOSE_FULLSCREENVIDEO' (state, cb) {
      if(state.fullScreenVideo.cb)
        state.fullScreenVideo.cb()

      state.fullScreenVideo.isOpen = false
      state.fullScreenVideo.video = {}
      state.fullScreenVideo.cb = null
      state.fullScreenVideo.hideBg = false
    },
    'SET_NAVIGATION' (state, bool) {
      state.menuIsOpen = bool
    },
    'SET_MODE' (state, mode) {
      state.mode = mode
    },
    'RESET_MODE' (state) {
      console.log(Settings.mode);
      state.mode = Settings.mode
    },
    'SET_FROM_ROUTE' (state, from) {
      state.route.from = from
    },
    'SET_TO_ROUTE' (state, to) {
      state.route.to = to
    },
    'LANG_REFRESH' (state) {
      const l = state.lang
      state.lang = 'none'
      state.lang = l
    },
    'LANG_CHANGED' (state, lang) {
      state.lang = lang
      Cookies.set(Settings.cookies.lang, state.lang)
    },
  },
  getters: {
    fullScreenVideo: state => state.fullScreenVideo,
    lang: state => state.lang,
    mode: state => state.mode,
    route: state => state.route,
    menuIsOpen: state => state.menuIsOpen,
  }
}
