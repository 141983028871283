<template>
  <div class="Introduction">

    <div class="Introduction__hello" v-if="!actived">
      <div class="Introduction__title" v-html="trans('title')"/>
      <div class="Introduction__txt" v-html="trans('hello')"/>
      <BtnGeneral :txt="trans('active')" @click="isActived()"/>
    </div>

    <transition name="swiper-container">
      <div class="swiper-container" v-if="actived">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(key, i) in keys">
            <SlideComp :indice="i"/>
          </div> 

        </div>
        <div class="swiper-pagination" ref="pagination"/>
      </div>
    </transition>
 
    <BtnSlider type="left" @click="goToPrev()" v-if="actived && pos > 0"/>
    <BtnSlider ref="slide_right" type="right" @click="goToNext()" v-if="actived && pos < 4"/>
   
      <div class="Introduction__close" v-if="actived">
        <BtnGeneral :txt="trans('start')" @click="onClick()" :class="{ 'is-inactive': pos != keys.length-1 }"/>
      </div>

    <div class="Page__header" v-if="actived">    
      <h1 ref="title">{{ trans('title_'+ pos) }}</h1>
      <h4 ref="description">{{ trans('description_'+ pos) }}</h4>
    </div>

    <button :aria-label="trans('pass')" class="Introduction__pass" @click="skip()" @mouseenter="mouseenter" v-if="actived">
      <div class="Introduction__pass_txt">{{ trans('pass') }}</div> 
      <img :src="require('@/commons/img/arrow_right.png')">
    </button>

    <!--  
      <router-link 
        ref="button" 
        :aria-label="trans('btn')"
        @click.native="onClick()"
        to="" 
        class="Btn">
          {{ trans('btn') }}
      </router-link> 
    -->

    <!-- 
      <span v-else class="Btn is-inactive">{{ trans('btn') }}</span> 
    -->
    
  </div>
</template>

<script>

import BtnSlider from '@/components/btn-slider/BtnSlider'
import BtnGeneral from '@/components/assets/BtnGeneral'
import ImageComp from './components/Image'
import SlideComp from './components/Slide'
import Swiper from 'swiper/js/swiper.min.js'
require('swiper/css/swiper.css')

export default {

  name: 'Introduction',
  components: { BtnSlider, ImageComp, BtnGeneral, SlideComp },
  beforeDestroy() {
  },
  data: () => ({ 
    actived: false,
    keys: [ 'introduction_1', 'introduction_2', 'introduction_3', 'introduction_4', 'introduction_5' ],
    sounds: [ 'salut', 'suis_moi', 'introduction_scene', 'tournage_complete', 'recommencer' ],
    pos: 0,
  }),
  translations: {
    en: {
      
      title: 'Shoot your own Scene',
      btn: 'Introduction',
      hello: "Hello! I would like to come along</br>with you as you complete the activity.</br>Will you let me join you?",
      active: 'Activate',
      start: 'Start',
      title: 'Shoot your own Scene',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',

      title_0: 'Follow me!',
      description_0: "I have a filmmaking activity for you to try.",

      title_1: "Become a filmmaker",
      description_1: "Shoot a scene inspired by a film clip.",

      title_2: "See the world in new ways",
      description_2: "Discover different cameras.",

      title_3: "Watch and compare",
      description_3: "Cameras influence the way we capture the world.",

      title_4: "Choose another camera or a different scene",
      description_4: "Start over or visit other sections of the website.",

      pass: "Skip intro" 

    },
    fr: {

      title: 'Tourne ton film',
      btn: 'Introduction',
      hello: "Salut! J'aimerais t'accompagner</br>de vive voix au cours de ton expérience.</br>Est-ce que tu me permets?",
      active: 'Activer',
      start: 'Débuter',
      title: 'Tourne ton film',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',

      title_0: 'Suis-moi!',
      description_0: "J'ai une expérience de tournage pour toi.",

      title_1: "Deviens cinéaste",
      description_1: "Tourne une scène inspirée d'extraits de films.",

      title_2: "Regarde le monde autrement",
      description_2: "Découvre différentes caméras.",

      title_3: "Visionne et compare",
      description_3: "Les caméras influencent notre façon de capturer le monde.",

      title_4: "Choisis une autre caméra ou une autre scène",
      description_4: "Recommence l'expérience ou explore d'autres sections du site.",

      pass: "Passez l'introduction" 
    },
  },

  created() {
    this.$camera = this.$parent.$babylon.camera
    this.$store.commit('user/SET_SLIDE', this.pos)
    TweenMax.set(this.$camera, { babylon: { rotation: 0, x: 0, y: 2, z: 0 } })
    if(this.changed)
      this.actived = true
  },

  // mounted() {
  //   this.$nextTick(()=>{
  //     // this.Initialize()
  //   })
  // },

  computed: {
    changed() { return this.$store.getters['user/changed'] },
    sound_ended() { return this.$store.getters['user/sound_ended'] },
  },

  watch: {
    'actived'(nw) {
      if(nw) {
        this.$nextTick(()=>{ 
          this.Initialize()
        })
      }
    },
    'sound_ended'(nw) {
      if(nw && this.$swiper.activeIndex < 4)
        TweenMax.delayedCall(1, ()=>this.$swiper.slideNext())
    },
  },

  methods: {

    mouseenter() {
      this.$store.commit('user/SET_SOUND', 'tertiaire_hover')
    },
    skip() {
      this.$store.commit('user/SET_SOUND', 'tertiaire_click')
      this.onClick()
    },  
    onClick() { 
      this.$tl.to('.Introduction__pass', .5, { opacity: 0 })
      this.$store.commit('user/USE_TRANSITION', { introduction: 'camera' })
    }, 
    isActived() {
      this.actived = true
    },
    goToPrev() {
      this.$swiper.slidePrev()
    },
    goToNext() {
      this.$swiper.slideNext()
    },
    Initialize() {
      this.$swiper = new Swiper('.swiper-container', {
        'observer': true,
        pagination: {
          el: this.$refs.pagination,
          clickable: true
        }
      })
      this.$swiper.on('slideChangeTransitionStart', ()=> {
        this.pos = this.$swiper.activeIndex
        this.$store.commit('user/ADD_NARRATION', null)
        this.$store.commit('user/SET_SOUND', 'primaire_click')
        this.$store.commit('user/SET_SLIDE', this.pos)

        if(this.$tween)
          this.$tween.kill()

        this.$tween = TweenMax.delayedCall(.5, ()=>{
          this.updadeNarration(this.pos)
        })
 
      })
      this.addIntro()
    },
    updadeNarration(index) {
      this.$audio = this.getAudio(this.sounds[index])
      this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
    },
    addIntro() {
      this.$nextTick(()=>{
        this.$tl = new TimelineMax({ 
          paused: true
        })
        this.$tl.staggerFrom([ this.$refs.title, this.$refs.description, '.swiper-pagination' ], .5, { y: 75, opacity: 0, ease: Cubic.easeOut }, .25)
        this.$tl.from([this.$refs.slide_right.$el ], .5, { x: 100, opacity: 0, ease: Cubic.easeOut })
        this.$tl.set('.Introduction__close', { y: 150 })
        this.$tl.to('.Introduction__close', .5, { y: 0, opacity: 1 })
        this.$tl.to('.Introduction__pass', .5, { opacity: 1 })
        this.$tl.call(()=>{ this.updadeNarration(0) })
        const delay = (this.changed > 0) ? 1.5 : 3
        TweenMax.delayedCall(delay, ()=>{
          this.$tl.play()
        })
      })
    },
  },
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Introduction
  width 100vw
  height 100vh
  color orange
  position absolute
  top 0
  left 0
  display flex
  justify-content center 
  align-items center
  background-image url('./components/images/introduction.png')
  background-size cover
  background-position repeat repeat

  &__title 
    margin-bottom 42px
    font-size 2.4vw

  &__hello
    display flex
    flex-direction column
    justify-content center 
    align-items center
    width 600px
    text-align center

  &__txt
    margin-bottom 48px
    font-family montserrat
    font-size 1.5rem
    line-height 1.9rem
    font-weight 600

  &__close
    position absolute
    bottom 0
    margin-bottom 124px
    opacity 0

  &__pass
    font-weight bold
    font-family montserrat
    font-size 14px
    position absolute
    display flex
    cursor pointer
    top 32px
    right 48px
    z-index 9999999
    opacity 0

    & img
      padding-top 6px

    &:hover 
      .Introduction__pass_txt
        transform translateX(-12px)

  &__pass_txt
    padding 4px 24px  
    transition opacity .5s, transform .25s cubic-bezier(0.000, 0.000, 0.580, 1.000)

  .swiper-container 
    width 100vw
    height 50vh
    opacity 1
    transition opacity .5s

    &-enter
    &-leave-active
      opacity 0

  .swiper-slide
    text-align center
    font-size 18px
    display -webkit-box
    display -ms-flexbox
    display -webkit-flex
    display flex
    -webkit-box-pack center
    -ms-flex-pack center
    -webkit-justify-content center
    justify-content center
    -webkit-box-align center
    -ms-flex-align center
    -webkit-align-items center
    align-items center

  .swiper-pagination
    opacity 1

    .swiper-pagination-bullet 
      background-color rgba(blue, 0)
      border 2px solid 
      width 14px
      height 14px
      opacity .5
      transition transform .25s

      &:hover 
        transform scale(1.25)

    .swiper-pagination-bullet-active 
      opacity 1
      background-color orange


</style>














