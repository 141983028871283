<template>
  <div class="WelcomeBack">
    <button class="Btn" :aria-label="trans('continue')" @click="onContinue()">{{ trans('continue') }}</button>
  </div>
</template>

<script>

export default {
  name: 'WelcomeBack',
  translations: {
    en: { continue: "Continue experience" },
    fr: { continue: "Poursuivre l'expérience" },
  },
  methods: {
    onContinue() {
      // this.$parent.onContinue()
    },
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.WelcomeBack
  position fixed
  top 0
  left 0
  height 100vh
  width 100vw
  background rgba(#13125b, 1)
  z-index 1000

  display flex
  align-items center
  justify-content center

</style>























