<template>
  <div class="Environment" />
</template>

<script>
import { GizmoManager, Texture, MeshBuilder, Mesh, MotionBlurPostProcess, StandardMaterial, Color3 } from 'babylonjs'

export default {
  name: 'Environment',
  beforeDestroy() {
    _.each(this.$garbage, g => g.dispose())
  },
  created() {
    const scene = this.$parent.$babylon.scene
    const camera = this.$parent.$babylon.camera
    this.$garbage = []

    // gizmo
    // const gizmoManager = new GizmoManager(scene)
    // gizmoManager.positionGizmoEnabled = true
    // this.$garbage.push(gizmoManager)

    // dome
    const skyMaterial = new StandardMaterial("skyMaterial", scene)
    skyMaterial.backFaceCulling = false
    skyMaterial.disableLighting = true
    skyMaterial.infiniteDistance = true
    skyMaterial.emissiveColor = Color3.FromHexString('#13125b')
    skyMaterial.sideOrientation = Mesh.DOUBLESIDE

    const skybox = Mesh.CreateBox("skyBox", 200, scene)
    skybox.material = skyMaterial

    this.$garbage.push(skybox)

    //ground
    const ground = MeshBuilder.CreatePlane("ground", { size: 15 }, scene)
    TweenMax.set(ground, { babylon: { rotationX: 90, z: 13 } })
    this.$garbage.push(ground)

    const groundMaterial = new StandardMaterial('ground', scene)
    const groundTexture = new Texture(require('./img/ground.png'))
    groundMaterial.diffuseColor = Color3.FromHexString('#4124fb')
    groundMaterial.diffuseTexture = groundTexture
    groundMaterial.opacityTexture = groundTexture
    ground.material = groundMaterial


    // const mb = new MotionBlurPostProcess('mb', scene, 1.0,  camera)
    // mb.motionStrength = 5
  }
}
</script>
