<template>
  <transition  @enter="enter" @leave="leave" appear>
    <div class="FullscreenVideo">
      <VueFocusTrap ref="trap" @open="focusFirst" @gofirst="focusFirst" @golast="focusLast">
        <div class="FullscreenVideo__header">
          <button ref="close" class="FullscreenVideo__button" @click="close()"><div class="Sub">x</div></button>
        </div>
        <div class="FullscreenVideo__container">
          <div class="FullscreenVideo__title">
            {{ fullScreenVideo.video[lang].title }}
          </div>
          <div class="FullscreenVideo__description" v-html="fullScreenVideo.video[lang].description" />
          <div id="vimeo" class="FullscreenVideo__player">
            <iframe :src="video_id" width="640" height="400" frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
          </div> 
          <div class="FullscreenVideo__bottom">
            <div class="FullscreenVideo__legend" v-html="fullScreenVideo.video[lang].legend"/>
            <div class="FullscreenVideo__transcription">
              <div class="FullscreenVideo__transcription-header">
                <button class="FullscreenVideo__transcription-open" @click="onToggle()">
                  {{ trans('transcription') }}
                  <div class="FullscreenVideo__transcription-chevron" :class="{ closed: closed }">
                    <svg ref="chevron" viewBox="0 0 12 8">
                      <path fill="currentColor" d="M11.8 2.2L6 8 .2 2.2 1.6.8 6 5.2 10.4.8l1.4 1.4z"></path>
                    </svg>
                  </div>
                </button>
              </div>
              <div 
                class="FullscreenVideo__transcription-txt" 
                :class="{ closed: closed }"> 
                  <div v-html="fullScreenVideo.video[lang].transcription"/>
                  <div class="FullscreenVideo__transcription-gradient"/>
                </div>
              <button class="FullscreenVideo__transcription-close" v-if="!closed" @click="onToggle()">{{ trans('close') }}</button>
              <div class="FullscreenVideo__btn">
                <BtnGeneral :txt="trans('close_box')" @click="close()"/>
              </div>
            </div>
          </div>
        </div>
      </VueFocusTrap>
    </div>
  </transition>
</template>

<script>

import Page from '@/core/mixins/Page'
import { VueFocusTrap } from "vue-a11y-utils"
import BtnGeneral from '@/components/assets/BtnGeneral'

export default {
  name: 'FullscreenVideo',
  components: { VueFocusTrap, BtnGeneral },
  data: () => ({
    closed: true,
    video_id: null
  }),
  translations: {
    fr: {
      close: 'Fermer',
      close_box: 'Fermer la boîte de dialogue',
      transcription: 'Transcription'
    },
    en: {
      close: 'Close',
      close_box: 'Close dialog box',
      transcription: 'Transcript'
    }
  },
  // watch: {
  //   caption() {
  //     this.$player.toggleCaptions(this.caption)
  //   }
  // },
  computed: {
    fullScreenVideo() { return this.$store.getters['app/fullScreenVideo'] }
  },

  beforeDestroy() {
    this.$store.commit('user/ADD_NARRATION', 'close_video')
  },
  mounted() {
    this.video_id = this.fullScreenVideo.video[this.lang].vimeo
    this.$nextTick(() => {
      this.$refs.trap.open()
      this.$refs.video.pause()
    })
    this.$store.commit('user/ADD_NARRATION', 'open_video')
  },
  methods: {
    enter(el, done) {
      TweenMax.to(this.$el, .1, { alpha: 1, ease: Linear.EaseNone, onComplete: ()=>{ done() }, delay: .2 })
    },
    leave(el, done) {
      done()
    },
    onToggle() {
      this.closed = !this.closed
    },
    close() {
      this.$store.commit('user/SET_SOUND', 'secondary_click')
      this.$refs.trap.close()
      this.$store.commit('app/CLOSE_FULLSCREENVIDEO')
    },
    focusFirst() {
      this.$refs.close.focus()
    },
    focusLast() {
      this.$refs.close.focus()
    },
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.FullscreenVideo
  height 100vh
  position fixed
  width 100vw
  z-index 500000
  top 0
  left 0
  transition opacity 1s
  background blue-dark
  color white
  overflow-y scroll
  padding-bottom 64px
  opacity 0

  & em
    font-style italic

  &__header
    height 135px
    display flex
    justify-content flex-end
    align-items center
    padding-right 1.5rem
    padding-top 8px

  &__container 
    max-width 48rem
    margin-left auto
    margin-right auto
    padding-left 1rem

  &__bottom 
    padding 1rem

  &__title 
    display block
    font-size 1.17em

  &__description
    margin-top 2.5rem
    margin-bottom 2.5rem
    font-family montserrat
    font-weight 600
    font-size 1.125rem
    line-height 1.3846

  &__legend 
    display flex
    flex-direction column
    justify-content center
    align-items center
    font-family montserrat
    font-weight 700
    font-size .85rem
    line-height 1.3846

    & p 
      margin 0

  &__button
    font-size 1.5rem
    height 4rem
    width 4rem
    background-color #ff7f50
    padding-top 0.5rem
    padding-bottom 0.5rem
    padding-left 1.5rem
    padding-right 1.5rem
    text-transform uppercase

    border-radius 9999px
    display inline-flex
    align-items center
    justify-content center
    font-family montserrat
    font-weight bold
    font-size 1rem
    color blue-dark

    transition transform .35s

    &:hover 
      transform scale(1.2)
      & .Sub 
        transform scale(.8)

  &__player 
    height 400px
    display flex
    justify-content center

  &__transcription
    margin-top 2rem

    &-txt 
      position relative
      padding-bottom 2rem
      border-bottom 2px solid white
      font-family montserrat
      font-weight 500
      font-size 1.125rem
      line-height 1.3846
      height 100%

      &.closed 
        height 80px
        overflow hidden
        border-bottom 2px solid transparent

    &-header 
      display flex
      justify-content center
      margin-top 3rem

    &-open 
      display flex 
      justify-content space-between
      align-items center
      width 220px
      text-transform uppercase
      padding .75rem 0
      margin-bottom .5rem
      border-bottom 2px solid white

     &-chevron 
      width 12px
      transform rotate(180deg)

      &.closed
        transform rotate(0deg)

    &-gradient 
      height 60px
      width 100%
      position absolute
      bottom 0    
      background linear-gradient(180deg, rgba(blue-dark, 0) 0%, rgba(blue-dark,1) 100%)

    &-close 
      text-transform uppercase
      padding 1rem
      margin-top 1rem

  &__btn
    display flex
    justify-content center
    padding 2rem

  & .BtnGeneral__background
    width auto

  & .BtnGeneral__txt
    width 100%

.Sub 
  transition transform .35s
  width 16px
  height 16px
  display flex
  justify-content centrer
  align-items center
  padding-left 2px

</style>
