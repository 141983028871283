<template>
  <div class="Process">

    <!-- <div class="Process__title">{{ trans('title') }}</div> -->

    <div class="Page__header">    
      <h1 ref="title">{{ trans('title') }}</h1>
      <div ref="description"><h4 v-html="trans(getDescription())" /></div>
    </div>

    <div class="Process__img" :class="'is-'+ camera.id">
      <img :src="camera.tech_items[1].cover.url">
    </div>

    <div ref="btn_footer" class="Page__footer">
      <!-- <button class="Btn" :aria-label="trans('continue')" @click="onClose()">{{ trans('continue') }}</button> --> 
      <BtnGeneral :txt="trans('continue')" @click="onClose()"/>
    </div>

  </div>
</template>

<script>

import Settings from '@/config/Settings'
import BtnGeneral from '@/components/assets/BtnGeneral'

export default {
  name: 'Process',
  components: { BtnGeneral },
  data: ()=>({
    is_breadcrumb: false
  }),
  computed: {
    scene() { return this.$store.getters['user/scene'] },
    breadcrumb_back() { return this.$store.getters['user/breadcrumb_back'] },
    changed() { return this.$store.getters['user/changed'] },
    camera() { return this.$store.getters['user/camera'] },
  },
  beforeDestroy() {
    this.$store.commit('app/ClOSE_FULLSCREENVIDEO')
    this.$store.commit('user/SET_SOUND', null)
  },
  translations: {
    fr: { 
      title: 'Développe ton film',
      description: 'Ton tournage est terminé. Maintenant, il faut développer la pellicule. Le processus peut être long avant de voir le résultat final du film.',
      description_magnetique: 'Ton tournage est terminé. Cette caméra utilise de la bande magnétique.</br>Il suffit de rembobiner la bande et on est prêt à visionner le film',
      description_numerique: "Ton tournage est terminé. Cette caméra est numérique.</br>Elle utilise une carte mémoire. Il suffit d'appuyer sur le bouton de lecture.",
      continue: 'Poursuivre' 

      // Magnetique: Ton tournage est terminé. Cette caméra utilise de la bande magnétique. Il suffit de rembobiner la bande et on est prêt à visionner le film.

      // Numerique: Ton tournage est terminé. Cette caméra est numérique. Elle utilise une carte mémoire. Il suffit d'appuyer sur le bouton de lecture.

    },
    en: { 
      title: 'Develop the film',
      description: 'Your shoot is done. Now the film must be developed. It can be a long process before the film is ready to be watched.',
      description_magnetique: 'Your shoot is done. This camera uses magnetic tape. Just rewind the tape and your film will be ready for viewing.',
      description_numerique: "Your shoot is done. This camera is digital. It uses a memory card. Simply press the Play button.",
      continue: 'Next'
    },
  },
  mounted() {
   
    // this.$store.commit('app/OPEN_FULLSCREENVIDEO', { video: this.scene.process, type: 'audio', cb: this.onClose, hideBg: false })
  
    // this.$store.commit('app/OPEN_FULLSCREENVIDEO', {
    //   video: camera.balados.audio,
    //   type: 'audio',
    //   hideBg: false,
    //   cb: () => this.current = null
    // })

    let mp3 = 'developpement_pellicule'
    switch(this.camera.id){
      case 56: 
        this.$store.commit('user/SET_SOUND', 'bolex_dev')
        break
      case 88:
        mp3 = 'developpement_numerique'
        break
      case 96:
        mp3 = 'developpement_bande_magnetique'
        this.$store.commit('user/SET_SOUND', 'portapak_dev')
        break
    }

    this.$audio = this.getAudio(mp3)
    const delay = (this.changed == 0) ? 2 : 1
    this.$tween_audio = TweenMax.delayedCall(delay, ()=>{
      this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
    })
    
   this.addIntro()
  },
  methods: {

    // enter(el, done) { 
    //   TweenMax.from(el, 1, { alpha: 0, delay: 1.5, onComplete: ()=>{ done() } })
    // },
    // leave(el, done) { done() },

    getDescription() {
      let description 
      switch(this.camera.id){
        case 88:
          description = 'description_numerique'
          break
        case 96:
          description = 'description_magnetique'
          break
        default:
          description = 'description'
      }
      return description
    },

    addIntro() {

      this.$nextTick(()=>{
        this.$tl = new TimelineMax({ paused: true })  
            .staggerFrom([ this.$refs.title, this.$refs.description ], .5, { y: 75, opacity: 0, ease: Cubic.easeOut }, .25)
            .from([this.$refs.btn_footer], .75, { y: 100, opacity: 0, ease: Cubic.easeOut })
            
            TweenMax.delayedCall(1.5, ()=>{
              this.$tl.play()
            })
      })
    },
    onClose() {
      if(!this.is_breadcrumb)
        this.$store.commit('user/USE_TRANSITION', { single: true })
        // this.$router.push({ params: { single: true }})
    },
  },
  watch: {
    breadcrumb_back(nw, od) {
      this.is_breadcrumb = true
    },

  }
}

</script>

<style lang="stylus">

@import '~@/config/Settings'

.Process
  position absolute
  top 0px
  width 100vw
  height 100vh
  background-image url('./img/background.png')
  background-size cover
  background-position repeat repeat

  width 100vw 
  height 100vh
  display flex 
  justify-content center
  align-items center

  &__img
    position relative
    width max(40vh, 360px)
    height max(40vh, 360px)
    border-radius 50%
    // background rgba(#a5a5a5, .8)

    &.is-96
      width max(25vh, 300px)
      height max(25vh, 300px)

    &.is-88
      width max(20vh, 240px)
      height max(20vh, 240px)

</style>




























































