<template>
  <div class="Visualisation">
    <template v-if="ready">
      <StaticView v-if="this.camera.id == 86 || this.camera.id == 52"/>    
      <DynamicView v-if="this.camera.id != 86 && this.camera.id != 52"/>
    </template>
    <template v-if="!$route.params.process && !is_live">
      <div class="Page__header">    
        <h1 ref="title">{{ trans('title') }}</h1>
        <h4 ref="description">{{ trans('description') }}</h4>
      </div>
      <div ref="container" class="Page__footer">    
        <BtnGeneral ref="button" :txt="trans('continue')" @click="onClick()"/>
      </div>
    </template>

  </div>
</template>

<script>

import Settings from '@/config/Settings'
import Mixin from '@/core/mixins/Matrix'
import StaticView from './StaticView'
import DynamicView from './DynamicView'
import { Path3D, FreeCamera, Vector3 } from 'babylonjs'
import BtnGeneral from '@/components/assets/BtnGeneral'
import 'babylonjs-loaders'

export default {

  name: 'Visualisation',
  components: { StaticView, DynamicView, BtnGeneral },
  mixins: [ Mixin ],
  translations: {
    en: {
      continue: 'Start',
      title: 'Ready to start filming?',
      description: "Now it's your turn."
    },
    fr: {
      continue: 'Débuter',
      title: 'Prêt à tourner?',
      description: 'À toi de jouer.'
    },
  },

  data: () => ({
    is_live: false,
    is_static: true,
    camera_is_ready: false,
    ready: false
  }),

  beforeDestroy() {
    if(this.$tween) this.$tween.kill()
    if(this.$tween_audio) this.$tween_audio.kill()
  },

  created() {
    this.$scene = this.$parent.$babylon.scene
  },

  mounted() {
    this.$is_static = (this.camera.component_name == 'CameraCinematographe') ? true : false
    if(!this.$route.params.process)
      this.Initialize()
    this.ready = true
  },

  methods: {

    Initialize() {
      TweenMax.set('.Canvas',{ opacity: 1 })

      this.$audio = this.getAudio('tournage')
      const delay = (this.changed == 0) ? 3 : 1 

      this.$tween_audio = TweenMax.delayedCall(delay, ()=>{
        this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
      })

      this.$tween = new TimelineMax({})
          .set(this.$refs.container, { alpha: 1 })
          .staggerFrom([ this.$refs.title, this.$refs.description, this.$refs.button.$el ], .75, { opacity: 0, y: window.innerHeight*.25, ease: Cubic.easeOut, delay: 1 }, .2)
          .stop()  

      if(this.$route.params.path && this.$route.params.process == undefined && this.camera.id == 56)   
        this.$store.commit('user/SET_SOUND', 'bolex_crank')
    },

    hideRightBlock() {
      this.$hide = TweenMax.to(this.$refs.container, 0, { alpha: 0, ease: Linear.easeNone, onComplete: ()=>{
        this.playAnimation()
      }})
    },

    playAnimation() {
      this.$parent.$refs.scene.playAnimation()
      this.is_live = true
    },

    onClick() {
      this.hideRightBlock()
      // this.$audio = this.getAudio('recording')
      this.$tween_audio = TweenMax.delayedCall(.5, ()=>{
        this.$store.commit('user/SET_RECORDING', true)
        // this.$store.commit('user/ADD_NARRATION', {  
        //   sound : this.$audio[this.lang].url, 
        //   txt: this.$audio[this.lang].text, 
        //   genre: 'buddy' 
        // })
      })
    },

    reset() {
      this.camera_is_ready = false
      this.is_live = false
    },

  },

  watch: {
    'camera_is_ready'(nw){
      if(nw)
        this.$tween.play(0)
    },
    '$route': {
      immediate: false,
      handler() {
        this.$nextTick(()=>{
          if(this.$route.params.process) this.reset()
          else this.Initialize()
        })
      }
    },
    animation_completed(nw, od) {
      if(this.is_live)
        this.$tween = TweenMax.delayedCall(2, ()=>{ 
          this.$store.commit('user/USE_TRANSITION', { process: 'process' })
        })
    },
  },
  computed: {
    camera() { return this.$store.getters['user/camera'] },
    changed() { return this.$store.getters['user/changed'] },
    animation_completed() { return this.$store.getters['user/animation_completed']},
  },

}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Visualisation
  position absolute
  overflow hidden
  top 0px
  width 100vw
  height 100vh

  display flex
  align-items center
  justify-content center

  .Block
    &.container
      justify-content center
      align-items center

  .Btn
    opacity 1

  .Description
    padding-top 4vh
    padding-bottom 4vh


</style>




















