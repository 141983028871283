<script>

import Settings from '@/config/Settings'
import Visualisation from '@/core/mixins/Visualisation'
import Matrix from '@/core/mixins/Matrix'
import { FreeCamera, Vector3 } from 'babylonjs'
import 'babylonjs-loaders'

export default {

  name: 'StaticView',
  mixins: [ Visualisation, Matrix ],
  render: h => h(),
  data: ()=>({
    pos: []
  }),
  beforeDestroy() {
    this.killTweens()
    this.$store.commit('user/SET_MOTION', null)
  },
  created() {

    // console.log('StaticView !!!');

    this.$scene = this.$parent.$scene
    this.$cameraman = this.$scene.getMeshByName('cameraman')
    this.$camera = this.$scene.getCameraByName('tracking') 
    this.$tracking = this.$scene.getMeshByName('cameraman_tracking')
    this.$target = this.$scene.getMeshByName('cameraman_target')
    this.$is_parade = Settings.scene_translations[this.lang][this.$route.params.scene] == 'parade' ? true : false

    const path3d = this.$is_parade ? Settings.parade_generic_path : Settings.generic_path
    const dist = this.$is_parade ? 973.8278421072654 : 1035.5108447534776 
    this.speed = this.$is_parade ? 9.738278421072653 : 10.355108447534777
    this.pos = []
    _.each(path3d, (path)=>{
      let p = path
      let obj = { x: p[0], y: 0, z: p[1] }
      this.pos.push(obj)
    })

    // this.$tween = new TimelineMax({ onUpdate: ()=>{ this.$cameraman.lookAt(new Vector3(0, 0, 0)) } }).stop()

  },

  mounted() {
    // this.InitializeTweenCompare()
  },

  methods: {
    reset() {
      this.setCameramanPosition()
    },
    setCameramanPosition() {
      this.$scene.activeCamera = this.$camera 
      
      // this.$target.setAbsolutePosition(new Vector3(0, 1, 0))
      // this.$tracking.setAbsolutePosition(new Vector3(_.random(-3,5), _.random(3,5), _.random(2,10))) 

      this.$target.position = new Vector3(0, 1, 0)
      this.$tracking.position = new Vector3(2, 2.75, 2)
      this.$tracking.lookAt(this.$target.position) 

      const pos = this.getPos(this.$path[0])
      TweenMax.set(this.$cameraman.position, { 
        x: pos[0], 
        y: 0, 
        z: pos[1], 
        onComplete:()=>{ this.panBeforeRecording() },
      })
    },
    panBeforeRecording() {

      this.$cameraman.lookAt(new Vector3(0, 0, 0))

      let pdt = {  x: 0, y: 2, z: -4 }
      if(Settings.scene_translations[this.lang][this.$route.params.scene] == 'parade' 
      && this.$cameraman.position.z > .3) 
        pdt = {  x: 0, y: 1.75, z: -3 }
      else if(Settings.scene_translations[this.lang][this.$route.params.scene] == 'parade' 
      && this.$cameraman.position.z < .3) 
        pdt = {  x: 0, y: 3, z: -2 }

      TweenMax.delayedCall(.1, ()=>{
        TweenMax.to(this.$tracking.position, 5, { ...pdt,
          onUpdate: ()=>{ this.$tracking.lookAt(this.$target.position)  },
          onComplete: ()=>{ this.$parent.camera_is_ready = true },
          delay: 2
        })
      })
    },
    resetFinal() {

      // console.log('resetFinal');

      const pos = this.getPos(this.$path[0])
      this.$cameraman.position.x = pos[0]
      this.$cameraman.position.y = 0
      this.$cameraman.position.z = pos[1]
      this.$cameraman.lookAt(new Vector3(0, 0, 0))

    },
    InitializeTweenCompare() {
      
      // const path3d = Settings.generic_path
      // const dist = 1035.5108447534776 
      // const speed = 10.355108447534777
      // this.pos = []
      // _.each(path3d, (path)=>{
      //   let p = path
      //   let obj = { x: p[0], y: 0, z: p[1] }
      //   this.pos.push(obj)
      // })

      this.$tween = new TimelineMax({ 
        onUpdate: ()=>{ this.$cameraman.lookAt(new Vector3(0, 0, 0)) } 
      })
        .set(this.$cameraman.position, { x: this.pos[0].x, y: this.pos[0].y, z: this.pos[0].z })
        .to(this.$cameraman.position, this.speed, { bezier: { values: this.pos }, ease: Linear.easeNone, delay: 0.5 })
    },
    panBellAndHowell() {

      // console.log('panBellAndHowell');

      this.resetFinal()

      let obj = { x: 1 }
      this.$cameraman.lookAt(new Vector3(obj.x, 0, 0))

      if(this.$pan) 
        this.$pan.kill()

      this.$pan = TweenMax.to(obj, 10, { 
        x: -1, 
        onUpdate: ()=>{
          this.$cameraman.lookAt(new Vector3(obj.x, 0, 0))
        },
        ease: SteppedEase.config(80),
        delay: 0
      })
    },
    playGopro() {

      this.$first = this.pos[0] 
      const dst = this.getDistance(0,0,this.$first.x, this.$first.z)
      const pt = this.getNextPoint(0,0,this.$first.x, this.$first.z, dst+20) 
      this.$dpt = { x: pt[0], y: 0, z: pt[1] }
      this.$cameraman.position = new Vector3(this.$dpt.x, 0, this.$dpt.z)
      this.$cameraman.lookAt(new Vector3(0, 0, 0))

      if(this.$pan) 
        this.$pan.kill()

      this.$pan = new TimelineMax({})
          .to(this.$cameraman.position, 3.5, { 
            x: this.$first.x, 
            z: this.$first.z,
            ease: Linear.easeNone,
          })
          .to(this.$cameraman.position, .5, { 
            y: 2, 
            onUpdate: ()=>{
              this.$cameraman.lookAt(new Vector3(0, 0, 0))
            },
            yoyo: true,
            repeat: 1,
            ease: Back.easeIn,
            onComplete: ()=>this.InitializeTweenCompare()
          })

    },
    killTweens() {
      if(this.$tween) this.$tween.kill()
      if(this.$pan) this.$pan.kill()
    },
    stopTweens() {
      if(this.$tween) this.$tween.pause()
      if(this.$pan) this.$pan.pause()
    },

    getDistance(xA, yA, xB, yB) { 
      let xDiff = xA - xB 
      let yDiff = yA - yB 
      return Math.sqrt(xDiff * xDiff + yDiff * yDiff)
    },

    getNextPoint(_x1, _y1, _x2, _y2, _smallerLen) {
      let x1 = _x1
      let y1 = _y1
      let x2 = _x2
      let y2 = _y2
      let xlen = x2 - x1
      let ylen = y2 - y1
      let hlen = Math.sqrt(Math.pow(xlen,2) + Math.pow(ylen,2))
      let smallerLen = _smallerLen
      let ratio = smallerLen / hlen
      let smallerXLen = xlen * ratio
      let smallerYLen = ylen * ratio
      let smallerX = x1 + smallerXLen
      let smallerY = y1 + smallerYLen
      return [smallerX, smallerY]
    },

  },

  computed: {
    is_recording() { return this.$store.getters['user/is_recording'] },
    viewport() { return this.$store.getters['user/viewport'] },
    ideal_camera() { return this.$store.getters['user/ideal_camera'] },
    camera() { return this.$store.getters['user/camera'] },
  },

  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{
          if(this.$route.params.path && this.$route.params.process == undefined)
            this.reset()
          else if(this.$route.params.single || this.$route.params.double)
            this.resetFinal()
        })
      }
    },
    is_recording(nw, od) {
      this.$store.commit('user/SET_MOTION', 'cycle_idle')
    },
    'viewport': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{
          this.stopTweens()
          if(this.$route.params.single != undefined 
          && this.$route.params.double != undefined 
          && this.viewport == 'compare' && this.ideal_camera != 86 
          && this.ideal_camera != 52){




            if(this.ideal_camera == 88)
              this.playGopro()
            else
              this.InitializeTweenCompare()
          }
          else if(this.$route.params.single != undefined  
              && this.viewport == 'compare' && this.ideal_camera == 52
              || this.$route.params.single != undefined  
              && this.viewport == 'origine' && this.camera.id == 52) {
            this.panBellAndHowell()
          }  
          else {
            this.resetFinal()
          }
        })
      }
    },
  },

}
</script>


















