<template>
  <div class="Square">
    <div class="Square__container" :class="'is-' + selected" @click="onClick()"></div>
  </div>
</template>

<script>

export default {
  
  name: 'Square',
  props: ['x', 'y'],

  data: () => ({
    size: 24,
    selected: '',
  }),

  mounted() {
    this.selected = this.$parent.mode
    TweenMax.set(this.$el, { x: this.x*this.size, y: this.y*this.size })
  },

  methods: {

    onClick() {

      if(this.$parent.mode === 'dev') { 

        if(this.selected === 'point')
          return

        this.selected = 'point'
        this.$parent.isGrid(this.x, this.y)

      } else { 

        if(this.$parent.notMorePoint()) return
        this.$store.commit('user/ADD_POINT', { x: this.x, y: this.y }) 

      }
    },

  },

  watch: {},
  computed: {}
  
}

</script>


<style lang="stylus">

@import '~@/config/Settings'

.Square
  position absolute
  width 25px
  height 25px

  &__container  
    width 100%
    height 100%
    opacity 1

    &.is-obstacle
      opacity 1
      background green

    &.is-point
      opacity 1
      background yellow

    &.is-path
      opacity 1
      background red

    &.is-dev
      outline 1px solid grey

    &.is-build
      outline 0px solid grey


</style>















