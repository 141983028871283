<template>
  <div class="ScreenMask">  
    <div class="ScreenMask__screen" :class="getClass()"></div>
    <div class="ScreenMask__slide" :class="getClass()">
      <div class="ScreenMask__layer first"></div>
      <div class="ScreenMask__layer second"></div>
      <div class="ScreenMask__layer third"></div>
    </div> 
  </div>
</template>

<script>

export default {

  name: 'ScreenMask',
  data: ()=>({
    show: 'none',
    is_back: false,
    tempo: 0,
    add: 0
  }),
  computed: {
    use_transition() { return this.$store.getters['user/use_transition'] },
    breadcrumb_back() { return this.$store.getters['user/breadcrumb_back'] },
    use_loader() { return this.$store.getters['user/use_loader'] },
    changed() { return this.$store.getters['user/changed'] }
  },
  watch: {
    '$route': {
      immediate: false,
      handler() {
        if(!this.use_transition) {
          this.show = 'show-quick'
          TweenMax.delayedCall(.75, ()=>{ 
            this.show = 'none' 
          }) 
        }
        TweenMax.delayedCall(.75, ()=>{ 
          if(this.show == 'none') {}
          else if(this.show == 'show') this.show = 'none'
          else this.show = (this.is_back) ? 'swipe-center-in' : 'swipe-center-out'
          this.is_back = false
        }) 
      }
    },
    'breadcrumb_back'(nw, od) {
      this.is_back = true
    },
    'use_loader'(nw, od) {
      if(nw == true && this.$tween) 
        this.$tween.kill()
      else if(nw == false && this.$route.params.camera != undefined && this.changed > 0) {
        this.show = 'standby' 
        this.$tween = TweenMax.delayedCall(2.5, ()=>{ 
          this.show = 'swipe-center-out' 
        }) 
      }    
    },
    'use_transition' (nw, od) {
      if(nw) {
        let np = null
        let ni = null
        _.each(nw, (p, i)=>{  
          np = p 
          ni = i
          this.tempo = 1
          this.add = .75
          if( i == 'double') { 
            if(this.is_back)
              this.show = 'swipe-out-center' 
            else {
              this.show = 'none'
              this.tempo = 0
              this.add = .1
            }
          }
          else
            this.show = (this.is_back) ? 'swipe-out-center' : 'swipe-in-center'
        })
        const params = {}
        let find = false
        _.each(this.$route.params, (p, i)=>{
          if(i == ni) find = true
          if(!find) params[i] = p
          else if(i == ni) params[i] = np
          else params[i] = undefined
        })
        TweenMax.delayedCall(this.tempo, ()=>{ this.$router.push({ params: params }) })
        TweenMax.delayedCall(this.tempo + this.add, ()=>{ this.$store.commit('user/USE_TRANSITION', null) })
      }
    },
  },
  methods: {
    getClass() {
      return this.show
    }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.ScreenMask
  position fixed
  top 0
  left 0
  height 100vh
  width 100vw
  z-index 700000
  display flex
  align-items center
  justify-content center
  pointer-events none

  &__screen
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background rgba(#13125b, 1)
    transition opacity .75s
    opacity 0

    &.show
      transition opacity .75s
      opacity 1

    &.show-quick
      transition opacity 0s
      opacity 1

  &__slide
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    transform skewX(-30deg)  translateX(150%)

    @keyframes swipe-in-center {
      from { transform: skewX(-30deg) translateX(150%) }
      to { transform: skewX(0) translateX(-25%) }  
    }

    @keyframes swipe-center-out {
      from { transform: skewX(0) translateX(-25%) }
      to { transform: skewX(30deg) translateX(-175%) }
    }

    @keyframes swipe-out-center { 
      from { transform: skewX(30deg) translateX(-175%) }
      to { transform: skewX(0) translateX(-25%) }
    }

    @keyframes swipe-center-in {
      from { transform: skewX(0) translateX(-25%) }  
      to { transform: skewX(-30deg) translateX(150%) }
    }

    &.standby
      transform skewX(0) translateX(-25%)

    &.swipe-in-center
      animation swipe-in-center 1s cubic-bezier(0.32, 0.94, 0.6, 1) forwards 
      display block

    &.swipe-out-center
      animation swipe-out-center 1s cubic-bezier(0.32, 0.94, 0.6, 1) forwards 
      display block

    &.swipe-center-out
      animation swipe-center-out 1s cubic-bezier(0.32, 0.94, 0.6, 1) forwards 
      display block

    &.swipe-center-in
      animation swipe-center-in 1s cubic-bezier(0.32, 0.94, 0.6, 1) forwards 
      display block

  &__layer
    position absolute
    top 0
    left 0
   
    &.first
      background rgba(#ffe200, .35)
      width 150%
      height 100%

    &.second
      background rgba(#ffe200, .5)
      transform translateX(10%)
      width 130%
      height 100%

     &.third
      background rgba(#ffe200, 1)
      transform translateX(25%)
      width 100%
      height 100%

</style>


