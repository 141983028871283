<script>

import PF from 'pathfinding'

export default {
  
  name: 'PathFinder',
  render: h => h(),

  data: ()=>({

    clone: null,
    path: [],
    type: 'BestFirstFinder',
    tolerance: 1,

    // AStarFinder
    // BestFirstFinder
    // BreadthFirstFinder 
    // DijkstraFinder 
    // IDAStarFinder.js 
    // JumpPointFinder 
    // OrthogonalJumpPointFinder 
    // BiAStarFinder
    // BiBestFirstFinder
    // BiBreadthFirstFinder 
    // BiDijkstraFinder 

  }),

  methods: {

    getPath(matrix, points, type) {

      this.path = []
      var grid = new PF.Grid(matrix)

      _.each(points, (pt, i)=>{
        if(i>0){
          var last = (i == points.length-1) ? true : false
          var pts  = [points[i-1], points[i]]
          var pth = this.getPathFinder(grid, pts, type)
              pth = this.smoothPath(pth, last)
          this.path = _.concat(this.path, pth)
        }
      })
      
      return this.path
    },

    smoothPath(path, last) {

      var quart = Math.round(path.length*.25)
      var middle = Math.round(path.length*.5)

      var arr = []
          arr.push(path[0])
      if(path.length > 10) arr.push(path[quart])
      if(path.length > 5) arr.push(path[middle])
      if(last) arr.push(path[path.length-1])

      return arr
    },

    getPathFinder(grid, points, _type) {

      this.clone = grid.clone()
      var type = (!_type) ? this.type : _type
      var finder = new PF[type]({ allowDiagonal: true, dontCrossCorners: true, biDirectional: false }) 
      var path = finder.findPath(points[0][0], points[0][1], points[1][0], points[1][1], this.clone)

      return path
    },
  }
  
}

</script>

