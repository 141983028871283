<template>
<transition name="Loader" appear>
  <div class="Loader" :class="{ 'is-transition': isTransition() }">   
    <transition name="Loader__hello">
      <div class="Loader__hello" v-if="use_hello">
        <div class="Loader__title" v-html="trans('title')"/>
        <transition name="Loader__block">
          <div class="Loader__block" :class="{ enable: !onload }">
            <div class="Loader__txt" v-html="trans('hello')"/>
            <BtnGeneral :txt="trans('active')" @click="onClick()"/>
          </div>
        </transition>
      </div>
    </transition>
    <transition name="Loader__snippet" v-if="onload">
      <div class="Loader__snippet" data-title=".dot-pulse">
        <div class="Loader__stage">
          <div class="dot-pulse"></div>
        </div>
      </div>
    </transition>
  </div>
</transition>
</template>

<script>
import Settings from '@/config/Settings'
import 'babylonjs-loaders'
import BtnGeneral from '@/components/assets/BtnGeneral'
import { SceneLoader } from 'babylonjs'

export default {

  props: ['type'],
  components: { BtnGeneral },
  computed: {
    promises() { return this.$store.getters['loader/promises'] },
    cameras() { return this.$store.getters['data/cameras'] },
    scenes() { return this.$store.getters['data/scenes'] },
    changed() { return this.$store.getters['user/changed'] },
    loader_added() { return this.$store.getters['user/loader_added'] },
  },
  translations: {
    en: {   
      title: 'Shoot your own Scene',
      hello: "Hello! I would like to come along</br>with you as you complete the activity.</br>Will you let me join you?",
      active: 'Activate',
    },
    fr: {
      title: 'Tourne ton film',
      hello: "Salut! J'aimerais t'accompagner</br>de vive voix au cours de ton expérience.</br>Est-ce que tu me permets?",
      active: 'Activer',
    },
  },
  data: () => ({
    meshLoaderResolver: null,
    assetsLoader: { cameras: false, scenes: false },
    onload: true,
    use_hello: false
  }),
  beforeDestroy() {
    this.$store.commit('user/USE_LOADER', false)
  },
  created() {
    this.$store.commit('user/USE_LOADER', true)
    if(this.type != 'regular')
      return
    if(Settings.cms)
      this.loadWordpress()
    const MeshsLoader = new Promise(resolve => this.meshLoaderResolver = resolve)
    this.$store.commit('loader/PROMISE_PUSH', MeshsLoader)
    Promise.all(this.promises).then(() =>{
      if(this.$route.params.introduction != undefined)
        this.onload = false
      else
        this.$store.commit('loader/LOADER_LOADED')
    })
  },
  mounted() {
    this.$nextTick(()=>{ 
      if(this.loader_added < 2)
        this.use_hello = true 
    })
  },
  watch: {
    cameras() { if(this.cameras) this.loadMesh(this.cameras, 'cameras') },
    scenes() { if(this.scenes) this.loadMesh(this.scenes, 'scenes') },
  },
  methods: {
    onClick() {
      this.use_hello = false
      TweenMax.delayedCall(.25, ()=>this.$store.commit('loader/LOADER_LOADED')) 
    },
    isTransition() {
      return (this.$route.params.camera != undefined && this.changed > 0) ? true : false
    },
    loadMesh: async function(objs, type) {
      let count = 0
      while(count < objs.length) {
        const obj = objs[count]
              obj['babylon'] = await SceneLoader.LoadAssetContainerAsync('', obj.mesh)
        if(obj.overlay)
          obj['babylon_overlay'] = await SceneLoader.LoadAssetContainerAsync('', obj.overlay)
        count++
      }
      this.assetsLoader[type] = true
      if(this.assetsLoader.cameras && this.assetsLoader.scenes)
        this.meshLoaderResolver()
    },
    loadWordpress() {
      const params = {
        per_page: 500,
        hide_empty: 0,
        orderby: 'menu_order',
        order: 'asc'
      }
      const endpoints = [
        { api: 'pages', option: false },
        { api: 'cameras', option: false },
        { api: 'scenes', option: false },
        { api: 'general', option: true },
      ]
      _.each(endpoints, endpoint => {
        const url = endpoint.option ? `options/${endpoint.api}` : endpoint.api
        this.$store.commit('loader/PROMISE_PUSH', this.$http.get(url, { params: params })
          .then(res => this.$store.commit('data/SET_DATA', { option: endpoint.option, type: endpoint.api, data: res.data }))
        )
      })
    }
  }
}

</script>

<style lang="stylus">
@import '~@/config/Settings'

.Loader
  position fixed
  left  0
  right 0
  bottom 0
  top 0
  background #13125b
  z-index 999999
  transition opacity .75s
  transition-delay 2s

  &.is-transition
    background #ffe200
    & .Loader__hello
      pointer-events none
      opacity 0

  &-leave-active
    opacity 0

  &__snippet
    position absolute
    top 50%
    left 50%
    transform scale(1.25)
    transition opacity .5s
    &-enter
    &-leave-active
      opacity 0

  .dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FF7F50;
    color: #FF7F50;
    box-shadow: 9999px 0 0 -5px #FF7F50;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
  }

  .dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #FF7F50;
    color: #FF7F50;
  }

  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px #FF7F50;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }

  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px #FF7F50;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
  }

  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #FF7F50;
    }
    30% {
      box-shadow: 9984px 0 0 2px #FF7F50;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #FF7F50;
    }
  }

  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #FF7F50;
    }
    30% {
      box-shadow: 9999px 0 0 2px #FF7F50;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #FF7F50;
    }
  }

  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #FF7F50;
    }
    30% {
      box-shadow: 10014px 0 0 2px #FF7F50;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px #FF7F50;
    }
  }

  &__hello
    display flex
    flex-direction column
    justify-content center 
    align-items center
    width 600px
    text-align center
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    transition opacity .75s
    transition-delay .5s

    &-enter
      opacity 0
    &-leave-active
      transition-delay 0s
      opacity 0

  &__block
    transition opacity .5s
    transition-delay .5s
    display flex
    flex-direction column
    align-items center
    opacity 0
    
    &.enable 
      opacity 1

  &__title 
    margin-bottom 42px
    font-size 2.4rem

  &__txt
    margin-bottom 48px
    font-family montserrat
    font-size 1.5rem
    line-height 1.9rem
    font-weight 600

</style>












