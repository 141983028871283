<template>
  <div class="Breadcrumb">
    <!-- <transition name="Breadcrumb__back"> -->

      <div class="Breadcrumb__container" v-if="this.$route.params[this.path[0]]">
        
        <button :aria-label="trans('back')" @click="back" @mouseenter="mouseenter" class="Breadcrumb__block left">
          <img :src="require('@/commons/img/arrow_left.png')">
          <div class="Breadcrumb__txt left"><div>{{ prev }}</div><div class="Breadcrumb__prev-text">{{ text }}</div></div>
        </button>
        
        <div class="Breadcrumb__block center">
          <div class="Breadcrumb__txt">{{ current + 1 }}</div> 
        </div>

        <button class="Breadcrumb__block right" :class="{'enable': current != total }">
          <div class="Breadcrumb__txt">{{ next }}</div> 
          <img :src="require('@/commons/img/arrow_right.png')">
        </button>
      </div>

    <!-- </transition> -->

  </div>
</template>

<script>
  export default {
    name: 'Breadcrumb',
    data: () => ({
      total: 0,
      path: [],
      prev: null,
      next: null,
      text: null
    }),
    translations: {
      fr: { 
        back: 'retour', 
        scene: "Choisis une scène", 
        camera: 'Choisis ta caméra', 
        items: 'Choisis ton équipement', 
        path: 'Positionne ta caméra', 
        process: 'Tournage', 
        single: 'Développe ton film', 
        double: 'Visionne ton film',  
      },
      en: { 
        back: 'Back', 
        scene: "Select a scene", 
        camera: 'Select a camera', 
        items: 'Select your equipment', 
        path: 'Position the camera', 
        process: 'Shooting scene', 
        single: 'Develop the film', 
        double: 'Watch your film', 
      },
    },
    computed: {
      current() {
        let current = 0
        _.each(this.path, (p,i)=>{
          if(i > 0 && this.$route.params[this.path[i]]) current += 1
        })

        if(current == 0) this.prev = 'Introduction'
        else this.prev = current

        if(current == 0) this.text = ''
        else this.text = this.trans(this.path[current])

        this.next = current + 2
        return current
      }
    },
    created() {
      _.each(this.$route.params, (param, idx)=>{
        this.path.push(idx)
      })
      this.total = this.path.length - 1
    },
    methods: {
      mouseenter() {
        this.$store.commit('user/SET_SOUND', 'tertiaire_hover')
      }, 
      back() {
        this.$store.commit('user/SET_SOUND', 'tertiaire_click')
        this.$store.commit('user/BREADCRUMB_BACK')
        TweenMax.delayedCall(.1, ()=>{
          this.$store.commit('user/USE_TRANSITION', { [this.path[this.current]]: undefined })
        })
      }
    }
  }
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Breadcrumb
  color orange
  position absolute
  top 0
  text-align center
  padding 32px 0
  transform translateX(-50%)
  left 50%
  width 50vw
  z-index 5000
  transition opacity 1s, transform 1s
  &-enter
  &-leave-active
    transform translateX(-50px)
    opacity 0

  &__container
    display flex
    justify-content space-between
    font-weight bold
    font-family montserrat
    font-size 14px

  &__block
    display flex
    width 60px

    &.left 
      &:hover 
        .Breadcrumb__txt.left 
          transform translateX(16px)
        .Breadcrumb__prev-text 
          opacity 1

    &.center
      justify-content center

    &.right
      justify-content flex-end
      opacity 0

      &.enable
        opacity .5

  &__prev-text 
    transition opacity .35s, transform .35s
    white-space nowrap
    opacity 0


  &__txt
    transition opacity .35s, transform .35s
    padding 4px 24px
    display flex 

    & div 
      padding-right 16px

  & img
    padding-top 5px


</style>
