<template>
  <div class="Items">
    
    <!-- <div class="Items__right"> -->
    <div ref="right" class="Block right">  
      <div ref="container" class="Items__container">
        <Item v-for="(obj, index) in items" 
          :key="'tech_' + index" 
          :obj="obj" 
          :type="'technical'"
          :idx="'tech_' + index"
        />
      </div>
    </div>

    <div ref="mouse" class="Items__mouse">
      <img :src="require('@/commons/img/icon_mouse.png')">
    </div>

   
    <div ref="dropzone" class="Items__center">
      <div>
        <Dropzone ref="zone1" id="zone1"/>
        <Dropzone ref="zone2" id="zone2"/>
      </div>
      <div>
        <Dropzone ref="zone3" id="zone3"/>
        <Dropzone ref="zone4" id="zone4"/>
      </div>
    </div>

    <div class="Page__header">    
      <h1 ref="title">{{ trans('title') }}</h1>
      <div ref="desc"><h4>{{ trans('description') }}</h4></div>
    </div>

    <div ref="btn_footer" class="Page__footer">     
      <BtnGeneral class="Items__buttons" :txt="trans('continue')" @click="onClick()" :class="{ 'is-inactive': !this.active }" />
    </div>

  </div>
</template>

<script>

import { Draggable } from 'gsap/all'
import Settings from '@/config/Settings'
import { Scenes } from '@/components/scene/js/data.js'
import Item from './Item'
import Dropzone from './Dropzone'
import BtnGeneral from '@/components/assets/BtnGeneral'

import { Mesh, StandardMaterial, FreeCamera, Vector3, Camera } from 'babylonjs'
import 'babylonjs-loaders'
import Hashids from 'hashids'

export default {

  name: 'Items',
  components: { Item, Dropzone, BtnGeneral },

  translations: {
    en: {
      continue: 'Next',
      title: 'Select your equipment',
      description: 'Pick the four items you will need to operate this camera' 
    },
    fr: { 
      continue: 'Poursuivre',
      title: 'Choisis ton équipement',
      description: 'Sélectionne les 4 items nécessaires pour tourner avec cette caméra' 
    },
  },

  data: () => ({
    items: null,
    use_feedback: false,
    feedback: '',
    array: [],
    items_added: [],
    active: false,
    count: 0,
    max: 4,
    dropzone: [],
    used: {
      zone1: null,
      zone2: null,
      zone3: null,
      zone4: null,
    },
    current_obj: null
  }),

  beforeDestroy() {
    if(this.$tween_audio) 
      this.$tween_audio.kill()
    if(this.$tween) 
      this.$tween.kill()
    if(this.$tl) 
      this.$tl.kill()
    if(this.$sim) 
      this.$sim.kill()
    this.$containerMaster.dispose()
  },

  created() {

    this.$hashids = new Hashids()
    this.$store.commit('user/IS_ACTIVE', false)

    // _.each(this.camera.tech_items, (item)=>{
    //   // if(item.is_valid) this.max += 1
    // })

    // ADD CAMERA IN 3D

    this.items = _.shuffle(this.camera.tech_items)
    this.$meshes = []
    this.$childmeshes = []

    this.$scene = this.$parent.$babylon.scene
    this.$engine = this.$parent.$babylon.engine
    this.$camera = new FreeCamera('camera_orthographic', new Vector3(0, 0, 0), this.$scene)

    // this.$camera.position.x = 0
    // this.$camera.position.y = 2
    // this.$camera.position.z = 0
    // this.$camera.mode = Camera.ORTHOGRAPHIC_CAMERA 
    // this.resizeOrthgraphicCamera(this.$camera)
    // this.$engine.onResizeObservable.add(()=>{ this.resizeOrthgraphicCamera(this.$camera) })
    // this.$scene.activeCamera = this.$camera

    this.$camera = this.$parent.$babylon.camera
    this.$scene = this.$parent.$babylon.scene
    TweenMax.to(this.$camera, 0, { babylon: { rotation: 0, x: 0, y: 2, z: 0 }, delay: .5 })
    
    this.$containerMaster = new Mesh('camera_container_master', this.$scene)
    this.$container = new Mesh('camera_container', this.$scene)
    this.$container.parent = this.$containerMaster
    this.$store.commit('user/PREV_CONTAINER', true)
    
    TweenMax.set(this.$container, { babylon: { z: 4, y: -.5, x: 1 } })

    // TweenMax.set(this.$containerMaster, { babylon: { rotationY: -90 } })
    
    // const mesh = new Mesh('camera_items', this.$scene)
    // const meshCamera = this.camera['babylon'].instantiateModelsToScene().rootNodes[0]
    //       meshCamera.parent = mesh
    //       mesh.parent = this.$container

    // this.camera['angle'] = 0

    // const index = 0
    // const pos = this.getPointAroundCircle(this.camera['angle'], 10)
    // TweenMax.set(meshCamera, { babylon: { scale: 15 } })
    // // TweenMax.set(mesh, { babylon: { ...pos, y: 2, rotationY: (index + 4) * 45 }})
    // TweenMax.set(mesh, { babylon: { ...pos, y: 2, rotationY: (index + 3.82) * 45 }})

    // _.each(mesh.getChildMeshes(), (m)=>{ this.$childmeshes.push(m) })   
    // this.camera['$mesh'] = mesh

    // this.$cameraman = this.$scene.getMeshByName('cameraman')
    // this.$cameraman.position.x = 0
    // this.$cameraman.position.z = 0
    // this.$cameraman.lookAt(this.$camera_target.position)

    // console.log(this.$cameraman);

    // 86: { name: "CameraCinematographe" },
    // 52: { name: "CameraBellHowell" }, 
    // 56: { name: "CameraBolex" }, 
    // 96: { name: "CameraPortapak" }, 
    // 94: { name: "CameraAaton" },
    // 88: { name: "CameraGoPro" },

    this.$pos = {
      86 : { z: 5, rotationY: -18, y: 1.05, x: -2, idle: 'Spin' },
      52 : { z: 5, rotationY: -18, y: 1.05, x: -2, idle: 'Idle' },
      56 : { z: 5, rotationY: -18, y: 1.05, x: -2, idle: 'WalkFront' },
      96 : { z: 5, rotationY: -18, y: 1.05, x: -2, idle: 'WalkFront' },
      94 : { z: 5, rotationY: -18, y: 1.05, x: -2, idle: 'WalkFront' },
      88 : { z: 5, rotationY: -18, y: 1.05, x: -2, idle: 'WalkFront' },
    }

    // this.$pos = {
    //   86 : { z: 5, rotationY: 0, y: 1.05, x: 0, idle: 'Spin' },
    //   52 : { z: 5, rotationY: 0, y: 1.05, x: 0, idle: 'Cycle' },
    //   56 : { z: 5, rotationY: 0, y: 1.05, x: 0, idle: 'WalkFront' },
    //   96 : { z: 5, rotationY: 0, y: 1.05, x: 0, idle: 'WalkFront' },
    //   94 : { z: 5, rotationY: 0, y: 1.05, x: 0, idle: 'WalkFront' },
    //   88 : { z: 5, rotationY: 0, y: 1.05, x: 0, idle: 'WalkFront' },
    // }

    const avatarGLTF = this.avatars[0]['babylon'].instantiateModelsToScene()         
    const mesh = new Mesh('avatar', this.$scene).addChild(avatarGLTF.rootNodes[0])
          mesh.parent = this.$containerMaster

    this.$mesh = mesh 
    this.$avatarGLTF = avatarGLTF       
  },

  mounted() {

    // document.addEventListener('keypress', this.onKeyPress)

    // TweenMax.delayedCall(2, ()=>{    
    //   // this.$store.commit('user/USE_TRANSITION', false)
    //   // this.createDrag() 
    // })

    this.$audio = this.getAudio('choix_item')

    // this.$tween_audio = TweenMax.delayedCall(2.5, ()=>{
    //   this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
    // })

  },

  methods: {

    addSimulation() {

      const speed = 1

      const tg1  = document.getElementsByClassName(" tech_0")[0].getBoundingClientRect()
      const zn1  = this.$refs.zone1.$el.getBoundingClientRect()
      const tg2  = document.getElementsByClassName(" tech_2")[0].getBoundingClientRect()
      const zn2  = this.$refs.zone4.$el.getBoundingClientRect()
      const tg3  = document.getElementsByClassName(" tech_4")[0].getBoundingClientRect()
      const zn3  = this.$refs.zone2.$el.getBoundingClientRect()

      this.$sim = new TimelineMax({ delay: .25 })
          .set(this.$refs.mouse, { x: window.innerWidth*.5-20, y: window.innerHeight*.5-30 })
          .set({}, { delay: .25 })
          .to(this.$refs.mouse, .25, { alpha: 1 })
 
          .set({}, { delay: .25 })
          .to(this.$refs.mouse, speed, { x: tg1.x + 64, y: tg1.y + 50, ease: Power2.easeOut })
          .to(this.$refs.mouse, .15, { scale: .9 })
          .set({}, { delay: .25 })
          .to(this.$refs.mouse, speed, { x: zn1.x + 38, y: zn1.y + 38, ease: Strong.easeOut })
          .set({}, { delay: .25 })
          .to(this.$refs.mouse, .15, { scale: 1 })

          .set({}, { delay: .25 })
          .to(this.$refs.mouse, speed, { x: tg2.x + 64, y: tg2.y + 50, ease: Power2.easeOut })
          .to(this.$refs.mouse, .15, { scale: .9 })
          .set({}, { delay: .25 })
          .to(this.$refs.mouse, speed, { x: zn2.x + 38, y: zn2.y + 38, ease: Power2.easeOut })
          .set({}, { delay: .25 })
          .to(this.$refs.mouse, .15, { scale: 1 })

          .set({}, { delay: .25 })
          .to(this.$refs.mouse, speed, { x: tg3.x + 64, y: tg3.y + 50, ease: Strong.easeOut })
          .to(this.$refs.mouse, .15, { scale: .9 })
          .set({}, { delay: .25 })
          .to(this.$refs.mouse, speed, { x: zn3.x + 38, y: zn3.y + 38, ease: Power2.easeOut })
          .set({}, { delay: .25 })
          .to(this.$refs.mouse, .15, { scale: 1 })
          .set({}, { delay: .25 })
          .call(() => { this.onComplete() })
    },

    onComplete() {
      TweenMax.to(this.$refs.mouse, .25, { alpha: 0 })
    },

    removeSimulation() {
      if(this.$sim) {
        this.$sim.kill()
        this.onComplete()
        this.$sim = null
      }
    },

    onKeyPress(event) {
      // this.addIntro()
      this.active = !this.active
      // this.$store.commit('user/IS_ACTIVE', this.active)
    },
    
    // enter(el, done) { 
    //   TweenMax.from(el, 1, { alpha: 0, delay: 1.5, onComplete: ()=>{ done() } })
    //   TweenMax.from(this.$refs.dropzone, 1, { alpha: 0, delay: 2.5 })
    //   this.$tween = new TimelineMax({})
    //       .staggerFrom([ this.$refs.title, this.$refs.container ], .75, { alpha: 0, y: window.innerHeight*.25, ease: Cubic.easeOut, delay: 1.5 }, .2)
    // },

    // leave(el, done) { done() },

    updateUsed(id) {
      _.each(this.used, (u, zone)=>{
        if(id == u) this.used[zone] = null
      })
    },

    createDrag() {

      const _this = this
      this.$drag = Draggable.create('.draggable', {

        bounds: window,
        zIndexBoost: false,

        onDragStart() {
          _this.$store.commit('user/SET_SOUND', 'secondary_hover')
          _this.beforeDrag(this.target)
        },

        onDragEnd() {

          let i = _this.dropzone.length
          let succes = null
          while (--i > -1) {
            if (this.hitTest(_this.dropzone[i], '50%')) 
              succes = _this.dropzone[i] 
          }
          if(!succes || _this.used[succes.id]){
            _this.$store.commit('user/SET_SOUND', 'secondary_hover')
            _this.backDragCompleted(succes, this.target, 0.5) 
          }
          else {
            // _this.$store.commit('user/SET_SOUND', 'secondary_click')
            _this.dragCompleted(succes, this.target, 0.5)   
          }
        }
      })
    },

    getZone() {
      let b = false
      let index 
      _.each(this.used, (val, idx)=>{
        if(val == null && !b) {
          b = true
          index = idx
        }
      })

      if(index) return this.$refs[index].$el
      else return  null
    },

    simulateDrag(target) {
      this.beforeDrag(target)
      const zone = this.getZone()
      if(zone)
        this.dragCompleted(zone, target, 0)
    },

    simulateBackDrag(target) {
      this.beforeDrag(target)
      this.backDragCompleted(null, target, 0)
    },

    beforeDrag(target) {
      this.removeSimulation()
      this.$store.commit('user/DRAG_END', { target: 'nothing', dropzone: 'nothing' })
      this.$store.commit('user/DRAG_BACK', { target: 'nothing', dropzone: 'nothing' })
      this.current_obj = _.find(this.camera.tech_items, { cover: { id: Number(target.id) }})
      this.updateUsed(target.id)
    },

    backDragCompleted(succes, target, speed) {
      const zone = (!succes) ? 'nothing' : succes.id
      this.$store.commit('user/DRAG_BACK', { target: target.id, dropzone: zone })
      TweenMax.to(target, speed, { x: 0, y: 0 })
    },

    dragCompleted(succes, target, speed) {
      this.$store.commit('user/DRAG_END', { target: target.id, dropzone: succes.id })
      this.used[succes.id] = target.id
      const drop = succes.getBoundingClientRect()
      const item = target.getBoundingClientRect()
      const x = drop.right - item.x - item.width - 5.5
      const y = drop.bottom - item.y - item.height - 6
      TweenMax.to(target, speed, { x: '+=' + x, y: '+=' + y })
    },

    addTechnical(id, obj) {
      this.items_added.push(id)
      if(obj.is_valid){
        this.addCount()
        const data = { txt: [obj[this.lang].retroaction] }
        this.$store.commit('user/ADD_NARRATION', data)
      }
    },

    removeTechnical(id, obj) {
      this.removeToArray(id, this.items_added)
      if(obj.is_valid) {
        this.$store.commit('user/ADD_NARRATION', 'close_it')
        this.removeCount()
        this.close()
      }
    },

    removeToArray(id, array){
      var index = array.indexOf(id)
      array.splice(index, 1)
    },

    close() {
      this.use_feedback = false
    },

    onClick() {

      if(this.items_added.length == 0) this.items_added = [0]
      this.$store.commit('user/ADD_NARRATION', 'close_it')
      // this.$encode = this.$hashids.encode(this.items_added)
      this.$encode = 'gY'

      this.closeIt()
   
      // this.$store.commit('user/ADD_MASK')
      // this.$tween = new TimelineMax({
      //       onComplete: ()=>{ 
      //         const encode = this.$hashids.encode(this.items_added)
      //         this.$router.push({ params: { items: encode } }) 
      //       }
      //     })
      //     .to(this.$el, .25, {
      //       alpha: 0,
      //       ease: Linear.easeOut 
      //     })
      //     .set({}, { delay: 1 })
    },

    addCount() {
      this.count += 1
      this.checkCount()
    },
    removeCount() {
      this.count -= 1
      this.checkCount()
    },
    checkCount() {
      if(this.count === this.max) { 
        this.active = true 
        // this.$store.commit('user/IS_ACTIVE', true)
      }
      else this.active = false
    },

    // resizeOrthgraphicCamera(camera) {
    //   const coef = .0035
    //   const scale = Math.min(window.innerWidth/1920, window.innerHeight/1080) 
    //   camera.orthoTop = window.innerHeight*(coef/scale)
    //   camera.orthoBottom = - window.innerHeight*(coef/scale)
    //   camera.orthoLeft = -window.innerWidth*(coef/scale)
    //   camera.orthoRight = window.innerWidth*(coef/scale)
    // },

    addNarration(_delay) {
      const delay = (_delay) ? _delay : 1.5
      this.$tween_audio = TweenMax.delayedCall(delay, ()=>{
        this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
      })
    },

    addIntro(_delay) {
      const delay = (_delay) ? _delay : .75
      const tempo = (this.changed == 0 || _delay) ? 3.25 : 1.25
      this.addNarration(tempo)

      this.$tl = new TimelineMax({ paused: true })
          // .from([this.$refs.right ], 1, { x: window.innerWidth*.25, opacity: 0, ease: Expo.easeOut })
          .staggerFrom([ this.$refs.title, this.$refs.desc ], .5, { y: 75, opacity: 0, ease: Cubic.easeOut }, .25)
          .staggerFrom([ this.$refs.zone1.$el, this.$refs.zone2.$el, this.$refs.zone3.$el, this.$refs.zone4.$el ], .25, { opacity: 0, ease: Linear.easeNone }, .25)
          .from([this.$refs.btn_footer], .75, { y: 100, opacity: 0, ease: Cubic.easeOut })
          .from([this.$refs.right ], 1, { x: window.innerWidth*.25, opacity: 0, ease: Expo.easeOut })
          .call(() => { 
            this.addSimulation() 
            this.createDrag() 
            // TweenMax.delayedCall(2, this.addNarration)
          })

      this.$tween = TweenMax.delayedCall(delay, ()=>{
        // this.createDrag()
        this.$tl.play()
      })

    },

    closeIt() {
      this.$tl = new TimelineMax({})
        .staggerTo([ 
            this.$refs.btn_footer,
            '.circle_tech_0',
            '.circle_tech_1',
            '.circle_tech_2',
            '.circle_tech_3',
            '.circle_tech_4',
            '.circle_tech_5',
            this.$refs.zone4.$el, 
            this.$refs.zone3.$el, 
            this.$refs.zone2.$el, 
            this.$refs.zone1.$el,
            this.$refs.desc, 
            this.$refs.title 
          ], .25, { opacity: 0, ease: Cubic.easeOut }, .1)
          .to([this.$refs.right ], 1, { 
            x: window.innerWidth*.5, 
            opacity: 0, 
            ease: Expo.easeOut
          })
          .to(this.$mesh, 1, { babylon: { x: -5 } })
      
      TweenMax.delayedCall(2, ()=>{
        this.$store.commit('user/USE_TRANSITION', { items: this.$encode })
      })

    },

    Initialize(delay) {

      TweenMax.set(this.$mesh, { babylon: {  
        x: this.$pos[this.camera.id]['x'], 
        y: this.$pos[this.camera.id]['y'], 
        z: this.$pos[this.camera.id]['z'], 
        rotationY: this.$pos[this.camera.id]['rotationY'] } 
      })

      // this.$avatarGLTF.animationGroups[4].goToFrame(0)   
      // console.log(this.$avatarGLTF.animationGroups);
      // console.log(this.$pos[this.camera.id]['idle']);

      // this.$anime = this.$avatarGLTF.animationGroups.find(a => a.name === this.$pos[this.camera.id]['idle'])
      // this.$anime.play(true) 
      
      this.addIntro(delay)
      
    },  
  },

  computed: {
    camera() { return this.$store.getters['user/camera'] },
    scene() { return this.$store.getters['user/scene'] },
    avatars() { return this.$store.getters['user/camera'].avatars },
    use_transition() { return this.$store.getters['user/use_transition'] },
    use_loader() { return this.$store.getters['user/use_loader'] },
    changed() { return this.$store.getters['user/changed'] }
  },

  watch: {
    'use_loader'(nw, od) {
      this.$nextTick(()=>{  
        this.Initialize(3)
      })
    },
    'use_transition' (nw, od) { 
      this.$nextTick(()=>{  
        if(!this.use_loader)this.Initialize()
      })
    }
  }

}

</script>

<style lang="stylus">

@import '~@/config/Settings'
@import '~@/commons/stylus/Media-queries'

.Items
  position absolute
  width 100vw
  display block
  overflow hidden

  & .Block
    display flex
    justify-content center 
    align-items center
    background-image url('./images/block_bkg.png')
    background-size cover
    background-position repeat repeat

  &__center
    width 100vw
    height 100vh
    display flex
    justify-content center
    align-items center

  &__dropzone
    width 100px
    height 100px
    border 3px dashed grey
    border-radius 50%
    margin 24px

  &__container
    width 70%
    display flex
    flex-wrap wrap 

    +max-screen-height(tabletPortrait)
      margin-bottom 50px

  &__button
    height 56px

  &__espace
    width 8vw

  &__feedback
    position absolute
    top 32px
    color #2b1a64
    font-size 10px
    line-height 15px
    text-align center
    width 320px
    height 320px
    background-color orange
    border-radius 50%
    display flex
    align-items center
    justify-content center
    padding 16px 48px
    cursor pointer

  &__mouse 
    position absolute 
    pointer-events none
    top 0
    left 0
    width 42px
    opacity 0

</style>




























































