<script>
import Mixin from '@/core/mixins/CameraFinal'
import { Vector2, Vector3, Viewport } from 'babylonjs'
import 'babylonjs-loaders'

export default {
    name: 'CameraBolex',
    mixins: [ Mixin ],
    render: h => h(),
    data: ()=>({
        fov: 1.2, // 10mm = 1.6 | 12.5mm = 1.5| 30mm  = 1.4| 35mm = 1.3| 50mm = .8 | 75mm = .6| 100mm = .4
        range: [ 1.2, .4 ]
    }),
    beforeDestroy() {
        if(this.$tween)
            this.$tween.kill()
        this.$camera.position = new Vector3(0, 0, 0)
        this.$processing.vignetteEnabled = false
        this.$processing.exposure = 1
    },
    created() {
        this.$engine = this.$parent.$engine
        this.$camera.rotation.x = 0 * (Math.PI/180)
        this.$camera.fov = 1.2
        this.$target.position.y = 1.35
        this.$target.position.z = -.5
    },
    mounted() {

        console.log('Camera Bolex '+ this.$camera.position);

        this.$store.commit('user/SET_FINAL_CAMERA', 'round')
        this.$nextTick(()=>{
            // this.animation.speedRatio = 1.6
            this.$store.commit('user/SHOW_AVATAR', false)
            this.muteAnimation(true)
            this.updateCanvas()
            this.addPostProcess()
            this.addMotion()

            TweenMax.delayedCall(_.random(4,8), ()=> this.addZoom())

            // document.addEventListener('keypress', this.onKeyPress)
        })
        // const blur = new BABYLON.BlurPostProcess("blur", new Vector2(1.0, 1.0), 1.0, 1.0, this.$camera)
        //       blur.kernel = 30  
    },
    methods: {

        onKeyPress() {
            this.addZoom()
        },

        addPostProcess() {

            this.$processing = new BABYLON.ImageProcessingPostProcess("processing", 1.0, this.$camera)
            this.$processing.vignetteEnabled = false
            this.$processing.exposure = 1.4
            
            // const blur = new BABYLON.BlurPostProcess("blur", new Vector2(1.0, 1.0), 1.0, 1.0, this.$camera)
            //   // blur.kernel = 12
            //   blur.kernel = 2

            // const grain = new BABYLON.GrainPostProcess("grain", .8, this.$camera)
            //       grain.intensity = 20
            //       grain.animated = true   

        },

        addZoom() {
            const zoom = { x: 1.2 }
            TweenMax.to(zoom, 3, { 
                x: .8,
                ease: Elastic.easeOut,
                onUpdate: ()=>{
                    this.$camera.fov = zoom.x
                }
            })

            // TweenMax.to(zoom, 2, { 
            //     x: 1.2,
            //     onUpdate: ()=>{
            //         this.$camera.fov = zoom.x
            //     },
            //     ease: Elastic.easeIn,
            //     delay: 2
            // })
        },

        addMotion() {
            this.$tween = new TimelineMax({})
            for(let i=0; i<10; i++){


                this.$tween.to(this.$camera, _.random(1,1.2), { 
                    babylon: { 
                        x: _.random(-.1, .25),
                        y: _.random(-.1, .25)
                    }, 
                    ease: Expo.easeOut,
                   
                    // delay: _.random(.5,2) 
                })

                this.$tween.to(this.$camera, 2, { 
                    babylon: { 
                        x: _.random(-.1, -.25),
                        y: _.random(-.1, .25)
                    }, 
                    ease: Elastic.easeOut,
                   
                    // delay: _.random(.5,2) 
                })

                .to(this.$camera, _.random(1,1.2), { 
                    babylon: { 
                        x: .1,
                        y: _.random(0, .1)
                    }, 
                    ease: Expo.easeOut,
                   
                    // delay: _.random(.5,2) 
                })
                .to(this.$camera, _.random(1,1.2), { 
                    babylon: { 
                        x: _.random(.1, .25),
                        y: _.random(-.1, .25)
                    }, 
                    ease: Expo.easeIn,
                   
                    // delay: _.random(.5,2) 
                })
                this.$tween.to(this.$camera, 2, { 
                    babylon: { 
                        x: _.random(-.2, .25),
                        y: _.random(-.1, .25)
                    }, 
                    ease: Elastic.easeOut,
                   
                    // delay: _.random(.5,2) 
                })




                // this.$tween.to(this.$camera, _.random(.2, .5), { 
                //     babylon: { 
                //         x: _.random(-.1, .3),
                //         y: _.random(-.25, .5),
                //     }, 
                //     yoyo: true, 
                //     ease: _.random(1,2) == 2 ? Cubic.easeIn : Back.easeOut,
                //     repeat: _.random(1,3) , 
                //     // delay: _.random(.5,2) 
                // })


                // this.$tween.to(this.$camera, _.random(.2, 3), { 
                //     babylon: { 
                //         x: _.random(-.1, .1),
                //         y: _.random(-.1, .1),
                //     } 
                // })
            }
        },

    },
}
</script>


