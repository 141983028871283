<template>
  <div class="Scenes">
    <div class="Scenes__top" v-if="scene">
      
     <!--  <transition name="Scenes__title" mode="out-in">
        <div ref="title" class="Scenes__title" :key="scene[lang].title">{{ scene[lang].title }}</div>
      </transition> -->

      <!--  
      <transition name="Scenes__title-video" mode="out-in">
        <div ref="txt" class="Scenes__title-video" v-if="scene.video && scene.video[lang]" :key="scene.video[lang].title">{{ scene.video[lang].title }}</div>
      </transition> 
      -->
      
      <!--  
      <router-link 
        ref="button" 
        :aria-label="trans('btn')" 
        v-if="scene.id == 170" 
        to="" 
        @click.native="onClick({ scene: scene[lang].slug })"
        class="Btn">
        {{ trans('btn') }}
      </router-link>

      <span v-else class="Btn is-inactive">{{ trans('btn') }}</span> 
      -->

    </div>

    <BtnSlider ref="slide_left" type="left" @click="goToScene(prev)" />
    <BtnSlider ref="slide_right" type="right" @click="goToScene(next)" />

    <div class="Page__header">    
      <h1 ref="own_title">{{ trans('title') }}</h1>
       <transition name="Scenes__title" mode="out-in">
        <div ref="title" class="Scenes__title" :key="scene[lang].title">{{ scene[lang].title }}</div>
      </transition>
    </div>

    <div ref="btn_footer" class="Page__footer">  

      <!-- 
      <router-link 
        ref="button" 
        :aria-label="trans('btn')" 
        v-if="scene.id == 170" 
        to="" 
        @click.native="onClick({ scene: scene[lang].slug })"
        class="Btn">
        {{ trans('btn') }}
      </router-link>
      <span v-else class="Btn is-inactive">{{ trans('btn') }}</span>  
      -->

      <BtnGeneral :txt="trans('btn')" @click="onClick({ scene: scene[lang].slug })" :class="{ 'is-inactive': this.isInactive(scene) }"/>

    </div>

  </div>
</template>

<script>
import { Mesh, MeshBuilder, ActionManager, StandardMaterial, ExecuteCodeAction, Texture, Vector3, Color3 } from 'babylonjs'
import BtnSlider from '@/components/btn-slider/BtnSlider'
import BtnGeneral from '@/components/assets/BtnGeneral'

export default {
  name: 'Scenes',
  components: { BtnSlider, BtnGeneral },
  beforeDestroy() {
    this.$tween.kill()
    this.$containerMaster.dispose()
    this.$camera.attachControl(document.querySelector('canvas'))
    if(this.$tween_audio) this.$tween_audio.kill()
  },
  data: () => ({
    scene: null,
  }),
  translations: {
    en: {
      btn: 'Choose this one',
      title: 'Select a scene'
    },
    fr: {
      btn: 'Choisir',
      title: 'Choisis une scène'
    },
  },
  computed: {
    scenes() { return this.$store.getters['data/scenes'] },
    len() { return this.scenes.length },
    index() { return _.findIndex(this.scenes, scene => this.scene.id == scene.id) },
    next() { return this.scenes[(this.index+1)%this.len]},
    prev() { return this.scenes[(this.index+this.len-1)%this.len]},
    breadcrumb_back() { return this.$store.getters['user/breadcrumb_back'] },
    changed() { return this.$store.getters['user/changed'] },
  },
  created: async function() {
    this.$meshes = []
    this.$camera = this.$parent.$babylon.camera
    this.$scene = this.$parent.$babylon.scene
    this.scene = this.scenes[0]
    this.$containerMaster = new Mesh('scenes_container_master', this.$scene)
    this.$container = new Mesh('scenes_container', this.$scene)
    this.$container.parent = this.$containerMaster
  },
  mounted() { 

    TweenMax.set('.Canvas',{ opacity: 1 })
    this.$camera.detachControl(document.querySelector('canvas'))
    this.$audio = this.getAudio('choix_de_scene')

    const delay = (this.changed == 0) ? 2 : 1

    this.$tween_audio = TweenMax.delayedCall(delay, ()=>{
      this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
    })
    this.$tween = TweenMax.delayedCall(.1, ()=>{
      this.init3D()
    })
    this.$tween_intro = TweenMax.delayedCall(.3, ()=>{
      this.addIntro()
    })

  },
  methods: {

    isInactive(scene) {
      // return (scene.id != 170 && scene.id != 186) ? true : false
      // return (scene.id != 170) ? true : false
      return false
    },

    addIntro() {

      this.$nextTick(()=>{

        _.each(this.$meshes, (mesh, index)=>{
          const angle = index * (360 / this.scenes.length)
          const pos = this.getPointAroundCircle(angle, 14)
          TweenMax.set(mesh, { babylon: { ...pos }})
        })

        TweenMax.set(this.$container.rotation, { directionalRotation: { y: (120 * (Math.PI/180)) + '_short', useRadians: true } })
        // TweenMax.set(this.$container, { babylon: { z: -50 }})

        this.$tl = new TimelineMax({ paused: true })  
            .add([
              TweenMax.to(this.$container.rotation, 4, {
                directionalRotation: { 
                  y: (0 * (Math.PI/180)) + '_short', 
                  useRadians: true 
                },
              }),
              TweenMax.to(this.$container, 4,{ babylon: { z: 0 } })
            ])
            .staggerFrom([ this.$refs.own_title ], .5, { y: 75, opacity: 0, ease: Cubic.easeOut }, .25)
            .from([this.$refs.title], .5, { opacity: 0, ease: Cubic.easeOut })
            .from([this.$refs.slide_right.$el ], .5, { x: 100, opacity: 0, ease: Cubic.easeOut })
            .from([this.$refs.slide_left.$el ], .5, { x: -100, opacity: 0, ease: Cubic.easeOut })
            .from([this.$refs.btn_footer], .75, { y: 100, opacity: 0, ease: Cubic.easeOut })
            
            TweenMax.delayedCall(0, ()=>{
              this.$tl.play()
            })
      })
    },

    onClick(param) {
      this.$store.commit('user/USE_TRANSITION', param)
    },

    init3D() {

      // console.log(this.scene);

      TweenMax.to(this.$camera, 0, { babylon: { rotation: 0, x: 0, y: 2, z: 0 } })
      TweenMax.set(this.$containerMaster, { babylon: { rotationY: -90 } })

      const materialBTN = new StandardMaterial('material_btn', this.$scene)
      const BTNTexture = new Texture(require('@/commons/img/btn_play.png'))
      materialBTN.diffuseTexture =  BTNTexture
      materialBTN.emissiveTexture =  BTNTexture
      materialBTN.opacityTexture = BTNTexture

      const defaultMaterial = new StandardMaterial('default_material', this.$scene)
      defaultMaterial.diffuseColor = Color3.FromHexString('#222222')

      _.each(this.scenes, (scene, index) => {
        const mesh = new Mesh('scene_' + index, this.$scene)
        scene['babylon'].instantiateModelsToScene().rootNodes[0].parent = mesh
        scene['angle'] = index * (360 / this.scenes.length)
        
        // const pos = this.getPointAroundCircle(scene['angle'], 14)
        const pos = { x: 14, z: 0 }
        TweenMax.set(mesh, { babylon: { ...pos, 
          rotationY: -90 + (index*72)

        }})
        let plane = null

        if(scene.video.cover && !this.isInactive(scene)) {
          plane = MeshBuilder.CreatePlane('plane', {width: 3, height: 2, sideOrientation: Mesh.DOUBLESIDE}, this.$scene)

          const pos = scene.fr.slug == 'la-parade' ? 0 : -2.5
          TweenMax.set(plane, { babylon: { y: 1.2, x: pos, rotationY: 180 }})
          const textureCover = new Texture(scene.video.cover.sizes.large)
          const material = new StandardMaterial('material', this.$scene)
                material.diffuseTexture = textureCover
                material.emissiveTexture = textureCover
          plane.material = material
          plane.parent = mesh
        }

        if(scene.video && plane && !this.isInactive(scene)) {
          
          const planeBTN = MeshBuilder.CreatePlane('plane_BTN', {width: .9, height: .9, sideOrientation: Mesh.DOUBLESIDE}, this.$scene)

          TweenMax.set(planeBTN, { babylon: { z: -.01 }})

          planeBTN.material = materialBTN
          planeBTN.actionManager = new ActionManager(this.$scene)
          planeBTN.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, event =>{ 
            this.$store.commit('user/SET_SOUND', 'secondary_hover')
            TweenMax.to(planeBTN, .25, { babylon: { scale: 1.25 }}) }))
          planeBTN.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, event =>{ TweenMax.to(planeBTN, .25, { babylon: { scale: 1 }}) }))
          planeBTN.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, event =>{
            this.$store.commit('user/SET_SOUND', 'secondary_click')
            this.$store.commit('app/OPEN_FULLSCREENVIDEO', { video: this.scene.video })
            TweenMax.to(planeBTN, .25, { babylon: { scale: 1 }})
          }))
          planeBTN.parent = plane
          TweenMax.to(planeBTN, 1, { babylon: { scale: .8 }, repeat: 5, yoyo: true, delay: 1.25 })
        }

        // console.log(scene.id);

        // if(this.isInactive(scene))
        //   _.each(mesh.getChildMeshes(), m => m.material = defaultMaterial)

        mesh.parent = this.$container
        this.$meshes.push(mesh)

      })

      // this.goToScene(this.scene)
    },

    goToScene(scene) {
      this.scene = scene
      TweenMax.to(this.$container.rotation, 2, {
        directionalRotation: { y: (-scene.angle * (Math.PI/180)) + '_short', useRadians: true },
        ease: Expo.easeOut
      })
    }
  },
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Scenes
  height 100vh

  &__top
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    margin-top -15vw
    text-align center

  &__title-video
    position absolute
    font-family montserrat
    bottom -14vw
    right 15vw
    font-weight 600
    width 15vw
    font-size 14px
    text-align left

  &__title
    color orange
    font-size 1.4vw
    // margin-bottom 2vw
    position relative
    // height 3vw
    width 100vw
    transition transform .3s, opacity .3s
    white-space nowrap
    &-enter
      transform translate(0px, 1vw)
      opacity 0
    &-leave-active
      transform translate(0px, -1vw)
      opacity 0

</style>
