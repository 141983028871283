<template>
  <div class="Navigation" v-if="$route.meta.name == 'experience'">
    <div class="Navigation__block">
      <button class="Navigation__leave" 
        :class="{ 'en': $root.lang == 'en' }"
        :aria-label="trans('Global.menu.home')" 
        @click="onClick()"
        @mouseenter="mouseenter">
        <div class="Navigation__close">
          <svg viewBox="0 0 13.989 16.934">
            <g id="Group_44" data-name="Group 44" transform="translate(-46.159 -32.865)">
              <path id="Path_68" data-name="Path 68" class="cls-3" d="M0,6.445,7.832,0l7.832,6.445" transform="translate(53.376 33.5) rotate(90)"/>
              <path id="Path_69" data-name="Path 69" class="cls-3" d="M0,0,7.832,6.445,15.663,0" transform="translate(59.376 33.5) rotate(90)"/>
            </g>
          </svg>
        </div>
        <div class="Navigation__txt">{{ trans('leave') }}</div>
      </button>
    </div>
     
    <!--  
    <div class="Navigation__block">
      <button :aria-label="trans('lang')" @click.prevent="$root.langSwitch()" class="Navigation__language" :class="[ { is_experience: $route.meta.name == 'experience' }, { active: this.$root.lang == 'fr' } ]">
        fr
      </button>
      <button :aria-label="trans('lang')" @click.prevent="$root.langSwitch()" class="Navigation__language" :class="[ { is_experience: $route.meta.name == 'experience' }, { active: this.$root.lang == 'en' } ]">
        en
      </button>
    </div>  
    -->

  </div>
</template>

<script>

// import { VueFocusTrap } from "vue-a11y-utils"

export default {
  name: 'Navigation',
  // components: { VueFocusTrap },
  translations: {
    en: {
      logoURL: 'http://www.virtualmuseum.ca',
      lang: 'Français',
      close: 'Close',
      menu: 'Menu',
      leave: "Exit",
      make_your_movie: "MAKE A MOVIE"
    },
    fr: {
      logoURL: 'http://www.museevirtuel.ca',
      lang: 'English',
      close: 'Fermer',
      menu: 'Menu',
      leave: "Quitter",
      make_your_movie: "FAIS TON CINÉMA"
    }
  },

  computed: {
    menuIsOpen() { return this.$store.getters['app/menuIsOpen'] }
  },
  watch: {
    '$route'() {
      this.$store.commit('app/SET_NAVIGATION', false)
    },
    menuIsOpen() {
      this.menuIsOpen
      ? this.$nextTick(() => this.$refs.trap.open())
      : this.$refs.trap.close()
    },
  },
  methods: {
    
    // focusFirst() {
    //   this.$refs.close.focus()
    // },
    // focusLast() {
    //   this.$refs.close.focus()
    // },

    mouseenter() {
      this.$store.commit('user/SET_SOUND', 'secondary_hover')
    },
    onClick() {
      this.$store.commit('user/SET_SOUND', 'secondary_click')
      this.$store.commit('user/ADD_MESSAGE', { type: 'closed' })

      // let params = {}
      // _.each(this.$route.params, (param, idx)=>{
      //   params[idx] = undefined
      // })
      // this.$store.commit('user/USE_TRANSITION', params)
    },

  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'
@import '~@/commons/stylus/Media-queries'
@import './styl/Hamburger-icon'

.Navigation

  position fixed
  width 20vw
  top 0px
  left 0px
  right 0px
  z-index 99999
  color blue-dark
  display flex
  flex-direction row
  justify-content space-between
  align-items center
  font-weight 900
  font-family montserrat
  font-size 14px

  &.is_experience
    background-color transparent
    pointer-events none
    .Navigation__block
      pointer-events all

  &__block
    display flex
    flex-direction row
    align-items center
    padding 24px
    max-height 90px 

  &__close
    width 10px
    margin 0px 14px 0 2px
    transition opacity .5s, transform .25s
    position absolute

   &__txt
    transition opacity .5s, transform .35s
    padding-left 24px

  &__leave
    display flex
    flex-direction row
    align-items center
    border 2px solid orange
    border-radius 20px
    font-size 12px
    width 120px
    height 40px
    padding 0 24px 0 16px
    cursor pointer
    color orange
    position relative

    &.en 
      width 100px
    
    &:hover 
      .Navigation__close
        transform rotate(180deg)

      .Navigation__txt
        transform translateX(8px)

  &__language
    width 40px
    height 40px
    display flex
    align-items center
    justify-content center
    font-size 14px
    line-height 15px
    font-size 12px
    cursor pointer

    &.is_experience
      color orange

    &.active
      border-radius 50%
      border 2px solid
      text-transform uppercase
      pointer-events none
      cursor initial

  &__link-container
    border-bottom 0px solid
    margin 0 24px
    padding-bottom 8px
    text-align center
    max-width 126px

    &.active
      border-bottom 2px solid

  .cls-1
    fill blue-dark
    font-size 27px
    font-family monument !important
    font-weight 500

  .cls-2
    fill none
    stroke #271961
    stroke-miterlimit 10
    stroke-width 2px

  .cls-3
    fill none
    stroke orange
    stroke-width 2px

  .orange
    color orange

  .blue-dark
    color blue-dark

</style>
