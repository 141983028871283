<script>
import {Howl, Howler} from 'howler'
import spriteJSON from './audio/sounds.json'

export default {
  name: 'Sound',
  render: h => h(),
  beforeDestroy() {
    this.$sound.unload()
  },
  beforeCreate() {
    this.$sound = new Howl({
      src: [require('./audio/sounds.mp3')],
      sprite: spriteJSON.sprite
    })
    this.$ambiance = new Howl({
      src: [require('./audio/ambiance.mp3')],
      loop: true,
      volume: .4
    })
  },
  computed: {
    sound() { return this.$store.getters['user/sound'] },
    volume() { return this.$store.getters['user/volume'] },
  },
  watch: {
    sound() {
      if(this.sound) {
        const id = this.$sound.play(this.sound)
        const split = this.sound.split('_')[1]
        const volume = split == 'hover' || split == 'click' ? .5 : 1
        this.$sound.volume(volume)
        this.$sound.once('end', () => this.$store.commit('user/SET_SOUND', null), id)
      } else 
        this.$sound.stop()
    },

    'volume': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{ 
          Howler.volume(this.volume)
        })
      }
    },

    '$route': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{
          if(this.$route.params.introduction == undefined) {
            this.$ambiance.play()
            this.$ambiance.fade(0, .4, 1000)
          }     
          else {
            this.$ambiance.fade(.4, 0, 1000)
            TweenMax.delayedCall(1, ()=>{ this.$ambiance.stop() })
          }
            
        })
      }
    }
  },
}
</script>

