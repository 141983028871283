<template>
  <div ref="borders" class="Borders">
    <div class="Borders__block"></div>
    <div class="Borders__block"></div>
  </div>
</template>

<script>

export default {

  name: 'Borders',
  data: () => ({
    initialized: false
  }),

  beforeDestroy() {
  },

  methods: {
    adjustCanvas(pos) {
      if(!this.initialized) {
        this.initialized = true
        this.resizeBorders(pos)
        return
      }
      let borders_x = -this.$margin
      switch(pos) {
        case 'left':
          borders_x = 0
          break
        case 'right':
          borders_x = -(this.$margin*2)
          break
      }
      TweenMax.to(this.$refs.borders, 1,{ x: borders_x, ease: Cubic.easeOut })
    },
    resizeBorders(pos) {
      const size = Math.round(window.innerWidth*.36)
      this.$margin = (size < 484) ? size : 484
      TweenMax.set(this.$refs.borders, { css: { width: window.innerWidth+(this.$margin*2) + "px", x: -this.$margin }})

      if(pos) {
        this.adjustCanvas(pos)
        return
      }
    },
  },

  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{
          if(this.$route.params.camera && !this.$route.params.items) this.adjustCanvas('right')
          else if(this.$route.params.items && !this.$route.params.path) this.adjustCanvas('left')
          else this.adjustCanvas('center')
        })
      }
    }
  },

}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Borders
  position fixed
  top 0
  left 0
  height 100vh

  display flex
  align-items center
  justify-content space-between

  &__block
    width 36vw
    max-width 484px
    height 100vh
    top 0px
    background-color #111a8d

</style>























