export default {
  author: 'Origin',
  name: 'Origin',
  cookies: {
    lang: 'origin_lang',
  },
  locale: ['fr', 'en'],
  loader: true,
  mode: 'out-in',
  prefixRoute: false,
  cms: true,
  matrix: {
    coef: 78,
    scene_size: { width: 1368, height: 768 },
    column: 57,
    row: 32,
    square_size: 24,
    correctif_x: 0,
    correctif_y: 0,
    start: [28, 27],
  },
  generic_path: [
    [2.153846153846154,4.46153846153846],
    [2.1411113846153853,4.46153846153846],
    [2.127537230769231,4.46153846153846],
    [2.1131458461538464,4.46153846153846],
    [2.097959384615385,4.46153846153846],
    [2.0820000000000007,4.46153846153846],
    [2.0652898461538465,4.46153846153846],
    [2.0478510769230773,4.46153846153846],
    [2.029705846153847,4.46153846153846],
    [2.010876307692308,4.46153846153846],
    [1.9913846153846158,4.46153846153846],
    [1.9712529230769233,4.46153846153846],
    [1.9505033846153847,4.46153846153846],
    [1.9291581538461544,4.46153846153846],
    [1.907239384615385,4.46153846153846],
    [1.8847692307692312,4.46153846153846],
    [1.8617698461538463,4.46153846153846],
    [1.8382633846153846,4.46153846153846],
    [1.8142720000000003,4.46153846153846],
    [1.7898178461538463,4.46153846153846],
    [1.764923076923077,4.46153846153846],
    [1.7396098461538465,4.46153846153846],
    [1.7139003076923078,4.46153846153846],
    [1.6878166153846155,4.46153846153846],
    [1.6613809230769232,4.46153846153846],
    [1.634615384615385,4.46153846153846],
    [1.607542153846154,4.46153846153846],
    [1.5801833846153848,4.46153846153846],
    [1.552561230769231,4.46153846153846],
    [1.524697846153846,4.46153846153846],
    [1.4966153846153847,4.46153846153846],
    [1.468336,4.46153846153846],
    [1.4398818461538463,4.46153846153846],
    [1.411275076923077,4.46153846153846],
    [1.3825378461538462,4.46153846153846],
    [1.3536923076923078,4.46153846153846],
    [1.3247606153846152,4.46153846153846],
    [1.2957649230769228,4.46153846153846],
    [1.2667273846153848,4.46153846153846],
    [1.2376701538461539,4.46153846153846],
    [1.2086153846153846,4.46153846153846],
    [1.1795852307692307,4.46153846153846],
    [1.1506018461538463,4.46153846153846],
    [1.1216873846153845,4.46153846153846],
    [1.092864,4.46153846153846],
    [1.0641538461538462,4.46153846153846],
    [1.0355790769230768,4.46153846153846],
    [1.007161846153846,4.46153846153846],
    [0.9789243076923075,4.46153846153846],
    [0.9508886153846152,4.46153846153846],
    [0.9230769230769234,4.46153846153846],
    [0.8953833846153849,4.46153846153846],
    [0.8676824615384618,4.46153846153846],
    [0.8399667692307695,4.46153846153846],
    [0.8122289230769234,4.46153846153846],
    [0.7844615384615388,4.46153846153846],
    [0.7566572307692311,4.46153846153846],
    [0.7288086153846158,4.46153846153846],
    [0.7009083076923082,4.46153846153846],
    [0.6729489230769237,4.46153846153846],
    [0.6449230769230775,4.46153846153846],
    [0.6168233846153853,4.46153846153846],
    [0.5886424615384623,4.46153846153846],
    [0.5603729230769239,4.46153846153846],
    [0.5320073846153855,4.46153846153846],
    [0.5035384615384625,4.46153846153846],
    [0.4749587692307701,4.46153846153846],
    [0.4462609230769239,4.46153846153846],
    [0.41743753846153936,4.46153846153846],
    [0.3884812307692317,4.46153846153846],
    [0.35938461538461636,4.46153846153846],
    [0.33014030769230857,4.46153846153846],
    [0.300740923076924,4.46153846153846],
    [0.2711790769230779,4.46153846153846],
    [0.24144738461538565,4.46153846153846],
    [0.21153846153846273,4.46153846153846],
    [0.1814449230769242,4.46153846153846],
    [0.15115938461538575,4.46153846153846],
    [0.12067446153846272,4.46153846153846],
    [0.08998276923077045,4.46153846153846],
    [0.059076923076924324,4.46153846153846],
    [0.02794953846153974,4.46153846153846],
    [-0.0034067692307679087,4.46153846153846],
    [-0.03499938461538337,4.46153846153846],
    [-0.06683569230769104,4.46153846153846],
    [-0.09892307692307563,4.46153846153846],
    [-0.13126892307692176,4.46153846153846],
    [-0.16388061538461418,4.46153846153846],
    [-0.19676553846153721,4.46153846153846],
    [-0.22993107692307566,4.46153846153846],
    [-0.2633846153846141,4.46153846153846],
    [-0.29713353846153717,4.46153846153846],
    [-0.33118523076922946,4.46153846153846],
    [-0.36554707692307564,4.46153846153846],
    [-0.4002264615384602,4.46153846153846],
    [-0.43523076923076787,4.46153846153846],
    [-0.47056738461538333,4.46153846153846],
    [-0.506243692307691,4.46153846153846],
    [-0.5422670769230756,4.46153846153846],
    [-0.5786449230769218,4.46153846153846],
    [-0.6153846153846132,4.46153846153846],
    [-0.6526116923076902,4.461839999999999],
    [-0.6904319999999979,4.462719999999998],
    [-0.7288233846153823,4.464141538461537],
    [-0.7677636923076901,4.46606769230769],
    [-0.8072307692307671,4.468461538461537],
    [-0.8472024615384592,4.471286153846153],
    [-0.8876566153846134,4.474504615384614],
    [-0.9285710769230748,4.478079999999998],
    [-0.9699236923076902,4.481975384615383],
    [-1.0116923076923057,4.486153846153845],
    [-1.0538547692307672,4.49057846153846],
    [-1.096388923076921,4.495212307692306],
    [-1.139272615384613,4.50001846153846],
    [-1.1824836923076902,4.504959999999998],
    [-1.225999999999998,4.509999999999998],
    [-1.2697993846153826,4.515101538461537],
    [-1.3138596923076906,4.5202276923076905],
    [-1.3581587692307675,4.525341538461537],
    [-1.4026744615384596,4.530406153846152],
    [-1.4473846153846137,4.535384615384613],
    [-1.4922670769230755,4.540239999999998],
    [-1.537299692307691,4.544935384615383],
    [-1.582460307692306,4.549433846153844],
    [-1.627726769230768,4.553698461538461],
    [-1.6730769230769216,4.557692307692307],
    [-1.718488615384614,4.56137846153846],
    [-1.763939692307691,4.564719999999999],
    [-1.8094079999999988,4.5676799999999975],
    [-1.8548713846153837,4.570221538461537],
    [-1.9003076923076911,4.572307692307691],
    [-1.9456947692307685,4.573901538461537],
    [-1.9910104615384607,4.574966153846152],
    [-2.036232615384615,4.575464615384614],
    [-2.081339076923076,4.575359999999998],
    [-2.126307692307692,4.574615384615383],
    [-2.1711163076923072,4.573193846153845],
    [-2.215742769230769,4.57105846153846],
    [-2.260164923076923,4.568172307692306],
    [-2.3043606153846152,4.564498461538459],
    [-2.348307692307692,4.559999999999999],
    [-2.3919840000000003,4.554639999999998],
    [-2.4353673846153843,4.548381538461536],
    [-2.4784356923076922,4.54118769230769],
    [-2.5211667692307693,4.533021538461536],
    [-2.563538461538461,4.523846153846152],
    [-2.6055286153846158,4.513624615384614],
    [-2.647115076923077,4.502319999999997],
    [-2.6882756923076925,4.489895384615382],
    [-2.728988307692308,4.476313846153844],
    [-2.7692307692307683,4.46153846153846],
    [-2.809470769230768,4.445484307692307],
    [-2.8501661538461533,4.428120615384614],
    [-2.891279999999999,4.40949169230769],
    [-2.932775384615384,4.389641846153845],
    [-2.9746153846153836,4.368615384615383],
    [-3.016763076923076,4.346456615384614],
    [-3.0591815384615377,4.323209846153845],
    [-3.1018338461538453,4.298919384615384],
    [-3.1446830769230756,4.273629538461538],
    [-3.187692307692307,4.247384615384615],
    [-3.230824615384614,4.220228923076922],
    [-3.2740430769230757,4.1922067692307685],
    [-3.3173107692307684,4.163362461538461],
    [-3.3605907692307677,4.133740307692307],
    [-3.4038461538461524,4.1033846153846145],
    [-3.447039999999999,4.072339692307691],
    [-3.4901353846153835,4.040649846153844],
    [-3.5330953846153834,4.0083593846153835],
    [-3.575883076923076,3.9755126153846145],
    [-3.6184615384615375,3.942153846153845],
    [-3.660793846153845,3.9083273846153834],
    [-3.702843076923076,3.8740775384615374],
    [-3.744572307692307,3.8394486153846143],
    [-3.785944615384614,3.804484923076922],
    [-3.8269230769230758,3.7692307692307687],
    [-3.867470769230768,3.7337304615384608],
    [-3.907550769230768,3.6980283076923066],
    [-3.947126153846152,3.6621686153846147],
    [-3.986159999999998,3.6261956923076917],
    [-4.024615384615383,3.590153846153845],
    [-4.062455384615383,3.554087384615384],
    [-4.099643076923075,3.5180406153846144],
    [-4.136141538461537,3.4820578461538454],
    [-4.171913846153844,3.446183384615384],
    [-4.206923076923076,3.410461538461538],
    [-4.241132307692306,3.3749366153846143],
    [-4.274504615384614,3.339652923076922],
    [-4.307003076923075,3.3046547692307686],
    [-4.338590769230768,3.2699864615384606],
    [-4.369230769230768,3.235692307692307],
    [-4.398886153846153,3.201816615384615],
    [-4.427519999999999,3.1684036923076917],
    [-4.455095384615383,3.1354978461538456],
    [-4.4815753846153825,3.103143384615384],
    [-4.5069230769230755,3.0713846153846154],
    [-4.531101538461537,3.040265846153846],
    [-4.554073846153845,3.009831384615384],
    [-4.575803076923075,2.9801255384615377],
    [-4.596252307692305,2.9511926153846146],
    [-4.615384615384613,2.9230769230769234],
    [-4.633115076923075,2.895265230769231],
    [-4.649412923076921,2.867229538461539],
    [-4.664322461538459,2.838992],
    [-4.6778879999999985,2.8105747692307697],
    [-4.6901538461538435,2.7820000000000005],
    [-4.701164307692306,2.753289846153846],
    [-4.71096369230769,2.7244664615384617],
    [-4.7195963076923055,2.695552],
    [-4.72710646153846,2.666568615384616],
    [-4.733538461538459,2.637538461538462],
    [-4.738936615384613,2.6084836923076926],
    [-4.743345230769228,2.579426461538462],
    [-4.746808615384614,2.5503889230769237],
    [-4.749371076923075,2.521393230769231],
    [-4.751076923076921,2.492461538461539],
    [-4.75197046153846,2.4636160000000005],
    [-4.752095999999998,2.4348787692307696],
    [-4.7514978461538435,2.4062720000000004],
    [-4.750220307692306,2.3778178461538464],
    [-4.74830769230769,2.349538461538462],
    [-4.745804307692305,2.321456],
    [-4.74275446153846,2.2935926153846156],
    [-4.739202461538459,2.265970461538462],
    [-4.735192615384613,2.238611692307692],
    [-4.730769230769228,2.2115384615384617],
    [-4.725976615384613,2.184772923076923],
    [-4.720859076923075,2.158337230769231],
    [-4.715460923076921,2.1322535384615384],
    [-4.7098264615384595,2.106544],
    [-4.703999999999998,2.0812307692307694],
    [-4.698025846153843,2.056336],
    [-4.691948307692305,2.0318818461538464],
    [-4.68581169230769,2.0078904615384614],
    [-4.679660307692306,1.9843840000000001],
    [-4.673538461538459,1.9613846153846155],
    [-4.66749046153846,1.9389144615384617],
    [-4.661560615384613,1.916995692307692],
    [-4.655793230769229,1.8956504615384615],
    [-4.6502326153846125,1.874900923076923],
    [-4.644923076923075,1.8547692307692307],
    [-4.63990892307692,1.8352775384615383],
    [-4.635234461538459,1.8164479999999998],
    [-4.630943999999998,1.798302769230769],
    [-4.627081846153844,1.7808639999999998],
    [-4.6236923076923055,1.764153846153846],
    [-4.620819692307689,1.7481944615384615],
    [-4.618508307692305,1.7330079999999999],
    [-4.616802461538459,1.7186166153846154],
    [-4.61574646153846,1.7050424615384614],
    [-4.615384615384613,1.6923076923076923]
  ],

  parade_generic_path: [
    [1.8461538461538467,3.5384615384615383],
    [1.843075692307693,3.544979692307692],
    [1.8399901538461543,3.5522067692307693],
    [1.8368898461538468,3.5601132307692303],
    [1.833767384615385,3.5686695384615383],
    [1.8306153846153852,3.5778461538461537],
    [1.827426461538462,3.587613538461538],
    [1.8241932307692315,3.597942153846154],
    [1.820908307692308,3.6088024615384615],
    [1.8175643076923083,3.620164923076923],
    [1.814153846153847,3.6319999999999997],
    [1.810669538461539,3.6442781538461535],
    [1.8071040000000007,3.656969846153846],
    [1.8034498461538466,3.670045538461538],
    [1.7996996923076929,3.6834756923076917],
    [1.7958461538461543,3.697230769230769],
    [1.7918818461538468,3.7112812307692304],
    [1.787799384615385,3.7255975384615385],
    [1.7835913846153852,3.7401501538461535],
    [1.7792504615384621,3.754909538461538],
    [1.7747692307692313,3.7698461538461534],
    [1.7701403076923083,3.784930461538461],
    [1.7653563076923082,3.800132923076923],
    [1.7604098461538469,3.8154239999999997],
    [1.755293538461539,3.8307741538461535],
    [1.7500000000000007,3.8461538461538454],
    [1.7445218461538465,3.861533538461538],
    [1.7388516923076929,3.876883692307692],
    [1.7329821538461545,3.8921747692307687],
    [1.7269058461538467,3.90737723076923],
    [1.7206153846153853,3.9224615384615373],
    [1.714103384615385,3.937398153846153],
    [1.7073624615384622,3.952157538461538],
    [1.7003852307692313,3.9667101538461536],
    [1.6931643076923082,3.981026461538461],
    [1.685692307692308,3.995076923076923],
    [1.6779618461538468,4.008832],
    [1.669965538461539,4.022262153846152],
    [1.6616960000000005,4.035337846153845],
    [1.6531458461538469,4.048029538461538],
    [1.6443076923076927,4.060307692307691],
    [1.6351741538461544,4.072142769230768],
    [1.6257378461538465,4.083505230769229],
    [1.615991384615385,4.094365538461537],
    [1.605927384615385,4.104694153846153],
    [1.595538461538462,4.114461538461537],
    [1.5848172307692312,4.123638153846153],
    [1.5737563076923082,4.132194461538461],
    [1.562348307692308,4.140100923076922],
    [1.5505858461538466,4.147327999999999],
    [1.5384615384615392,4.153846153846153],
    [1.5258498461538468,4.159756307692307],
    [1.5126449230769239,4.165188923076922],
    [1.4988689230769239,4.170158769230769],
    [1.4845440000000008,4.174680615384615],
    [1.4696923076923085,4.17876923076923],
    [1.4543360000000007,4.1824393846153844],
    [1.4384972307692316,4.185705846153846],
    [1.4221981538461548,4.188583384615384],
    [1.405460923076924,4.191086769230769],
    [1.3883076923076931,4.193230769230769],
    [1.3707606153846164,4.195030153846153],
    [1.3528418461538472,4.196499692307692],
    [1.3345735384615391,4.197654153846154],
    [1.3159778461538474,4.1985083076923075],
    [1.2970769230769241,4.199076923076922],
    [1.277892923076924,4.199374769230769],
    [1.258448000000001,4.1994166153846155],
    [1.2387643076923085,4.19921723076923],
    [1.2188640000000008,4.198791384615384],
    [1.1987692307692317,4.198153846153845],
    [1.1785021538461549,4.197319384615384],
    [1.158084923076924,4.196302769230769],
    [1.137539692307693,4.195118769230769],
    [1.116888615384616,4.193782153846152],
    [1.0961538461538471,4.192307692307692],
    [1.0753575384615395,4.190710153846153],
    [1.054521846153847,4.189004307692307],
    [1.033668923076924,4.187204923076922],
    [1.0128209230769238,4.185326769230769],
    [0.992000000000001,4.1833846153846155],
    [0.9712283076923086,4.18139323076923],
    [0.9505280000000009,4.179367384615384],
    [0.9299212307692317,4.177321846153845],
    [0.9094301538461549,4.175271384615384],
    [0.8890769230769241,4.173230769230769],
    [0.8688836923076934,4.171214769230769],
    [0.8488726153846163,4.169238153846153],
    [0.8290658461538469,4.167315692307691],
    [0.8094855384615391,4.165462153846153],
    [0.790153846153847,4.1636923076923065],
    [0.7710929230769239,4.1620209230769225],
    [0.752324923076924,4.160462769230768],
    [0.733872000000001,4.159032615384615],
    [0.7157563076923086,4.15774523076923],
    [0.6980000000000008,4.1566153846153835],
    [0.6806252307692316,4.155657846153845],
    [0.6636541538461549,4.154887384615384],
    [0.647108923076924,4.154318769230769],
    [0.6310116923076933,4.1539667692307685],
    [0.6153846153846168,4.153846153846153],
    [0.6001218461538476,4.1539667692307685],
    [0.5850978461538475,4.154318769230769],
    [0.5703052307692321,4.154887384615384],
    [0.5557366153846167,4.155657846153846],
    [0.5413846153846168,4.156615384615384],
    [0.5272418461538476,4.15774523076923],
    [0.5133009230769244,4.159032615384614],
    [0.49955446153846306,4.160462769230769],
    [0.4859950769230784,4.1620209230769225],
    [0.4726153846153861,4.1636923076923065],
    [0.45940800000000154,4.165462153846153],
    [0.44636553846154,4.1673156923076915],
    [0.4334806153846169,4.169238153846154],
    [0.4207458461538477,4.171214769230769],
    [0.40815384615384764,4.173230769230768],
    [0.3956972307692323,4.175271384615384],
    [0.38336861538461686,4.177321846153846],
    [0.3711606153846169,4.179367384615384],
    [0.3590658461538477,4.18139323076923],
    [0.3470769230769246,4.183384615384615],
    [0.33518646153846304,4.185326769230769],
    [0.32338707692307844,4.187204923076923],
    [0.3116713846153861,4.189004307692307],
    [0.3000320000000015,4.190710153846154],
    [0.28846153846153993,4.192307692307692],
    [0.2769526153846169,4.193782153846153],
    [0.26549784615384764,4.195118769230769],
    [0.25408984615384766,4.196302769230769],
    [0.24272123076923227,4.197319384615384],
    [0.2313846153846169,4.198153846153845],
    [0.22007261538461687,4.198791384615384],
    [0.20877784615384767,4.1992172307692295],
    [0.19749292307692456,4.199416615384615],
    [0.1862104615384631,4.199374769230769],
    [0.17492307692307843,4.199076923076922],
    [0.16362338461538606,4.1985083076923075],
    [0.1523040000000015,4.197654153846154],
    [0.1409575384615399,4.196499692307691],
    [0.1295766153846169,4.195030153846153],
    [0.1181538461538476,4.193230769230769],
    [0.10668184615384765,4.191086769230769],
    [0.09515323076923218,4.188583384615384],
    [0.08356061538461686,4.185705846153846],
    [0.07189661538461684,4.1824393846153844],
    [0.06015384615384764,4.17876923076923],
    [0.04832492307692453,4.174680615384615],
    [0.03640246153846302,4.170158769230769],
    [0.024379076923078363,4.165188923076923],
    [0.012247384615386075,4.159756307692307],
    [1.7763568394002505e-15,4.153846153846153],
    [-0.01254892307692129,4.1473883076923075],
    [-0.02556061538461359,4.14033723076923],
    [-0.039005538461536655,4.132715076923076],
    [-0.05285415384615203,4.124543999999999],
    [-0.06707692307692126,4.115846153846153],
    [-0.08164430769230586,4.106643692307692],
    [-0.0965267692307674,4.096958769230769],
    [-0.11169476923076738,4.086813538461538],
    [-0.12711876923076737,4.076230153846153],
    [-0.14276923076922887,4.065230769230769],
    [-0.15861661538461347,4.053837538461538],
    [-0.1746313846153827,4.0420726153846145],
    [-0.19078399999999807,4.029958153846153],
    [-0.20704492307692113,4.017516307692308],
    [-0.22338461538461343,4.004769230769231],
    [-0.23977353846153648,3.9917390769230763],
    [-0.2561821538461519,3.9784479999999993],
    [-0.27258092307692117,3.964918153846153],
    [-0.2889403076923057,3.951171692307692],
    [-0.3052307692307673,3.937230769230769],
    [-0.32142276923076735,3.9231175384615375],
    [-0.3374867692307673,3.9088541538461534],
    [-0.3533932307692288,3.8944627692307687],
    [-0.3691126153846135,3.8799655384615384],
    [-0.38461538461538264,3.865384615384615],
    [-0.39987199999999806,3.8507421538461535],
    [-0.4148529230769211,3.8360603076923074],
    [-0.42952861538461345,3.8213612307692304],
    [-0.44386953846153654,3.806667076923077],
    [-0.45784615384615185,3.792],
    [-0.4714289230769212,3.7773821538461534],
    [-0.4845883076923059,3.762835692307692],
    [-0.4972947692307672,3.7483827692307687],
    [-0.5095187692307673,3.734045538461538],
    [-0.5212307692307674,3.719846153846153],
    [-0.5324012307692287,3.705806769230769],
    [-0.5430006153846134,3.691949538461538],
    [-0.5529993846153827,3.678296615384615],
    [-0.562367999999998,3.6648701538461537],
    [-0.571076923076921,3.651692307692307],
    [-0.5790966153846134,3.6387852307692308],
    [-0.5863975384615363,3.626171076923076],
    [-0.5929501538461519,3.6138719999999998],
    [-0.5987249230769212,3.601910153846153],
    [-0.6036923076923056,3.5903076923076918],
    [-0.6078227692307674,3.5790867692307686],
    [-0.611086769230767,3.568269538461538],
    [-0.6134547692307671,3.5578781538461532],
    [-0.6148972307692285,3.5479347692307686],
    [-0.6153846153846132,3.5384615384615383],
    [-0.6147766153846133,3.5294732307692303],
    [-0.6129821538461516,3.520955076923077],
    [-0.6100455384615363,3.512884923076923],
    [-0.6060110769230748,3.505240615384615],
    [-0.6009230769230748,3.4979999999999998],
    [-0.5948258461538439,3.491140923076923],
    [-0.5877636923076902,3.4846412307692307],
    [-0.579780923076921,3.4784787692307693],
    [-0.570921846153844,3.4726313846153842],
    [-0.561230769230767,3.467076923076923],
    [-0.5507519999999979,3.4617932307692305],
    [-0.539529846153844,3.456758153846154],
    [-0.5276086153846133,3.451949538461538],
    [-0.5150326153846133,3.4473452307692303],
    [-0.5018461538461517,3.4429230769230763],
    [-0.4880935384615364,3.438660923076923],
    [-0.4738190769230748,3.434536615384615],
    [-0.4590670769230748,3.430528],
    [-0.443881846153844,3.4266129230769224],
    [-0.4283076923076902,3.42276923076923],
    [-0.41238892307692093,3.418974769230769],
    [-0.396169846153844,3.415207384615384],
    [-0.3796947692307671,3.4114449230769224],
    [-0.3630079999999979,3.40766523076923],
    [-0.34615384615384404,3.4038461538461533],
    [-0.3291766153846133,3.3999655384615375],
    [-0.3121206153846133,3.3960012307692296],
    [-0.2950301538461517,3.3919310769230764],
    [-0.27794953846153636,3.387732923076922],
    [-0.2609230769230748,3.383384615384614],
    [-0.24399507692307482,3.378863999999999],
    [-0.22720984615384404,3.374148923076922],
    [-0.21061169230769017,3.3692172307692294],
    [-0.19424492307692093,3.364046769230768],
    [-0.17815384615384405,3.3586153846153834],
    [-0.16238276923076717,3.3529009230769216],
    [-0.14697599999999789,3.3468812307692297],
    [-0.131977846153844,3.3405341538461526],
    [-0.11743261538461325,3.333837538461537],
    [-0.10338461538461335,3.326769230769229],
    [-0.08987815384615183,3.3193070769230757],
    [-0.07695753846153652,3.3114289230769214],
    [-0.06466707692307494,3.303112615384614],
    [-0.05305107692307487,3.2943359999999986],
    [-0.0421538461538441,3.2850769230769217],
    [-0.032019692307690306,3.2753132307692288],
    [-0.022692923076921168,3.265022769230767],
    [-0.014217846153844138,3.254183384615383],
    [-0.0066387692307672275,3.242772923076921],
    [1.7763568394002505e-15,3.23076923076923],
    [0.005910153846155619,3.2182781538461533],
    [0.011342769230770999,3.20542523076923],
    [0.01631261538461715,3.192217846153845],
    [0.0208344615384633,3.1786633846153833],
    [0.024923076923078682,3.16476923076923],
    [0.028593230769232526,3.1505427692307686],
    [0.03185969230769407,3.135991384615384],
    [0.034737230769232526,3.1211224615384605],
    [0.037240615384617134,3.105943384615384],
    [0.039384615384617135,3.090461538461538],
    [0.04118400000000174,3.0746843076923067],
    [0.0426535384615402,3.058619076923076],
    [0.04380800000000175,3.04227323076923],
    [0.04466215384615558,3.025654153846153],
    [0.045230769230770976,3.00876923076923],
    [0.04552861538461713,2.9916258461538456],
    [0.04557046153846328,2.974231384615384],
    [0.045371076923078665,2.95659323076923],
    [0.044945230769232514,2.9387187692307686],
    [0.04430769230769405,2.9206153846153837],
    [0.04347323076923251,2.902290461538461],
    [0.04245661538461712,2.883751384615384],
    [0.04127261538461713,2.865005538461538],
    [0.039936000000001755,2.846060307692307],
    [0.03846153846154021,2.826923076923076],
    [0.03686400000000173,2.80760123076923],
    [0.0351581538461556,2.7881021538461535],
    [0.03335876923077097,2.7684332307692303],
    [0.03148061538461712,2.748601846153846],
    [0.029538461538463262,2.728615384615384],
    [0.02754707692307866,2.70848123076923],
    [0.025521230769232475,2.6882067692307685],
    [0.023475692307694057,2.667799384615384],
    [0.021425230769232473,2.647266461538461],
    [0.019384615384617124,2.6266153846153837],
    [0.017368615384617148,2.6058535384615373],
    [0.01539200000000171,2.5849883076923073],
    [0.01346953846154017,2.564027076923076],
    [0.011616000000001736,2.54297723076923],
    [0.009846153846155586,2.521846153846153],
    [0.008174769230770984,2.50064123076923],
    [0.006616615384617164,2.4793698461538454],
    [0.005186461538463333,2.458039384615384],
    [0.003899076923078615,2.4366572307692302],
    [0.0027692307692325224,2.415230769230768],
    [0.0018116923076940405,2.393767384615384],
    [0.0010412307692325151,2.3722744615384608],
    [0.0004726153846171255,2.350759384615384],
    [0.00012061538461716204,2.329229538461538],
    [1.7763568394002505e-15,2.3076923076923075],
    [1.7763568394002505e-15,2.285790769230769],
    [1.7763568394002505e-15,2.263187692307692],
    [1.7763568394002505e-15,2.2399199999999997],
    [1.7763568394002505e-15,2.2160246153846153],
    [1.7763568394002505e-15,2.191538461538461],
    [1.7763568394002505e-15,2.166498461538462],
    [1.7763568394002505e-15,2.1409415384615382],
    [1.7763568394002505e-15,2.1149046153846154],
    [1.7763568394002505e-15,2.0884246153846155],
    [1.7763568394002505e-15,2.061538461538461],
    [1.7763568394002505e-15,2.0342830769230766],
    [1.7763568394002505e-15,2.006695384615384],
    [1.7763568394002505e-15,1.9788123076923076],
    [1.7763568394002505e-15,1.950670769230769],
    [1.7763568394002505e-15,1.9223076923076923],
    [1.7763568394002505e-15,1.8937599999999999],
    [1.7763568394002505e-15,1.8650646153846153],
    [1.7763568394002505e-15,1.8362584615384616],
    [1.7763568394002505e-15,1.8073784615384612],
    [1.7763568394002505e-15,1.778461538461538],
    [1.7763568394002505e-15,1.7495446153846153],
    [1.7763568394002505e-15,1.7206646153846152],
    [1.7763568394002505e-15,1.6918584615384613],
    [1.7763568394002505e-15,1.6631630769230765],
    [1.7763568394002505e-15,1.6346153846153846],
    [1.7763568394002505e-15,1.6062523076923072],
    [1.7763568394002505e-15,1.5781107692307688],
    [1.7763568394002505e-15,1.5502276923076919],
    [1.7763568394002505e-15,1.5226399999999995],
    [1.7763568394002505e-15,1.495384615384615],
    [1.7763568394002505e-15,1.4684984615384613],
    [1.7763568394002505e-15,1.4420184615384612],
    [1.7763568394002505e-15,1.415981538461538],
    [1.7763568394002505e-15,1.390424615384615],
    [1.7763568394002505e-15,1.3653846153846152],
    [1.7763568394002505e-15,1.3408984615384612],
    [1.7763568394002505e-15,1.3170030769230765],
    [1.7763568394002505e-15,1.2937353846153843],
    [1.7763568394002505e-15,1.2711323076923071],
    [1.7763568394002505e-15,1.249230769230769],
    [1.7763568394002505e-15,1.2280676923076919],
    [1.7763568394002505e-15,1.2076799999999996],
    [1.7763568394002505e-15,1.188104615384615],
    [1.7763568394002505e-15,1.169378461538461],
    [1.7763568394002505e-15,1.1515384615384614],
    [1.7763568394002505e-15,1.1346215384615381],
    [1.7763568394002505e-15,1.1186646153846151],
    [1.7763568394002505e-15,1.1037046153846153],
    [1.7763568394002505e-15,1.0897784615384614],
    [1.7763568394002505e-15,1.0769230769230769]
  ],

  // 0 : cinematographe
  // 1 : bell-&-howell
  // 2 : bolex
  // 3 : aaton
  // 4 : portapak
  // 5 : gopro

  scene_translations: {
    en: {
      'the-swim': 'plongeon',
      'the-lovers': 'amoureux',
      'civic-engagement': 'manifestation',
      'the-parade': 'parade',
      'hide-and-seek': 'jeu'
    },
    fr: {
      'le-plongeon': 'plongeon',
      'les-amoureux': 'amoureux',
      'lengagement-citoyen': 'manifestation',
      'la-parade': 'parade',
      'le-jeu-de-cache-cache': 'jeu'
    },
  },
  index_to_id: [86, 52, 56, 94, 96, 88],
  comparaisons: {
    plongeon: {
      'cinematographe': 5,
      'bell-&-howell': 0,
      'bolex': 0,
      'portapak': 0,
      'aaton': 0,
      'gopro': 0,
    },
    amoureux: {
      'cinematographe': 1,
      'bell-&-howell': 3,
      'bolex': 1,
      'portapak': 1,
      'aaton': 1,
      'gopro': 1,
    },
    jeu: {
      'cinematographe': 2,
      'bell-&-howell': 2,
      'bolex': 1,
      'portapak': 2,
      'aaton': 2,
      'gopro': 2,
    },
    manifestation: {
      'cinematographe': 4,
      'bell-&-howell': 4,
      'bolex': 4,
      'portapak': 1,
      'aaton': 4,
      'gopro': 4,
    },
    parade: {
      'cinematographe': 3,
      'bell-&-howell': 3,
      'bolex': 3,
      'portapak': 3,
      'aaton': 1,
      'gopro': 3,
    },
  }
}
