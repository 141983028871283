<template>
  <div class="Matrix">
    <div ref="container" class="Matrix__container">
      <Square :ref="'squares_' + obj.x +'_'+ obj.y" :key="array.top" v-for="(obj, indice) in array" :x="obj.x" :y="obj.y" />
    </div>
    <div class="Block left small">
      <div class="Block container middle">
        <div class="Matrix__sub-block">

          <transition name="Matrix__resume">
            <div class="Matrix__resume" 
              v-if="actived" 
              @click="onResume()"
              @mouseenter="mouseenter">
              <button class="uppercase" :aria-label="trans('resume')">{{ trans('resume') }}</button>
            </div>
          </transition>

          <transition name="Matrix__default">
            <div ref="default_button" class="Matrix__default" v-if="!actived" @click="onDefault()">
              <button class="uppercase" :aria-label="trans('default')">{{ trans('default') }}</button>
            </div>
          </transition>
         
          <!-- <transition name="Matrix__button"> -->
            <!-- 
            <button 
              class="Btn Matrix__button" 
              :aria-label="trans('continue')" 
              @click="onContinue()"
              v-if="be_continue" >{{ trans('continue') }}</button> -->

            <!-- <BtnGeneral class="Matrix__button" :txt="trans('continue')" @click="onContinue()" v-if="be_continue"/> -->
          <!-- </transition> -->

        </div>
      </div>
    </div>
    <div ref="block_right" class="Block right small">
      <div class="Matrix__witness"> 
        <div class="Matrix__empty top"></div>
         <div class="Matrix__center">
            <div class="Matrix__inner center"/>
            <div class="Matrix__inner"><CameraWitness v-if="is_ready"/></div>
            <div class="Matrix__inner center"/>
         </div>
        <div class="Matrix__empty bottom"></div>
      </div>
    </div>
    <PathFinder ref="pathFinder"/>

    <div v-if="$is_static" class="Page__header">    
      <h1 ref="title">{{ trans('title') }}</h1>
      <div ref="description"><h4>{{ trans('description') }}</h4></div>
    </div>

    <div v-else class="Page__header">    
      <h1 ref="title">{{ trans('title_bis') }}</h1>
      <div ref="description"><h4>{{ trans('description_bis') }}</h4></div>
    </div>


    <div ref="btn_footer" class="Page__footer footer">
      <BtnGeneral class="Matrix__button" :txt="trans('continue')" @click="onContinue()" 
      :class="{ 'is-inactive': !be_continue }"/>
    </div>
  </div>
</template>

<script>

import Settings from '@/config/Settings'
import Mixin from '@/core/mixins/Matrix'
import Square from './Square'
import { Scenes } from '@/components/scene/js/data.js'
import CameraWitness from '@/components/camera-babylon/CameraWitness'
import BtnGeneral from '@/components/assets/BtnGeneral'

// import Buttons from './Buttons'

import PathFinder from './PathFinder'

import { Mesh, MeshBuilder, Texture, Curve3, Vector3, Path3D, FreeCamera } from 'babylonjs'
import 'babylonjs-loaders'
import Hashids from 'hashids'

export default {

  name: 'Matrix',
  mixins: [ Mixin ],
  components: { Square, PathFinder, CameraWitness, BtnGeneral },
  translations: {
    en: {
      title_bis: "Plot your camera’s path",
      description_bis: "Determine how you would like your camera to move by clicking on five spots in the yellow cross-hatched area.",
      btnAvatar: 'Add avatar',
      btnParcours: 'Add route',
      continue: 'Next',
      resume: 'Clear',
      default: 'Position the camera',
      path: 'Plot your camera’s path',
      title: 'Position the camera',
      description: "To select where you would like to position your camera, click inside the yellow cross-hatched area."
      // description: 'Détermine le trajet que tu souhaites faire avec ta caméra en plaçant 5 points dans la zone hachurée jaune.'
    },
    fr: {

      title_bis: "Trace le trajet de ta caméra",
      description_bis: "Détermine le trajet que tu souhaites faire avec ta caméra en plaçant 5 points dans la zone hachurée jaune.",
      btnAvatar: 'Ajouter le cinéaste',
      btnParcours: 'Ajouter le parcours',
      continue: 'Poursuivre',
      resume: 'Replace la caméra',
      default: 'Place la caméra',
      path: 'Trace le trajet de ta caméra',
      title: 'Positionne ta caméra',
      description: "Clique dans la zone hachurée jaune l'endroit où tu souhaites te placer avec ta caméra pour filmer la scène"
      // description: 'Détermine le trajet que tu souhaites faire avec ta caméra en plaçant 5 points dans la zone hachurée jaune.'
    },
  },

  data: () => ({

    // Mixin extend

    closed: true,
    context: null,
    showBtnAvatar: false,
    array: [],
    mode: 'build',
    str: '',
    actived: false,
    is_first: true,
    is_ready: false,
    be_continue: false,
    max: 5,
    min: 2
  }),

  beforeDestroy() {
    this.$cameraman.lookAt(this.$camera_target.position)
    if(this.$tween_audio) this.$tween_audio.kill()
    document.removeEventListener('keypress', this.onKeyPress)
    this.removePoints()
    _.each(this.$meshes, m => m.dispose())
  },

  created() {

    this.$path = null
    this.$meshes = []
    this.$points = []
    this.$grid = []
    this.$scene = this.$parent.$babylon.scene
    this.$is_static = this.camera.id == 86 || this.camera.id == 52 ? true : false

    // this.$is_static = false

    this.context = (this.$is_static) ? 'Avatar' : 'Parcours'

    // this.$camera = this.$parent.cameraOrthographic
    
    this.$cameraman = this.$scene.getMeshByName('cameraman')
    this.$target = this.$scene.getMeshByName('target')
    this.$camera_target = this.$scene.getMeshByName('camera_target')

    this.$container = new Mesh('', this.$scene)
    this.$container.position.y = .5
    this.$points.push(this.$container)

    // this.$meshes.push(this.$container)

    this.$start = new Mesh('', this.$scene)
    this.$start.position.y = .1
    this.$start.parent = this.$container

    // this.$scene.registerAfterRender(() =>{ this.onLoop() })

    // this.$id = 7
    // this.$zone = Scenes[this.$id].zone

    // this.$zone = Scenes['zones'][this.$route.params.scene]
    
    this.$zone = Scenes['zones'][Settings.scene_translations[this.lang][this.$route.params.scene]]

    this.$positions = []
    this.$points3d = []
    this.$hashids = new Hashids()

    // this.setWitnessCamera()
    document.addEventListener('keypress', this.onKeyPress)
  },

  mounted() {

    TweenMax.set(this.$refs.default_button, { opacity: 0 })

    // this.$audio = this.getAudio('path')
    // const delay = (this.changed == 0) ? 3 : 1
    // this.$tween_audio = TweenMax.delayedCall(delay, ()=>{
    //   this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
    // })

    // this.$tween = new TimelineMax({})
    //   .staggerFrom([ this.$refs.title, this.$refs.description ], .75, { alpha: 0, y: window.innerHeight*.25, ease: Cubic.easeOut, delay: 1 }, .2)

    // this.$tween = TweenMax.delayedCall(1, ()=>{

    this.init3D()

    // console.log(this.$route.params);

    // })

    // if(this.mode === 'dev') {

    //   let x = 0
    //   let y = 0
    //   for(let i=0; i<(this.column*this.row); i++) {
    //     this.array.push({ x: x, y: y })
    //     x += 1
    //     if(x == this.column){
    //       x = 0
    //       y += 1
    //     }
    //   }

    // } else {

    //   this.$grid = this.initializeGrid()

    //   _.each(this.$zone, (sq)=>{
    //     let x = sq[0]
    //     let y = sq[1]
    //     this.array.push({ x: x, y: y })
    //     this.$grid[y][x] = 0
    //   })

    // }

    // // this.addElements()
    // this.updatePositionStart(this.start)

    // document.addEventListener('keypress', this.onKeyPress)
    // this.$scene.activeCamera = this.$camera
    // this.resizeMatrixContainer()
    // this.adjustCanvas()


    // const grd = [[14,8], [14,11], [14,14], [14,17], [14,20], [14,23], [15,25], [17,26], [20,27], [23,27], [26,27], [29,27], [32,27], [35,27], [38,27], [40,26], [41,24], [42,22], [42,20], [42,17], [42,14], [42,11], [42,7]]

    // const grd = [[6,31], [7,31], [8,31], [9,31], [10,31], [11,31], [12,31], [13,31], [14,31], [15,31], [16,31], [17,31], [18,31], [19,31], [20,31], [21,30], [22,29], [23,28], [24,27], [25,26], [26,25], [27,25], [28,25], [29,25], [31,25], [30,25], [32,25], [33,25], [34,25], [35,25], [36,25], [37,25], [39,25], [38,25], [40,24], [41,23], [42,22], [43,21], [43,20], [43,19], [43,18], [43,17], [43,16], [43,15], [43,14], [43,13], [43,12], [43,11], [43,10], [43,9], [43,8], [43,7]]


    // const pts = []
    // _.each(grd, (pt)=>{
    //   pts.push(this.getPos(pt))
    //   console.log(this.getPos(pt));
    // })

    // console.log(pts);

    this.addIntro()
  },

  methods: {

    onKeyPress(event) {

      console.log(this.str);

      // console.log(this.$scene.lights[0]);
      // this.addIntro()
      // this.closed = !this.closed
    },
    addIntro() {
      this.$nextTick(()=>{
        this.$tl = new TimelineMax({ 
          // onComplete: ()=>{ 
            // TweenMax.to(this.$refs.default_button, .2, { opacity: 1 }) 
          // },
          paused: true 
        })  
          .staggerFrom([ 
            this.$refs.title,
            this.$refs.description, 
            ], .5, { y: 75, opacity: 0, ease: Cubic.easeOut }, .25)
          .from([this.$refs.btn_footer], .75, { y: 100, opacity: 0, ease: Cubic.easeOut, delay: 3.75 })
          .call(() => {
            TweenMax.to(this.$refs.default_button, .2, { opacity: 1 }) 
          })
        this.$tween = TweenMax.delayedCall(1.5, ()=>{
          this.$tl.play()
        })
            
      })
    },

    init3D() {

      if(this.mode === 'dev') {

        let x = 0
        let y = 0
        for(let i=0; i<(this.column*this.row); i++) {
          this.array.push({ x: x, y: y })
          x += 1
          if(x == this.column){
            x = 0
            y += 1
          }
        }

      } else {

        this.$grid = this.initializeGrid()

        _.each(this.$zone, (sq)=>{
          let x = sq[0]
          let y = sq[1]
          this.array.push({ x: x, y: y })
          this.$grid[y][x] = 0
        })
      }

      this.updatePositionStart(this.start)
      // document.addEventListener('keypress', this.onKeyPress)
      // this.$scene.activeCamera = this.$camera
      this.resizeMatrixContainer()
      // this.adjustCanvas()

      this.is_ready = true
    },

    updatePositionStart(pos) {
      const ps = this.getPos(pos)
      this.$start.position.x = ps[0]
      this.$start.position.z = ps[1]
      this.$cameraman.position.x = ps[0]
      this.$cameraman.position.z = ps[1]

      // if(Settings.scene_translations[this.lang][this.$route.params.scene] != 'parade')
        this.$cameraman.lookAt(this.$camera_target.position)
      // else 
        // this.$cameraman.lookAt(new Vector3(0, 0, -1))
 
    },

    // adjustCanvas() {
    //   TweenMax.set(this.$refs.container, { css: { marginLeft: this.adjust_canvas } })
    // },

    resizeMatrixContainer() {

      // let scale = Math.min(window.innerWidth/1920, window.innerHeight/1080)*(window.innerHeight/1080)  

      let scale = (window.innerHeight/1080)*(window.innerHeight/1080)

      // console.log('===================');

      // console.log(Math.min(window.innerWidth/1920, window.innerHeight/1080));
      // console.log(window.innerWidth/1920, window.innerHeight/1080);
      // // console.log(window.innerHeight/1080);

      // // let scale = window.innerHeight/1080
      // console.log(scale);
      // console.log('==== ');
      // console.log('==== '+ (1920/1080));
      // console.log('==== '+ (window.innerWidth/window.innerHeight));

      TweenMax.set('.Matrix__container', { scale: scale })

      const bounding = this.$refs['container'].getBoundingClientRect()
      // console.log(bounding.width, bounding.height);
    },

    beContinue() {
      this.be_continue = ( this.$is_static && this.actived 
                        || !this.$is_static && this.actived && this.$positions.length >= this.min ) ? true : false
    },

    mouseenter() {
      this.$store.commit('user/SET_SOUND', 'tertiaire_hover')
    },
    onResume() {
      this.actived = false
      this.be_continue = false
      this.$parent.$refs.scene.cameraman_is_visible = false
      this.$positions = []
      this.removePoints()
      this.removeCurve()
      this.$store.commit('user/SET_SOUND', 'tertiaire_click')
    },
    onDefault() {
      this.$store.commit('user/SET_SOUND', 'tertiaire_click')
      this.addPoint(this.$zone[0][0], this.$zone[0][1])
    },

    onContinue() {
      if(!this.$path && !this.$is_static)
        return

      // console.log('onContinue');

      let arr = []
      const data = (this.$is_static) ? this.$positions : this.$path
      _.each(data, (pt)=>{
        arr.push(pt[0])
        arr.push(pt[1])
      })

      const encode = this.$hashids.encode(arr)

      this.$store.commit('user/USE_TRANSITION', { path: encode })

      // this.$tween = TweenMax.to(this.$el, .25, {
      //   alpha: 0,
      //   ease: Linear.easeOut,
      //   onComplete: ()=>{ 

      //     // this.$router.push({ params: { path: encode } }) 
      //     this.$store.commit('user/USE_TRANSITION', { path: encode })

      //   },
      //   delay: .5
      // })


    },

    initializeGrid() {
      let x = 0
      let y = 0
      let array = []
      for(let i=0; i<(this.column*this.row); i++) {
        if(!array[y]) array[y] = []
        array[y][x] = 1
        x += 1
        if(x == this.column) {
          x = 0
          y += 1
        }
      }
      return array
    },

    notMorePoint() {
      let res = (this.actived && this.$is_static
              || !this.$is_static && this.$positions.length == this.max) ? true : false
      return res
    },

    addPointSelf() {
      if(this.$is_static) this.addPoint(40, 30)
      else this.addParcours()
    },

    addParcours() {
      this.onResume()
      const parcours = [[20, 24],[30, 28],[35, 29],[39, 26],[43, 15]]
      _.each(parcours, (p)=>{
        this.createPoint(p[0],p[1])
      })

      this.drawCurve()
      this.actived = true
      this.updatePositionStart(_.last(parcours))
      this.updateCameraWitness()
      this.beContinue()

    },

    addPoint(x, y) {

      console.log(x,y);

      this.$store.commit('user/SET_SOUND', 'secondary_click')

      this.actived = true
      this.updatePositionStart([x,y])

      if(this.closed) {
        TweenMax.delayedCall(1, ()=> this.closed = false)
      }

      if(this.$is_static) {

        this.$parent.$refs.scene.cameraman_is_visible = true
        this.$points[0] = this.$container
        this.$positions[0] = [x,y]

        // this.updatePositionStart([x,y])
        // this.actived = true

        if(this.is_first) {
          this.is_first = false
          // this.$audio = this.getAudio('static')
          // this.$tween_audio = TweenMax.delayedCall(1, ()=>{
          //   this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
          // })
        }

        this.beContinue()
        return
      }

      this.$parent.$refs.scene.cameraman_is_visible = true
      this.updateCameraWitness()
      this.createPoint(x,y)
      if(this.$positions.length > 1) this.drawCurve()
      this.beContinue()
    },

    createPoint(x, y) {
      const pos = this.getPos([x,y])
      const px = pos[0]
      const py = pos[1]

      let point = this.createGround({ width: .12, height: .12 }, require('@/commons/img/small_circle.png'), this.$scene)
          point.parent = this.$container
          point.position.x = px
          point.position.z = py

      // this.$points.push(this.$container)

      this.$points.push(point)
      this.$positions.push([x,y])
    },

    drawCurve() {
      this.removeCurve()
      this.$points3d = []
      this.$path = this.$refs['pathFinder'].getPath(this.$grid, this.$positions, null)
      this.$line = this.getCurveLine(this.$path)
      this.$curve = new MeshBuilder.CreateLines('track', { points: this.$line.getPoints() }, this.$scene)
      this.$curve.color = new BABYLON.Color3(255, 255, 0)
      this.$curve.parent = this.$container
    },

    removeCurve() {
      if(this.$curve)
        this.$curve.dispose()
    },

    removePoints() {
      _.each(this.$points, m => m.dispose())
    },

    // addElements() {
    //   let point = this.createGround({ width: .12, height: .12 }, require('@/commons/img/small_circle.png'), this.$scene)
    //       point.parent = this.$start
    //   let circle = this.createGround({ width: 1.02, height: 1.02 }, require('@/commons/img/circle.png'), this.$scene)
    //       circle.parent = this.$start
    // },

    isGrid(x, y) {
      this.str += '['+ x +','+ y +'], '
    },

    // setWitnessCamera() {
    //   this.$lens = this.$scene.getMeshByName('cameraman_lens')
    //   TweenMax.set(this.$lens, { babylon: { rotationX: 67, rotationY: 0, y: 15 } })
    // },

    updateCameraWitness() {

      // console.log(this.$lens);
      return

      if(this.actived) {
        const speed = (this.$is_static) ? 3 : 0
        TweenMax.to(this.$lens, speed, { babylon: { rotationX: 0, rotationY: 0, y: 2 }, ease: Power2.easeOut })
      } else {
        TweenMax.to(this.$lens, 1, { babylon: { rotationX: 67, rotationY: 0, y: 15 } })
        this.updatePositionStart(this.start) 
      }
    },

  },

  watch: {
    point(nw, od) {
      this.addPoint(nw.x, nw.y)
    },
    '$root.width': 'resizeMatrixContainer',
    '$root.height': 'resizeMatrixContainer',
  },

  computed: {
    camera() { return this.$store.getters['user/camera'] },
    point() { return this.$store.getters['user/point'] },
    changed() { return this.$store.getters['user/changed'] }
  }

}

</script>

<style lang="stylus">

@import '~@/config/Settings'

.Matrix
  position absolute
  overflow hidden
  width 100%
  height 100%

  &__container
    position absolute
    width 1368px
    height 768px
    margin 0
    top 50%
    left 50%
    transform translate(-50%, -50%)
    // border 1px solid red

  &__button 
    opacity 1
    transition opacity .5s, transform .5s
    
    &-enter
    &-leave-active
      transform translateY(100px)
      opacity 0

  &__btn-avatar
    opacity 0
    &.is-active
      opacity 1

  &__resume
    position absolute
    cursor pointer
    height 64px
    transition opacity .5s, transform .5s
    
    &-enter
    &-leave-active
      transform translateY(100px)
      opacity 0

    &:hover 
      .uppercase
        transform translateY(-8px)

  &__default
    position absolute
    cursor pointer
    height 64px
    transition opacity .5s, transform .5s
    
    &-enter
    &-leave-active
      transform translateY(100px)
      opacity 0

  & .uppercase 
    transition transform .5s

  &__sub-block
    width 100%
    display flex
    justify-content center
    align-items center
    flex-direction column

  &__square
    position absolute
    outline 1px solid grey
    width 10px
    height 10px

  &__witness
    width 100%
    height 100%
    display flex
    flex-direction column
    justify-content center
    align-items center

  &__empty 
    width 100%
    height 100%

    &.top 
      background-image url('./images/block_top.png')
      background-size cover
      background-position repeat repeat

    &.bottom 
      background-image url('./images/block_bottom.png')
      background-size cover
      background-position repeat repeat

  &__center
    width 100%
    height 100%
    display flex 
    justify-content space-between

  &__inner 
    width 100%
    height auto
    &.center
      background-image url('./images/block_center.png')
      background-size cover
      background-position repeat repeat
 
  .Block
    pointer-events none    

    &.container
      padding 25vh 32px

      &.middle
        display flex
        justify-content center
        align-items center
        flex-direction column

    & button 
      pointer-events all

  .Title
    padding-bottom 4vh

</style>




























































