<template>
  <div class="Defilement" :class="{ 'closed': closed }">
    <div ref="bar" class="Defilement__container"></div>
  </div>
</template>

<script>

export default {

  name: 'Defilement',
  data: ()=>({  
    closed: true
  }),
  computed:{
    recording() { return this.$store.getters['user/recording'] },
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{ 
          this.$store.commit('user/SET_RECORDING', false)
        })
      }
    },
    recording(nw, od) { 
      if(nw)
        this.addMotion()
      else 
        this.closed = true
    },
  },
  methods: {
    addMotion() {
      this.$tween = new TimelineMax({ delay: 1 })
          .set(this.$refs.bar, { width: '.5%'})
          .call(()=>{ this.closed = false })
          .from(this.$el, .5, { opacity: 1 })
          .to(this.$refs.bar, 14, { width: '100%', ease: Linear.easeNone })
    },
  },

}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Defilement
  position fixed
  left 0
  bottom 0px
  display flex   
  background rgba(white, .5)
  width 100vw
  height 8px
  border-radius 8px

  &.closed 
    bottom -100px

  &__container 
    width 100%
    height 100%
    background orange
    border-radius 8px

</style>
