<template>
  <div :ref="id" :id="id" class="Dropzone" :class="color">
    <!-- <img :src="require('./images/important.png')" v-if="color=='failed'"> -->
    <div class="Dropzone__question" v-if="color=='none'">?</div>
  </div>
</template>

<script>

export default {

  name: 'Dropzone',
  props: ['id'],
  data: () => ({
    color: 'none',
    current: null,
  }),

  mounted(){
    this.$parent.dropzone.push(this.$refs[this.id])
  },

  watch: {
    drag_end(nw, od) {
      if(nw.dropzone == this.id) {
        this.current = this.$parent.current_obj.cover.id
        if(!this.$parent.current_obj.is_valid) 
          this.color = 'failed'
        else 
          this.color = 'used'
      }
    },
    drag_back(nw, od) {
      if(this.current == this.$parent.current_obj.cover.id) {
        this.color = 'none'
        this.current = null
      }
    }
  },
  
  computed: {
    drag_end() { return this.$store.getters['user/drag_end'] },
    drag_back() { return this.$store.getters['user/drag_back'] }
  },
}

</script>

<style lang="stylus">

@import '~@/config/Settings'

.Dropzone

  width 120px
  height 120px
  border 2px dashed orange
  border-radius 50%
  margin 32px 16px

  &.failed
    border-color yellow

  &.used
    border-color orange

  &__question
    font-family sourcecode
    font-size 1.5vw
    line-height 1.52vw
    font-weight 500
    display flex
    justify-content center
    align-items center
    height 120px

</style>




























































