import Settings from '@/config/Settings'

export default {
  data: () => ({
		$prevLeave: null
  }),
  computed: {
    mode() {
			return this.$store.getters['app/mode']
    }
  },
  beforeCreate() {
    this.$router.beforeEach((to, redirect, next) => {
			this.$store.commit('app/SET_FROM_ROUTE', this.$route)
			if(this.$refs.page)
					this.$prevLeave = this.$refs.page.leave
			next()
    })

    this.$router.afterEach(to => {
			this.$store.commit('app/SET_TO_ROUTE', to)

			if(this.lang != to.meta.lang)
				this.$store.commit('app/LANG_CHANGED', to.meta.lang)

			this.$root.setLangRouteNames()
    })
  },
  methods: {
    afterEnter() {
			if(this.mode == 'in-out' && this.mode != Settings.mode)
				this.$store.commit('app/RESET_MODE')
    },
    afterLeave() {
			if(this.mode == 'out-in' || this.mode == '' && this.mode != Settings.mode)
				this.$store.commit('app/RESET_MODE')
    },
    beforeEnter() {
			window.scrollTo(0,0)
    },
    enter(el, done) {
			this.$refs.page.enter
			? this.$refs.page.enter(done)
			: TweenMax.from(el, .5, { alpha: 0, onComplete: done  })
    },
    leave(el, done) {
			this.$prevLeave
			? this.$prevLeave(done)
			: TweenMax.to(el, .5, { alpha: 0, onComplete: done  })
    }
  }
}
