<template>
  <div class="Animation">
    <audio :src="userScene.audio" ref="audio" />
    <audio :src="$sound_camera.url" ref="camera"/>
  </div>
</template>
<script>

import { Mesh, SceneLoader, Sound } from 'babylonjs'
import 'babylonjs-loaders'
import Settings from '@/config/Settings'

export default {
  name: 'Animation',
  data: ()=>({
    loop: 0,
    count: 0
  }),
  beforeDestroy() {
    if(this.$tween) this.$tween.kill()
    this.$anime.dispose()
    _.each(this.$meshes, m => m.dispose())
  },
  created() {

    console.log('Animation!');

    this.$meshes = []
    this.$scene = this.$parent.$scene
    this.$container = this.$parent.createMesh('', this.$scene)
    this.$container.parent = this.$parent.$container
    this.$animation = this.userScene['babylon_animation'].instantiateModelsToScene()
    this.$animation.rootNodes[0].parent = this.$container
    this.$anime = this.$animation.animationGroups[0]
    this.$anime.onAnimationGroupEndObservable.add(() => { this.animationCompleted() })
    this.$anime.onAnimationGroupLoopObservable.add(() => { this.addLoop() })
    this.$meshes.push(this.$container)

    if(Settings.scene_translations[this.lang][this.$route.params.scene] == 'parade'
    || Settings.scene_translations[this.lang][this.$route.params.scene] == 'jeu') {
      _.each(this.$animation.rootNodes[0].getChildMeshes(), (c,i) => {
        c.alwaysSelectAsActiveMesh = true
      })
    }    

    // console.log(this.userScene);
    // console.log(this.$anime);
    // console.log("---------- : ");

    // this.$root.animation = this.$anime
    // console.log(this.$anime.speedRatio);
    // this.$store.commit('user/SET_ANIMATION', this.$anime)

    // console.log("xxxxxxxxx !");
    // console.log('camera_' +this.camera.fr.slug.split('-')[0] +'');

    this.$sound_camera = Settings.scene_translations[this.lang][this.$route.params.scene] == 'parade' && this.camera.id == 86
        ? this.getAudio('camera_cinematographe_parade')[this.lang]
        : this.getAudio('camera_' +this.camera.fr.slug.split('-')[0] +'')[this.lang]

    // console.log(this.userScene.audio);
    // console.log(this.camera.fr.slug);
    // console.log(this.$sound_camera);

  },

  methods: {
    play(b) {

      // this.$anime.goToFrame(4)
      // this.$anime.pause()
      // return

      this.$store.commit('user/SET_SOUND', null)

      this.$refs.audio.muted = this.animation_muted
      this.$refs.camera.muted = this.camera_muted

      if(this.$route.params.camera == 'bell-&-howell')
        this.$refs.camera.volume = .5

      this.count += 1
      if(b) this.$anime.play(true)
      else this.$anime.play()
      this.playAudio()
    },
    playAudio() {

      // console.log(this.userScene.audio);
      // console.log('.');
      // console.log(this.$refs.audio);
      // console.log(this.$refs.audio.volume);

      // this.$refs.audio.volume = 0.1
      // console.log('-||-----');
      // this.$refs.camera.volume = 0.1
      // console.log('..');
      // this.$refs.audio.pause()
      // console.log('...');
      // this.$refs.audio.currentTime = 0
      // console.log('....');
      // this.$refs.audio.play()
      // console.log('------');

      if(this.$route.params.path == undefined)
        return

      if(this.$refs.camera || this.camera.id != 94)
        this.addCamera()

      if(this.$refs.audio)
        this.addAudio()

    },

    addAudio() {
      this.$refs.audio.pause()
      this.$refs.audio.currentTime = 0
      this.$refs.audio.play()
    },
    addCamera() {
      this.$refs.camera.pause()
      this.$refs.camera.currentTime = 0
      this.$refs.camera.play()
    },

    reset() {
      this.$anime.play()
      this.resetLoop()
      TweenMax.delayedCall(.1, ()=>{

        this.$anime.goToFrame(0)
        this.$anime.pause()

        if(this.$refs.audio)
          this.$refs.audio.pause()

        if(this.$refs.camera)
          this.$refs.camera.pause()
      })
    },
    pause() {
      if(this.$refs.audio)
        this.$refs.audio.pause()
      if(this.$refs.camera)
        this.$refs.camera.pause()
      this.$anime.pause()
    },
    goToStart() {
      this.$anime.goToFrame(0)
      this.$anime.pause()
    },
    animationCompleted() {

      // if(!this.$route.params.single)
      //   this.$tween = TweenMax.delayedCall(2, ()=>{ this.$router.push({ params: { process: 'process' }}) })


      // console.log(this.$route.params);
      

      // if(!this.$route.params.single)
      //   this.$tween = TweenMax.delayedCall(2, ()=>{ 
      //     this.$store.commit('user/USE_TRANSITION', { process: 'process' })
      //   })

      // else 
      this.$store.commit('user/ANIMATION_COMPLETED')

      // else if(this.$route.params.single)
      //   this.$tween = TweenMax.delayedCall(2, ()=>{ this.$router.push({ params: { double: true }}) })

    },

    resetLoop() {
      this.loop = 0
      this.$store.commit('user/CURRENT_LOOP', this.loop)
    },

    addLoop() {
      this.playAudio()
      if(!this.$route.params.double)
        return
      this.loop += 1
      this.$store.commit('user/CURRENT_LOOP', this.loop)
    },

  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{

          // return

          this.reset()
          this.count = 0
          // if(this.$route.params.path != undefined && this.$route.params.process == undefined)
          //   return

          if(this.$route.params.path != undefined && this.$route.params.process == undefined)
            return

          else {
            // const delay = this.changed == 0 ? 4 : 2.1
            const delay = 2.1
            TweenMax.delayedCall(delay, ()=>{
              this.play()
            })
          }

          // TEMP 

          // TweenMax.delayedCall(.25, ()=>{
          //   this.play(true)
          // }) 

          // OIRIGNAL

          // if(this.$route.params.single && this.$route.params.double === undefined) {

          //   console.log('xxxx');

          //   TweenMax.delayedCall(2, this.play)
          // }
          // else if(this.$route.params.double) {

          //   console.log('xc');

          //   TweenMax.delayedCall(1, ()=>{
          //     this.play(true)
          //   }) 
          // }

        })
      }
    },
    viewport(nw, od) {
      if(this.$route.params.single != undefined || this.$route.params.double != undefined) {
        this.reset()

       if(this.count > 0)
         TweenMax.delayedCall(.25, this.play)
      }
    },
    camera_muted(nw, od) {
      this.$refs.camera.muted = nw
    },
    animation_muted(nw, od) {
      this.$refs.audio.muted = nw
    },
    'volume': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{ 
          if(this.$refs.audio)
            this.$refs.audio.volume = this.volume

          if(this.$refs.camera)
            this.$refs.camera.volume = this.volume
        })
      }
    },
  },

  computed: {
    userScene() { return this.$store.getters['user/scene'] },
    animation_muted() { return this.$store.getters['user/animation_muted'] },
    camera_muted() { return this.$store.getters['user/camera_muted'] },
    camera() { return this.$store.getters['user/camera'] },
    viewport() { return this.$store.getters['user/viewport'] },
    changed() { return this.$store.getters['user/changed']},
    volume() { return this.$store.getters['user/volume']},
  },

}
</script>




















