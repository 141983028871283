<template>
  <div class="Message">

    

    <transition name="Message__container">
      <VueFocusTrap ref="trap" @open="focusFirst" @gofirst="focusFirst" @golast="focusLast">
        <div class="Message__container" v-if="message.type == 'closed'">

          <div class="Message__background" @click="onClick('continue')"/>

          <div class="Message__circle">  
            <button ref="close" class="Message__close" @click="onClick('continue')">
              <!-- <img :src="require('@/commons/img/icon_close.png')"> -->


              <svg viewBox="0 0 13.989 16.934">
                <g id="Group_44" data-name="Group 44" transform="translate(-46.159 -32.865)">
                  <path id="Path_68" data-name="Path 68" class="cls-3" d="M0,6.445,7.832,0l7.832,6.445" transform="translate(53.376 33.5) rotate(90)"/>
                  <path id="Path_69" data-name="Path 69" class="cls-3" d="M0,0,7.832,6.445,15.663,0" transform="translate(59.376 33.5) rotate(90)"/>
                </g>
              </svg>
            </button>
            <h3 class="Message__row" v-html="trans('quit.title')"/>
            <h4 v-if="$route.params.single" class="Message__row" v-html="trans('quit.empty')"/>
            <h4 v-else class="Message__row" v-html="trans('quit.description')"/>

            <div class="Message__buttons"> 

              <!-- 
              <button class="Btn border" :aria-label="trans('closed')" @click="onClick('closed')">
                {{ trans('closed') }}
              </button> 
              -->

              <BtnGeneral :txt="trans('closed')" :add="'border'" @click="onClick('closed')" :fx="'secondary'"/>
              <div class="Message__space" />

              <!-- 
              <button class="Btn reverse" :aria-label="trans('continue')" @click="onClick('continue')">
                {{ trans('continue') }}
              </button> 
              -->

              <BtnGeneral :txt="trans('continue')" :add="'reverse'" @click="onClick('continue')" :fx="'secondary'"/>

            </div>
          </div>
        </div>
        <div class="Message__container" v-if="message.type == 'message'" @click="onClick('closed')">
          <div class="Message__circle message">  
            <img :src="require('@/commons/img/icon_close.png')">
            <h4 class="Message__row message">{{ message.txt }}</h4> 
          </div>
        </div>
      </VueFocusTrap>
    </transition>

  </div>
</template>

<script>

import { VueFocusTrap } from "vue-a11y-utils"
import BtnGeneral from '@/components/assets/BtnGeneral'

export default {

  name: 'Message',
  components: { VueFocusTrap, BtnGeneral },
  translations: {
    fr: {
      closed: "Quitter",
      continue: "Continuer",
      quit: {
        title: "Es-tu certain de vouloir</br>quitter?",
        description: "Ton film sera perdu si tu ne complètes pas l'expérience de tournage.",
        empty: "</br></br>"
      },
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar maximus ultrices. Interger eros mi, interdum vel fringilla sed, auctor vitae nulla."
    },

    // Your film will be lost if you don’t complete the activity.

    en: {
      closed: "Exit",
      continue: "Continue",
      quit: {
        title: "Are you sure you want to exit?",
        description: "Your film will be lost if you don’t</br>complete the activity.",
        empty: "</br></br>"
      },
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar maximus ultrices. Interger eros mi, interdum vel fringilla sed, auctor vitae nulla."
    },
  },
  computed: {
    message() { return this.$store.getters['user/message'] },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.trap.open()
    })
  },
  methods: {
    onClick(indice) {
      if(this.message.type != "message" && indice == 'closed') {
        this.$store.commit('user/SET_SOUND', 'secondary_click')
        TweenMax.delayedCall(.25, ()=>{   
          const link = this.lang == 'fr' 
            ? "https://macameraetmoi.ca/"
            : "https://macameraetmoi.ca/?lang=en"
          window.location.href = link
        })
      }
      else  {
        this.$store.commit('user/SET_SOUND', 'secondary_click')
        this.$store.commit('user/ADD_MESSAGE', { type: null })
        this.$refs.trap.close()
      }
    },
    focusFirst() {
      this.$refs.close.focus()
    },
    focusLast() {
      this.$refs.close.focus()
    },
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Message
  position fixed
  z-index 10000000
  top 0
  left 0

  &__background 
    position absolute
    background rgba(red, 0)
    width 100vw
    height 100vh 
    z-index 0

  &__container 
    width 100vw
    height 100vh 
    background rgba(blue-dark, .75)
    display flex
    align-items center
    justify-content center
    opacity 1
    transition opacity .25s 
    transition-delay .25s

    z-index 1
  
    &-enter
    &-leave-active
      opacity 0

  & h4 
    font-family montserrat
    font-size 1.05rem
    line-height 1.35rem
    font-weight 600
    // font-size max(1vw, 11px)
    // line-height max(1.2vw, 14px)

  &__circle 
    position relative
    color blue-dark
    background orange
    width 80vh 
    max-width 600px
    max-height 600px
    height 80vh
    border-radius 50%
    display flex
    align-items center
    justify-content center
    text-align center
    flex-direction column

    &.message 
      cursor pointer
      max-width 400px
      max-height 400px

  &__close 
    position absolute 
    cursor pointer
    width 16px
    height 16px 
    display flex
    justify-content center
    align-items center
    top 8%

  .cls-3
    fill none
    stroke blue-dark
    stroke-width 2px

  &__row 
    width 75%
    padding 16px 0

    &.message 
      padding-top 32px

  &__buttons
    padding-top 48px 
    display flex 
    justify-content center  

  &__space 
    width 1vw

</style>
















