<script>
import Mixin from '@/core/mixins/CameraFinal'
import { Vector2, Viewport } from 'babylonjs'
import 'babylonjs-loaders'

export default {
    name: 'CameraGoPro',
    mixins: [ Mixin ],
    render: h => h(),
    data: ()=>({  
        fov: 1.6, // 10mm = 1.6 | 35mm = 1.3 | 50mm = .8
    }),
    beforeDestroy() {
        if(this.$tween)
            this.$tween.kill()
        this.$camera.position = new Vector3(0, 0, 0)
    },
    created() {
        this.$engine = this.$parent.$engine
        this.$camera.rotation.x = 10 * (Math.PI/180)
        this.$camera.fov = this.fov
        this.$target.position.y = 1.6
        this.$target.position.z = -.45
    },
    mounted() {

        // console.log('Camera GoPro');

        this.$nextTick(()=>{
            // this.animation.speedRatio = 1
            this.$store.commit('user/SET_MOTION', 'idle_jog')
            this.muteAnimation(false)
            this.updateCanvas()
            this.addPostProcess()
            this.addMotion()
        })

        // let pipeline = new BABYLON.DefaultRenderingPipeline(
        //     "default", // The name of the pipeline
        //     true, // Do you want HDR textures ?
        //     this.$scene, // The scene instance
        //     [ this.$camera ] // The list of cameras to be attached to
        // )

        // pipeline.depthOfFieldBlurLevel = BABYLON.DepthOfFieldEffectBlurLevel.Medium
        // pipeline.depthOfFieldEnabled = true
        // pipeline.depthOfField.focusDistance  = 2000 // distance of the current focus point from the camera in millimeters considering 1 scene unit is 1 meter
        // pipeline.depthOfField.focalLength  = 20

        // console.log(this.$camera);
        // console.log(pipeline);
        // new BABYLON.BlurPostProcess("Horizontal blur", new Vector2(1.0, 0), 100.0, 1.0, this.$camera)

    },
    methods: {
      addPostProcess() {},
      addMotion() {

        this.$tween = new TimelineMax()

        for(let i=0; i<2; i++){
          this.$tween.to(this.$camera, .425, { 
            babylon: { 
                x: 1,
                y: -.25,
            },   
            ease: Cubic.easeNone,
          })
          this.$tween.to(this.$camera, .425, { 
            babylon: { 
                x: -1,
                y: 0,
            },   
            ease: Cubic.easeNone,
          })
          this.$tween.to(this.$camera, .425, { 
            babylon: { 
                x: 1,
                y: -.25,
            },   
            ease: Cubic.easeNone,
          
          })
          this.$tween.to(this.$camera, .425, { 
            babylon: { 
                x: -1,
                y: 0,
            },   
            ease: Cubic.easeNone,
          })
        }

        this.$tween.call(()=>{ this.$store.commit('user/SET_MOTION', 'jog_idle') })

        for(let i=0; i<10; i++){
          this.$tween.to(this.$camera, _.random(2, 3), { 
              babylon: { 
                  x: _.random(-.1, .1),
                  y: _.random(-.25, .25),
                  z: _.random(0, .2),
              }, 
              yoyo: true, 
              ease: _.random(1,2) == 2 ? Cubic.easeOut : Linear.easeNone,
              repeat: _.random(1,2) , 
              // delay: _.random(.5,2) 
          })
          this.$tween.to(this.$camera, _.random(.2,1), { 
              babylon: { 
                  x: _.random(-.2, .2),
                  y: _.random(-.1, .1),
              } 
          })
        }
      },
    },
}
</script>


