import Hashids from 'hashids'
const hashids = new Hashids()

export default {
  namespaced: true,
  state: {
    camera: null,
    scene: null,
    point: null,
    avatar: null,
    narration: null,
    items: null,
    path: null,
    adjust_canvas: 0,
    drag_end: null,
    drag_back: null,
    loop: 0,
    animation_completed: 0,
    breadcrumb_back: 0,
    animation_muted: false,
    changed: -1,
    use_transition: 'initial',
    use_loader: false,
    loader_added: 0,
    other_loading: false,
    prev_container: null,
    is_active: false,
    viewport: 'origine',
    final_camera: '',
    message: {
      type: null,
      txt: null
    },
    rotation: 0,
    camera_muted: false,
    animation: null,
    recording: false,
    sound_ended: false,
    motion: null,
    is_recording: false,
    iris: 'none',
    sound: null,
    show_avatar: true,
    step: 0,
    slide: 0,
    ideal_camera: 0,
    volume: 1
  },
  mutations: {
    'ROUTE_CHANGED' (state, data, context) {
      state.camera = _.find(this.state.data.cameras, camera => camera[this.state.app.lang].slug == this.state.app.route.to.params.camera)
      state.scene = _.find(this.state.data.scenes, scene => scene[this.state.app.lang].slug == this.state.app.route.to.params.scene)
      
      // this.state.app.route.to.params.avatar !== undefined
      // ? state.avatar = state.camera.avatars[this.state.app.route.to.params.avatar]
      // : state.avatar = null

      this.state.app.route.to.params.camera
      ? state.avatar = state.camera.avatars[0]
      : state.avatar = null
      
      state.items = hashids.decode(this.state.app.route.to.params.items)
      state.path = hashids.decode(this.state.app.route.to.params.path)
    },
    'CHANGED' (state) {
      state.changed += 1
    },
    'SET_AVATARS' (state, avatars) {
      _.each(state.camera.avatars, (avatar, index) => {
        state.camera.avatars[index]['babylon'] = avatars[index]
      })
      // state.camera_ideal = 70
      state.camera['avatarsLoaded'] = true
    },
    'SET_SCENE_ANIMATION' (state, animation) {
      state.scene['babylon_animation'] = animation
      state.scene['animationLoaded'] = true
    },
    'SET_ANIMATION' (state, animation) {
      state.animation = animation
    },
    'ADD_NARRATION' (state, data) {
        state.narration = data
    },
    'ADD_POINT' (state, data) {
        state.point = data
    },
    'ADJUST_CANVAS' (state, data) {
      state.adjust_canvas = data
    },
    'DRAG_END' (state, data) {
      state.drag_end = data
    },
    'DRAG_BACK' (state, data) {
      state.drag_back = data
    },
    'CURRENT_LOOP' (state, data) {
      state.loop = data
    },
    'BREADCRUMB_BACK' (state) {
      state.breadcrumb_back += 1
    },
    'ANIMATION_MUTED' (state, data) {
      // console.log('animation_muted : '+ data);
      state.animation_muted = data
    },
    'CAMERA_MUTED' (state, data) {
      // console.log('camera_muted : '+ data);
      state.camera_muted = data
    },
    'USE_TRANSITION' (state, data) {
      // console.log('USE_TRANSITION '+ data);
      state.use_transition = data
    },
    'USE_LOADER' (state, bol) {
      state.use_loader = bol
      state.loader_added += 1
    },
    'OTHER_LOADING' (state, bol) {
      state.other_loading = bol
    },
    'PREV_CONTAINER' (state, container) {
      state.prev_container = container
    },
    'IS_ACTIVE' (state, b) {
      state.is_active = b
    },
    'SET_VIEWPORT' (state, viewport) {
      state.viewport = viewport
    },
    'ANIMATION_COMPLETED' (state) {
      state.animation_completed += 1
    },
    'ADD_MESSAGE' (state, message) {
      state.message = message
    },
    'ROTATION_COMPLETED' (state) {
      state.rotation += 1
    },
    'SET_RECORDING' (state, data) {
      state.recording = data
    },
    'SOUND_ENDED' (state, b) {
      state.sound_ended = b
    },
    'SET_FINAL_CAMERA' (state, data) {
      state.final_camera = data
    },
    'SET_MOTION' (state, data) {
      state.motion = data
    },
    'IS_RECORDING' (state, b) {
      state.is_recording = b
    },
    'IDEAL_CAMERA' (state, id) {
      console.log('IDEAL_CAMERA '+ id);
      state.ideal_camera = id
    },
    'UPDATE_IRIS' (state, val) {
      console.log(' == '+ state.iris, val);
      state.iris = val
    },
    'SET_SOUND' (state, sound) {
      state.sound = sound
    },
    'SHOW_AVATAR' (state, b) {
      state.show_avatar = b
    },
    'SET_STEP' (state, val) {
      state.step = val
    },
    'SET_SLIDE' (state, val) {
      state.slide = val
    },
    'SET_VOLUME' (state, volume) {
      state.volume = volume
    },
  },
  getters: {
    narration: state => state.narration,
    animation: state => state.animation,
    point: state => state.point,
    camera: state => state.camera,
    scene: state => state.scene,
    avatar: state => state.avatar,
    items: state => state.items,
    path: state => state.path,
    adjust_canvas: state => state.adjust_canvas,
    drag_end: state => state.drag_end,
    drag_back: state => state.drag_back,
    loop: state => state.loop,
    breadcrumb_back: state => state.breadcrumb_back,
    animation_muted: state => state.animation_muted,
    changed: state => state.changed,
    use_transition: state => state.use_transition,
    other_loading: state => state.other_loading,
    use_loader: state => state.use_loader,
    prev_container: state => state.prev_container,
    is_active: state => state.is_active,
    viewport: state => state.viewport,
    animation_completed: state => state.animation_completed,
    message: state => state.message,
    rotation: state => state.rotation,
    camera_muted: state => state.camera_muted,
    recording: state => state.recording,
    sound_ended: state => state.sound_ended,
    final_camera: state => state.final_camera,
    loader_added: state => state.loader_added,
    motion: state => state.motion,
    is_recording: state => state.is_recording,
    ideal_camera: state => state.ideal_camera,
    iris: state => state.iris,
    sound: state => state.sound,
    show_avatar: state => state.show_avatar,
    step: state => state.step,
    slide: state => state.slide,
    volume: state => state.volume,
  }
}
