<template>
  <div class="Narration">
   <div ref="container" class="Narration__container"> 

      <transition name="Narration__background">
        <div ref="background" class="Narration__background" v-if="use_background"/> 
      </transition>

      <div class="Narration__block comments">  
        
        <div class="Narration__avatar">
          <img :src="require('./img/buddy.png')">
        </div>

        <div ref="bubble" class="Narration__bubble" :class="['is-'+ type, context, { 'hidden': narration_completed }]">
          <div class="Narration__arrow" :class="'is-'+ type"/>
          <h5 ref="sentence" class="Narration__sentence" v-html="sentence"/>
        </div> 

        <transition name="Narration__btn-replay">
          <button class="Narration__btn-replay" v-if="add_replay" @click="replay()" @mouseenter="mouseenter">
            <img :src="require('@/commons/img/icon-replay.png')">
            <h5 class="Narration__txt">{{ trans('replay') }}</h5>
          </button>
        </transition>

      </div>
      <div ref="player" class="Narration__block right" :class="{ 'hidden': narration_completed }">
        <audio ref="audio" :src="file" autoplay/>
        <div class="Narration__sound_control" @click="onClick()">
          
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" v-if="!muted">
            <g id="Group_2492" data-name="Group 2492" transform="translate(-1286 -685)">
              <g id="Ellipse_262" data-name="Ellipse 262" transform="translate(1286 685)" fill="none" stroke="coral" stroke-width="2">
                <circle cx="20" cy="20" r="20" stroke="none"/>
                <circle cx="20" cy="20" r="19" fill="none"/>
              </g>
              <g id="Group_2491" data-name="Group 2491" transform="translate(803.79 850.808)">
                <path id="Path_24923" data-name="Path 24923" d="M509.636-156.678v.011l-11,2.242a.185.185,0,0,0-.148.181v13.869a3.681,3.681,0,0,0-3.192.275c-1.609.837-2.466,2.373-1.916,3.432s2.3,1.239,3.909.4a3.717,3.717,0,0,0,2.218-2.872V-151.4a.163.163,0,0,1,.13-.159l9.807-1.99a.163.163,0,0,1,.195.159v10.23a3.68,3.68,0,0,0-3.192.275c-1.609.836-2.467,2.373-1.916,3.432s2.3,1.239,3.909.4a3.354,3.354,0,0,0,2.073-2.872h.008v-14.7a.185.185,0,0,0-.219-.182Z" fill="coral"/>
              </g>
            </g>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" v-if="muted">
            <g id="Group_2494" data-name="Group 2494" transform="translate(-1233 -685)">
              <g id="Group_2493" data-name="Group 2493" transform="translate(-53)">
                <g id="Ellipse_262" data-name="Ellipse 262" transform="translate(1286 685)" fill="none" stroke="coral" stroke-width="2">
                  <circle cx="20" cy="20" r="20" stroke="none"/>
                  <circle cx="20" cy="20" r="19" fill="none"/>
                </g>
                <g id="Group_2491" data-name="Group 2491" transform="translate(803.79 850.808)" opacity="0.4">
                  <path id="Path_24923" data-name="Path 24923" d="M509.636-156.678v.011l-11,2.242a.185.185,0,0,0-.148.181v13.869a3.681,3.681,0,0,0-3.192.275c-1.609.837-2.466,2.373-1.916,3.432s2.3,1.239,3.909.4a3.717,3.717,0,0,0,2.218-2.872V-151.4a.163.163,0,0,1,.13-.159l9.807-1.99a.163.163,0,0,1,.195.159v10.23a3.68,3.68,0,0,0-3.192.275c-1.609.836-2.467,2.373-1.916,3.432s2.3,1.239,3.909.4a3.354,3.354,0,0,0,2.073-2.872h.008v-14.7a.185.185,0,0,0-.219-.182Z" fill="coral"/>
                </g>
              </g>
              <line id="Line_178" data-name="Line 178" x2="26" y2="26" transform="translate(1238.5 693.5)" fill="none" stroke="coral" stroke-width="2"/>
            </g>
          </svg>




        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Narration } from '@/components/narration/js/data.js'
import Settings from '@/config/Settings'
import {} from 'babylonjs'
import 'babylonjs-loaders'
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

export default {

  name: 'Narration',
  components: {},

  data: () => ({
    playing: false,
    narration_completed: false,
    add_replay: false,
    type: 'narration',
    context: '',
    genre: 'lady',
    txt: '',
    file: '',
    muted: false,
    sentences: null,
    sentence: '',
    locked: false,
    standby: null,
    used: false,
    tab: false,
    use_background: true,
    muted: false
  }),

  translations: {
    fr: { replay: 'Rejouer' },
    en: { replay: 'Replay' }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('keyup', this.onKeyUp)
    window.removeEventListener('keypress', this.onKeyPress)
  },
  created() {
    window.addEventListener('keypress', this.onKeyPress)
    window.addEventListener('keyup', this.onKeyUp)
    window.addEventListener('resize', this.onResize)
  },

  mounted() {
    this.onResize()
    const controls = [ 'rewind', 'play', 'fast-forward', 'progress', 'current-time', 'duration', 'mute', 'volume' ]
    this.$audio = new Plyr(this.$refs.audio, {
      hideControls: false,
      controls: controls
    })
    window.player = this.$audio
  },

  methods: {

    onContinue() {
      this.locked = false
      if(this.standby) TweenMax.delayedCall( 1, ()=>{ this.addNarration(this.standby) })
    },

    onResize() {
      // const prc = Math.round(window.innerHeight*.1)
      // const psy = (prc > 64) ? 64 : prc
      // TweenMax.set(this.$refs.container, { bottom: psy })
    },

    onKeyUp(evt) {
      if(evt.code == 'Tab')
        this.tab = true
      else 
        this.tab = false
    },

    onKeyPress(evt) {
      if(evt.code == 'Space' && this.used && !this.tab){
        if(window.player.paused)
          window.player.play()
        else
          window.player.pause()
      }
    },

    reset(obj) {

      // console.log(obj);

      this.used = false
      this.add_replay = false
      this.narration_completed = true
      
      if(this.$route.params.items && this.$route.params.path == undefined)
        this.context = 'is-matrix'
      else 
        this.context = ''

      this.$refs.audio.pause()
      this.sentence = ''
      if(this.$tween) this.$tween.kill()
      this.$store.commit('user/ADD_NARRATION', null)
      this.file = null

      if(this.$twide)
        this.$twide.kill()

      if(this.$twow)
          this.$twow.kill()


      if(obj)
        this.playing = false

      this.$twide = new TimelineMax()
          .add([
            TweenMax.to('.plyr', .25, { opacity: 0 }),
            TweenMax.to(this.$refs.container, .1, { opacity: 0, onComplete: ()=>{
              this.playing = false
              if(obj)
                this.addNarration(obj)
              // else 
              //   this.playing = false

            } })
          ])
    },

    addNarration(obj) {
      if(this.locked) {
        this.standby = obj
        return
      }

      if(this.playing) {
        this.reset(obj)
        return
      }

      this.sentences = (typeof obj.txt[0] == 'object') ? obj.txt[0] : obj.txt
      this.$nextTick(()=>{

        if(this.$tween)
          this.$tween.kill()

        this.$tween = new TimelineMax({ paused: true })
        _.each(this.sentences, (sentence, i) => {
          this.$tween.call(()=> this.udpdateSentence(i))
          this.$tween.to(this.$refs.sentence, 0, { opacity: 1 }).to({ opacity: 1 }, 0, { delay: 10 })
          this.$tween.call(()=> this.udpdateSentence(i))
          this.$tween.call(()=> this.udpdateSentence(i+1))
          this.$tween.to(this.$refs.sentence, 0, { opacity: 0 })
        })

        if(this.$twow)
          this.$twow.kill()

        this.$twow = new TimelineMax()
            .add([
              TweenMax.to(this.$refs.container, .5, { opacity: 0 }),
              TweenMax.to('.plyr', .5, { opacity: 0 })
            ])

        this.playing = true
        if(obj.sound){
          this.type = (obj.color == 'yellow') ? 'failed' : 'narration'
          this.playSound(obj.sound)

        } else 
          this.type = 'retroaction'

      })
    },

    udpdateSentence(val) {
      if(this.sentences[val])
        this.sentence = this.sentences[val]
    },
    mouseenter() {
      this.$store.commit('user/SET_SOUND', 'tertiaire_hover')
    },
    replay() {
      this.add_replay = false  
      this.$store.commit('user/SET_SOUND', 'tertiaire_click')
      TweenMax.delayedCall(.35, ()=> { this.playSound(this.file) })  
    },

    playSound(sound) {

      // this.file = require('./audio/'+ sound +'.mp3')

      this.file = sound
      this.narration_completed = false
      this.used = true
      this.$store.commit('user/SOUND_ENDED', false)

      TweenMax.delayedCall(.1, ()=>{

        TweenMax.to(this.$refs.container, .5, { opacity: 1, delay: .25 })
        TweenMax.to('.plyr', .5, { opacity: 1, delay: .25 })

        this.$tween.duration(Number(this.$refs.audio.duration))
        this.$refs.audio.play()

        this.$refs.audio.onended = ()=> {  
          this.narration_completed = true  
          this.used = false
          this.add_replay = true
          if(this.$route.params.process != undefined && this.$route.params.single == undefined)
             this.$store.commit('user/SET_SOUND', 'completion_feedback')
          this.$store.commit('user/SOUND_ENDED', true)
        }

        this.$refs.audio.ontimeupdate = ()=> {
          if(!this.$refs.audio) return
          const perc = this.$refs.audio.currentTime / this.$refs.audio.duration
          if(perc){
            this.$tween.progress(perc)
          }
        }

        this.setVolume()

        // this.$refs.audio.addEventListener('volumechange', ()=> {
        //   this.$store.commit('user/SET_VOLUME', this.$refs.audio.volume)
        // })

        // this.$refs.audio.muted = this.muted
        // this.$store.commit('user/SET_VOLUME', this.$refs.audio.volume)

      })
    },

    setVolume() {
       if(this.muted)
        this.$store.commit('user/SET_VOLUME', 0)
      else
        this.$store.commit('user/SET_VOLUME', 1)
    },

    onClick() {
      this.muted = !this.muted
      this.setVolume()
    },

    getColor() {
      return (this.type == 'narration') ? 'cls-4' : 'cls-1'
    },

  },

  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{ this.reset() })
      }
    },

    narration(nw, od) {

      switch(nw){

        case 'close_it':
          this.reset()
          break

        case 'open_video':
          this.$refs.audio.pause()
          break

        case 'close_video':
          break

        default:
          this.addNarration(nw)
      }

      // if(nw === 'close_it') this.reset()
      // else if(nw) this.addNarration(nw)
      
    },

    narration_completed(nw) {
      this.use_background = !nw
      const opacity = nw ? 0 : 1
      document.getElementsByClassName('plyr__controls')[0].style.opacity = opacity

      // document.getElementsByClassName('plyr__controls__item plyr__control')[0].style.opacity = opacity
      // document.getElementsByClassName('plyr__controls__item plyr__control')[1].style.opacity = opacity
      // document.getElementsByClassName('plyr__controls__item plyr__control')[2].style.opacity = opacity
      // document.getElementsByClassName('plyr__controls__item plyr__progress__container')[0].style.opacity = opacity
      // document.getElementsByClassName('plyr__controls__item plyr__time--duration plyr__time')[0].style.opacity = opacity
      // document.getElementsByClassName('plyr__controls__item plyr__time--current plyr__time')[0].style.opacity = opacity
    }
  },

  computed: {
    narration() { return this.$store.getters['user/narration'] },
    changed() { return this.$store.getters['user/changed'] },
    route() { return this.$store.getters['app/route'] }
  }
}

</script>

<style lang="stylus">

@import '~@/config/Settings'

.Narration
  position relative
  width 100%
  height 100px
  position fixed
  bottom 0
 
  &__container
    width 100%
    height 100%
    position relative
    display flex
    flex-direction row

  &__background
    width 100%
    height 100%
    background-image url('./img/background.png')
    background-size cover
    background-position repeat repeat
    position absolute
    z-index -1
    transition opacity .25s

    &-enter 
    &-leave-active
      opacity 0

  &__block 
    width 50%
    height 100%
    display flex 
    justify-content center
    transition opacity .5s
    transition-delay .1s

    &.comments 
      justify-content flex-start
      align-items center
      padding 0 32px

    &.right
      justify-content space-between
      align-items center
      padding-right 32px

  &__sentence
    position absolute
    opacity 1
    z-index 1

  & h5
    font-family montserrat
    font-size max(.8vw, 12px)
    line-height max(1.05vw, 15px)
    letter-spacing .2px
    font-weight bold
    text-transform none
    top 0
    left 0
    padding 10px 12px

  &__avatar
    height 62px

  &__bubble
    position relative
    padding 0px 30px
    color blue-dark 
    font-size .85vw
    line-height 1.3vw
    border-radius 8px
    letter-spacing .05vw
    padding 8px 12px
    margin-left 32px
    height 64px
    width calc(100% - 94px)
    transition opacity .5s
    transition-delay .1s
    display flex
    justify-content flex-start
    align-items flex-start

    &.is-narration
      background pink

    &.is-retroaction
      background pink

    &.is-failed
      background yellow

    &.hidden 
      opacity 0

  &__arrow
    width 0
    height 0
    border-top 10px solid transparent
    border-bottom 10px solid transparent
    position absolute
    left -17px
    top 22px
   
    &.is-narration
      border-right 18px solid pink

    &.is-retroaction
      border-right 18px solid pink

    &.is-failed
      border-right 18px solid yellow

  &__btn-replay 
    position absolute
    padding 0 72px
    display flex
    align-items center
    color white
    cursor pointer
    transition opacity .25s, transform .25s

    &-enter 
      opacity 0
      transform translateX(20px)
      transition-delay .35s

    &-leave-active
      opacity 0
      transform translateX(20px)
      transition-delay 0s

    &:hover
      .Narration__txt
        transform translateX(10px)
  
    & img 
      padding 0 8px 0 0 

  &__txt
    transition opacity .25s, transform .25s

  .cls-1
    fill #f76106

  .cls-2
    fill #281d55

  .cls-3
    fill none
    stroke #271f55
    stroke-miterlimit 10
    stroke-width 2px

  .cls-4
    fill pink

  &__sound_control
    width 40px
    height 40px
    cursor pointer


</style>


