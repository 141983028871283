<template>
  <div class="Item" :class="idx" :aria-label="obj[lang].title">

    <button 
      :aria-label="obj[lang].title"
      ref="button" 
      :id="obj.cover.id" 
      class="draggable" 
      @keyup.enter="onClick(obj.cover.id)">

      <div class="Item__circle" :class="[{ 'enable': getActived()}, 'circle_'+ idx]">
        <img :src="obj.cover.url">
      </div>
      <!-- <img :src="obj.cover.url"> -->
    </button>


    <div class="Item__title">
      <h6 class="Item__title" v-html="obj[lang].title" 
         :class="[{ 'enable': !is_active }, { 'error': exception }]"/>
      </div>

    <!-- <h6 class="Item__title" v-html="obj[lang].title" :class="{ 'enable': !is_active }"/>  -->

    <!-- <transition @enter="enter" @leave="leave" mode="out-in"> -->
      <div class="Item__selected" v-if="selected && !is_active">
        <img :src="require('./images/selected.png')">
      </div>
    <!-- </transition> -->
    <!-- <transition @enter="enter" @leave="leave" mode="out-in"> -->
      <div class="Item__selected" v-if="exception && !is_active">
        <img :src="require('./images/important.png')">
      </div>
    <!-- </transition> -->
  </div>
</template>

<script>

export default {

  name: 'Item',
  props: ['obj', 'type', 'idx'],
  data: () => ({
    selected: false,
    exception: false
  }),

  methods: {

    // enter(el, done) {
    //   done()
    // },
    // leave(el, done) {
    //   done()
    // },

    getActived() {
      return (this.is_active && !this.selected) ? false : true
    },

    onClick(id) {
      if(this.obj.is_valid){
        if(!this.selected) this.$parent.simulateDrag(this.$refs.button)
        else this.$parent.simulateBackDrag(this.$refs.button)
      } 
      else {
        if(!this.exception) this.$parent.simulateDrag(this.$refs.button)
        else this.$parent.simulateBackDrag(this.$refs.button)
      }
    },

    dragCompleted(id) {

      if(this.obj.is_valid) {

        this.selected = !this.selected

        if(this.selected) {
          this.$parent.addTechnical(id, this.obj)
          const data = {
            sound: this.obj[this.lang].audio,
            txt: [ this.obj[this.lang].retroaction ],
            color: 'orange'
          }
          this.$store.commit('user/SET_SOUND', 'bon_feedback')
          TweenMax.delayedCall(.25, ()=>this.$store.commit('user/ADD_NARRATION', data))
          

        } else {
          this.$parent.removeTechnical(id, this.obj)
          this.$store.commit('user/ADD_NARRATION', 'close_it')
        }
      }

      else {

        this.exception = !this.exception

        if(this.exception) {
          const data = {
            sound: this.obj[this.lang].audio,
            txt: [ this.obj[this.lang].retroaction ],
            color: 'yellow'
          }
          this.$store.commit('user/SET_SOUND', 'mauvais_feedback')
          TweenMax.delayedCall(.25, ()=>this.$store.commit('user/ADD_NARRATION', data))
        }
        else
          this.$store.commit('user/ADD_NARRATION', 'close_it')
      }
    },
  },

  watch: {
    drag_end(nw, od) {
      if(this.obj.cover.id == Number(nw.target) && !this.selected && this.obj.is_valid
      || this.obj.cover.id == Number(nw.target) && !this.exception && !this.obj.is_valid)
        this.dragCompleted(nw.target)
    },
    drag_back(nw, od) {
      if(this.obj.cover.id == Number(nw.target) && this.selected && this.obj.is_valid
      || this.obj.cover.id == Number(nw.target) && this.exception  && !this.obj.is_valid)
        this.dragCompleted(nw.target)
    },
    // is_active(nw, od) {
    //   console.log(nw);
    // }
  },

  computed: {
    drag_end() { return this.$store.getters['user/drag_end'] },
    drag_back() { return this.$store.getters['user/drag_back'] },
    is_active() { return this.$store.getters['user/is_active'] }
  },

}

</script>

<style lang="stylus">

@import '~@/config/Settings'

.Item
  display block
  width 50%
  cursor pointer
  position relative
  margin 16px 0
  display flex 
  justify-content center
  align-items center
  flex-direction column
  cursor initial

  & button 
    cursor grab !important

  &__title
    text-align center
    display flex
    align-items center
    justify-content center
    align-items flex-start
    padding-top 4px
    width 80%
    height 20px

    &.enable
      opacity 1

    &.error
      color yellow

  &__circle
    // background #a5a5a5
    width 108px
    height 108px
    border-radius 50%
    opacity 0

    &.enable
      opacity .9

  &__img
    width 100%
    max-width 120px
    height 100%
    max-height 120px

  & img
      width 100%
      height 100%

  &__selected
    position absolute
    width 38px
    height 38px
    top 50%
    left 50%
    transform translate(-50%, -50%)

  .draggable
    padding-top 16px

</style>




























































