<template>
  <div class="Scene">
    <template v-if="ready">
      <Cameraman ref="cameraman"/>
      <Animation ref="animation" v-if="this.$route.params.items && this.$route.params.process == undefined || this.$route.params.single != undefined"/>
    </template>
  </div>
</template>

<script>

import Cameraman from './Cameraman'
import Animation from './Animation'

import Settings from '@/config/Settings'
import Mixin from '@/core/mixins/Matrix'
import { Scenes } from '@/components/scene/js/data.js'
import { Mesh, Vector3 } from 'babylonjs'
import 'babylonjs-loaders'
import Hashids from 'hashids'

export default {

  name: 'Scene',
  mixins: [ Mixin ],
  components: { Cameraman, Animation },

  beforeDestroy() {
    if(this.$tween) this.$tween.kill()
    this.removeOverlay()
    _.each(this.$meshes, m => m.dispose())
  },

  data: () => ({
    ready: false,
    cameraman_is_visible: true,
    locked: false,
    is_back: false
  }),

  created() {

    // console.log('SCENE !!!');

    this.$is_static = this.camera.id == 86 || this.camera.id == 52 ? true : false
    this.$scale = 17/(window.innerHeight/1080)

    // console.log(this.$scale);

    this.$meshes = []
    this.$scene = this.$parent.$babylon.scene

    this.$container = this.createMesh('', this.$scene)
    // this.$animation = this.createMesh('', this.$scene)
    this.$camera_target = this.createMesh('camera_target', this.$scene)
    this.$cameraman = this.createMesh('cameraman', this.$scene)
    this.$cameraman.position.z = 100

    this.$point = this.createMesh('point', this.$scene)

    // this.box = BABYLON.Mesh.CreateBox("box", .2, this.$scene)
    // this.box.scaling.y = 10
    // this.box.parent = this.$point

    const scene = this.userScene['babylon'].instantiateModelsToScene()
          scene.rootNodes[0].parent = this.$container

    this.$container.position.z = 14
    this.$container.rotation.y = 180 * (Math.PI/180)
    this.$container.position.x = 0

    this.$camera = this.$parent.$babylon.camera

    // this.$camera = this.$scene.getCameraByName('camera_orthographic')

    // this.$camera.position.x = 0
    // this.$camera.position.y = 18
    // this.$camera.position.z = 0

    // this.$camera.setTarget(new Vector3.Zero())

    this.$camera.setTarget(new Vector3(0, 2, 14))
    this.ready = true

    this.$cameraman.lookAt(new Vector3(0, 0, 0))

    // this.$cameraman.lookAt(this.$camera_target.position)
    // this.$scene.registerAfterRender(() =>{
    //   this.onLoop()
    // })
    // this.ready = true
  },

  mounted() {

    if(this.prev_container)
      this.addMotion()
    else {
      this.addCameraCompleted()
      this.resetContainer()
    }
  },

  methods: {

    addMotion() {

      let obj = { x:0, y:2, z: 14 }
      const tempo = 2.5
      this.$tween = new TimelineMax({ delay: 1.5 })
          // .set(this.prev_container, { babylon: { x: '-=20'} })
          .to(this.$container, 1.25, { babylon: { x: '-=20'}, ease: Cubic.easeOut })
          .set({}, { delay: 1 })
          .add([
            TweenMax.to(obj, tempo,{ x:0, y:0, z:0 }),
            TweenMax.to(this.$camera, tempo, { 
              babylon: { x: 0, y: this.$scale, z: 0 }, 
              ease: Linear.easeNone, 
              onUpdate: ()=>{
                this.$camera.setTarget(new Vector3(obj.x, obj.y, obj.z))
              },
            }),
            TweenMax.to(this.$container, tempo, { 
              babylon: { x: 0, y: 0, z: 0 }, 
              ease: Cubic.easeOut, 
              onComplete: ()=>{
                this.$container.rotation.y = 0 * (Math.PI/180)
                this.rotationCompleted()
              },
            })
          ]) 
    },

    onLoop() {
      // if(this.locked) return
      console.log('onLoop');
      this.$cameraman.lookAt(this.$camera_target.position)
    },
    createMesh(name, target) {
      const mesh = new Mesh(name, target)
            mesh.position.x = 0
            mesh.position.z = 0
            mesh.position.y = 0
      this.$meshes.push(mesh)
      return mesh
    },
    showCameraman() {
      _.each(this.$cameraman.getChildMeshes(), c=> {
        c.isVisible = true
      })
    },
    hideCameraman() {
      // this.$cameraman.position.z = 100
      _.each(this.$cameraman.getChildMeshes(), c=> {
        c.isVisible = false
      })
    },
    playAnimation() {


      console.log(this.camera);

      if(this.camera.id != 88)
        this.$refs.animation.play()
      else 
        TweenMax.delayedCall(3, ()=> this.$refs.animation.play())

      console.log('playAnimation !');

      this.$store.commit('user/IS_RECORDING', true)
      // this.$refs.cameraman.startRecording()
    },
    addOverlay() {

      this.$overlay = this.createMesh('', this.$scene)
      this.$overlay.parent = this.$container
      const mesh = this.userScene['babylon_overlay'].instantiateModelsToScene()
            mesh.rootNodes[0].parent = this.$overlay

      switch(Settings.scene_translations[this.lang][this.$route.params.scene]){
        case 'amoureux': 
          TweenMax.set(mesh.rootNodes[0], { babylon: { z: 1.15 }})
          break

        case 'jeu': 
          TweenMax.set(mesh.rootNodes[0], { babylon: { z: -.4, x:0 }})
          break

        case 'parade': 
          TweenMax.set(mesh.rootNodes[0], { babylon: { x: 0.125 }})
          break

        case 'manifestation': 
          TweenMax.set(mesh.rootNodes[0], { babylon: { z: 1.125 }})
          break
      }

      _.each(mesh.rootNodes[0].getChildMeshes(), c => {
        if(c.material) TweenMax.to(c, 1, { babylon: { alpha: .3 }, yoyo: true, repeat: -1 })
      })
    },
    removeOverlay() {
      if(this.$overlay) {
        this.$overlay.dispose()
        this.$overlay = null
      }
    },
    rotationCompleted() {
      this.$store.commit('user/ROTATION_COMPLETED')
      this.addOverlay()
      this.addNarration(1)
    },
    addCameraCompleted() {
      this.$scene.activeCamera = this.$camera
      TweenMax.set(this.$camera, { babylon: { x: 0, y: this.$scale, z: 0 }})
      this.$camera.setTarget(new Vector3(0, 0, 0))
      this.addOverlay()
      const delay = (this.changed == 0) ? 3 : 1
      this.addNarration(delay)
    },
    resetContainer() {
      this.$container.rotation.y = 0 * (Math.PI/180)
      this.$container.position.x = 0
      this.$container.position.z = 0
    },
    addNarration(delay) {

      // console.log(this.$route.params.items, this.$route.params.path == undefined);

      if(this.$route.params.items && this.$route.params.path == undefined) {
        const mp3 = this.$is_static ? 'trajet_camera_simple' : 'trajet_camera_multiple'
        this.$audio = this.getAudio(mp3)
        this.$tween_audio = TweenMax.delayedCall(delay, ()=>{
          this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
        })
      } 
    },

    setCameraPosition() {  
      const coef = 17/(window.innerHeight/1080)
      this.$scale = (coef > 18) ? coef : 19
      console.log('setCameraPosition '+ this.$scale);
      console.log(this.$scale);
      TweenMax.set(this.$camera, { babylon: { x: 0, y: this.$scale, z: 0 }})
    },

  },

  computed: {
    userScene() { return this.$store.getters['user/scene'] },
    prev_container() { return this.$store.getters['user/prev_container'] },
    breadcrumb_back() { return this.$store.getters['user/breadcrumb_back'] },
    use_transition() { return this.$store.getters['user/use_transition'] },
    changed() { return this.$store.getters['user/changed'] },
    camera() { return this.$store.getters['user/camera'] },
  },

  watch: {

    // '$root.width': 'setCameraPosition',
    // '$root.height': 'setCameraPosition',

    'breadcrumb_back'(nw, od) {
      this.is_back = true
    },

    'cameraman_is_visible' (nw){
      if(nw === true) this.showCameraman()
      else if(nw === false) this.hideCameraman()
    },

    '$route': {
      immediate: true,
      handler() {

        this.$nextTick(()=>{

          // console.log(this.$route.params.camera, this.$route.params.items);
          // console.log('xxxxx');

          this.$store.commit('user/IS_RECORDING', false)

          if(this.$route.params.camera && this.$route.params.items == undefined) {
            this.hideCameraman()
          }
          if(this.$route.params.items && this.$route.params.path == undefined) {

            if(this.use_transition == undefined) {
              this.cameraman_is_visible = false
              this.addOverlay()
              this.is_back = false
              return
            }

            if(this.is_back == true) 
              this.addCameraCompleted()
            this.cameraman_is_visible = false
  
            // this.$container.position.x = (this.is_back || this.changed == 0) ? 0 : 20
     
            if(this.is_back || this.changed == 0) this.$container.position.x = 0 
            else TweenMax.delayedCall(.2, ()=>{ this.$container.position.x = 20 })

            // this.addOverlay()

          }
          else {
            this.cameraman_is_visible = true
            this.removeOverlay()
          }

          this.is_back = false

        })

        

      }
    },
  },

}
</script>




















