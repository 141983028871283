import Settings from '@/config/Settings'
export default {
  created() {
    this.$path = this.decodeHashids(this.path)
  },
  methods: {
    decodeHashids(arr) {
      const path = []
      _.each(arr, (val, i)=>{
        let row = Math.floor(i/2)
        if(i%2 == 0) {
          path[row] = []
          path[row][0] = val
        } else {
         path[row][1] = val 
        }
      })
      return path
    },
  },
  computed: {
    path() { return this.$store.getters['user/path'] },
  },
}





