<script>

import Settings from '@/config/Settings'
import Mixin from '@/core/mixins/Matrix'
// import StaticView from './StaticView'
// import CameraWitness from '@/components/camera-babylon/CameraWitness'

import { Path3D, FreeCamera, Vector3 } from 'babylonjs'
import 'babylonjs-loaders'

export default {

  name: 'Visualisation',
  render: h => h(),
  // components: { CameraWitness, StaticView },
  mixins: [ Mixin ],
  translations: {
    en: { continue: 'Continue' },
    fr: { continue: 'Continue' },
  },
  
  data: () => ({
    obj: null,
    is_lock: false,
    current_walk: 0,
    locked: false,
    is_record: false,
    is_live: false,
    is_initialized: false,
    angle: 2.4,
    od: null,
    current: 'idle',
    current_dir: null,
    count: 0,
    dpt: 0,
    // pt: { x: 0, y:0, z: 0 },
  }),

  beforeDestroy() {

    // console.log('DynamicView destroy !!!');
    // console.log('xxxxxxxxxxx == ');

    if(this.current != 'idle')
      this.$store.commit('user/SET_MOTION', this.current +'_idle')

    document.removeEventListener('keypress', this.onKeyPress)
    this.killTweens()
    // if(this.$tween) this.$tween.kill()
    // if(this.$delay) this.$delay.kill()
    // if(this.$tween_point) this.$tween_point.kill()
    this.resetCameramanPosition()
  },

  created() {

    // console.log('DynamicView');

    this.$tween = new TimelineMax({}).stop()

    // this.$scene = this.$parent.$babylon.scene

    this.$scene = this.$parent.$scene
    this.$cameraman = this.$scene.getMeshByName('cameraman')
    this.$buddy = this.$scene.getMeshByName('buddy')
    // this.$point = this.$scene.getMeshByName('point')
    this.$camera = this.$scene.getCameraByName('tracking') 
    this.$tracking = this.$scene.getMeshByName('cameraman_tracking')
    this.$target = this.$scene.getMeshByName('cameraman_target')

    this.$path = this.decodeHashids(this.path)
    this.$line = this.getCurveLine(this.path)

    // this.$walk = [

    //   [6.76923076923077, 4.769230769230768],
    //   [6.461538461538463, 4.769230769230768],
    //   [6.153846153846155, 4.769230769230768],
    //   [5.846153846153847, 4.769230769230768],
    //   [5.538461538461539, 4.769230769230768],
    //   [5.230769230769232, 4.769230769230768],
    //   [4.923076923076923, 4.769230769230768],
    //   [4.615384615384616, 4.769230769230768],
    //   [4.307692307692308, 4.769230769230768],
    //   [4.000000000000001, 4.769230769230768],
    //   [3.6923076923076925, 4.769230769230768],
    //   [3.384615384615385, 4.769230769230768],
    //   [3.0769230769230775, 4.769230769230768],
    //   [2.76923076923077, 4.769230769230768],
    //   [2.4615384615384617, 4.769230769230768],
    //   [2.153846153846154, 4.46153846153846],
    //   [1.8461538461538467, 4.153846153846153],
    //   [1.5384615384615392, 3.846153846153845],
    //   [1.2307692307692308, 3.5384615384615383],
    //   [0.9230769230769234, 3.846153846153845],
    //   [0.6153846153846168, 3.5384615384615383],
    //   [0.3076923076923084, 3.5384615384615383],
    //   [1.7763568394002505e-15, 3.5384615384615383],
    //   [-0.3076923076923066, 3.5384615384615383],
    //   [-0.9230769230769216, 3.5384615384615383],
    //   [-0.6153846153846132, 3.5384615384615383],
    //   [-1.23076923076923, 3.5384615384615383],
    //   [-1.5384615384615365, 3.5384615384615383],
    //   [-1.846153846153845, 3.5384615384615383],
    //   [-2.1538461538461533, 3.5384615384615383],
    //   [-2.46153846153846, 3.5384615384615383],
    //   [-2.7692307692307683, 3.5384615384615383],
    //   [-3.3846153846153832, 3.5384615384615383],
    //   [-3.076923076923075, 3.5384615384615383],
    //   [-4.307692307692307, 3.23076923076923],
    //   [-4.615384615384613, 2.3076923076923075],
    //   [-4.923076923076922, 2],
    //   [-4.615384615384613, 1.6923076923076925],
    //   [-4.615384615384613, 1.384615384615385],
    //   [-4.615384615384613, 1.0769230769230766],
    //   [-4.615384615384613, 0.7692307692307692],
    //   [-4.615384615384613, 0.4615384615384617],
    //   [-4.615384615384613, 0.1538461538461542],
    //   [-4.615384615384613, -0.1538461538461542],
    //   [-4.615384615384613, -0.4615384615384617],
    //   [-4.615384615384613, -0.7692307692307692],
    //   [-4.615384615384613, -1.076923076923077],
    //   [-4.615384615384613, -1.384615384615385],
    //   [-4.615384615384613, -1.6923076923076925],
    //   [-4.615384615384613, -2.0000000000000004],
    //   [-4.615384615384613, -2.307692307692308],
    //   [-4.615384615384613, -2.615384615384616]
    // ]

    this.$first = this.getPos(this.$path[0])

    const dst = this.getDistance(0,0,this.$first[0], this.$first[1])
    const pt = this.getNextPoint(0,0,this.$first[0], this.$first[1], dst+20) 

    this.$cue1 = this.getNextPoint(0,0,this.$first[0], this.$first[1], dst+19.5)
    this.$cue2 = this.getNextPoint(0,0,this.$first[0], this.$first[1], dst+.5)
    this.$cue3 = this.getNextPoint(0,0,this.$first[0], this.$first[1], dst+6)
    this.$cue4 = this.getNextPoint(0,0,this.$first[0], this.$first[1], dst+6.05)
    this.$cue5 = this.getNextPoint(0,0,this.$first[0], this.$first[1], dst+15)
    this.$cue = this.getNextPoint(0,0,this.$first[0], this.$first[1], dst+4)
    this.$dpt = this.camera.id != 88 
              ? {  x: this.$first[0], y: 0, z: this.$first[1], }
              : {  x: pt[0], y: 0, z: pt[1], }

    // this.$point.position = new Vector3(this.$dpt.x, 0, this.$dpt.z)
    this.$lookAt = new Vector3(0, 0, 0)
  },

  mounted() {
    document.addEventListener('keypress', this.onKeyPress)  
  },

  methods: {

    // degToCompass(num){
    //   const val =  Math.floor((num / 45) + 0.5)
    //   const arr = ["N","NE","E", "SE","S","SW","W","NW"]
    //   return arr[(val % 8)]
    // },

    killTweens() {
      // if(this.$tween) this.$tween.kill()
      if(this.$pan) this.$pan.kill()
      if(this.$delay) this.$delay.kill()
      // if(this.$tween_point) this.$tween_point.kill()
    },
    updateDir() {
      // console.log(this.current_dir);
      // console.log(this.$cameraman.rotation.y, this.angle);
      // console.log('xxxxxxx');
    },

    onStart() {
      // console.log(this.pos[this.pos.length-1]);
      this.dpt = this.$cameraman.rotation.y > 0 ? this.$cameraman.rotation.y : 5

      // if(this.pos) {
      //   if(Settings.scene_translations[this.lang][this.$route.params.scene] == 'parade' 
      //   && this.camera.id != 88
      //   && this.pos[this.pos.length-1]['z'] > 1.5) 
      //     TweenMax.to(this.$tracking.position, 7, { 
      //       x: 0,
      //       y: 2,
      //       z: -4,
      //       onUpdate: ()=>{ this.$tracking.lookAt(this.$target.position)},
      //       ease: Linear.easeNone,
      //       delay: 1
      //     })
      // }
    },

    onUpdate() {


      if(Settings.scene_translations[this.lang][this.$route.params.scene] == 'parade' && this.camera.id != 88) {
        this.$tracking.position.y = this.getY(this.$cameraman.position.z)
        this.$tracking.lookAt(this.$target.position)
      }


      // console.log(this.$tracking.position.z);

      this.cameramanLookAt() 
      this.count += 1

      if(this.count <50 && this.current != 'idle') {
        return
      }
      this.count = 0
      
      const p2 = this.od
      const p1 = { x: this.$cameraman.position.x, y: this.$cameraman.position.z }

      if(this.od) {

        const lx = (p1.x - p2.x)
        const ly = (p1.y - p2.y)
        const lt = Math.abs(lx) > Math.abs(ly) ? 'lx' : 'ly'

        let dir = 'idle'

        if(lt == 'lx' && lx < 0) dir = 'right'
        else if(lt == 'lx' && lx > 0) dir = 'left'
        else if(lt == 'ly' && ly < 0) dir = 'front'
        else if(lt == 'ly' && ly > 0) dir = 'back'

        this.current_dir = dir

        if(this.current == 'idle' && dir != 'idle') {
          this.$store.commit('user/SET_MOTION', this.current +'_'+ dir)
          this.current = dir
          return
        }

        let nw = null

        switch(dir){

          case 'front':
            if(this.$cameraman.rotation.y < 3.3 && this.$cameraman.rotation.y > 2.76 && this.current != 'front') 
              nw = 'front'
            else if(this.$cameraman.rotation.y > 3.3 && this.current != 'left') 
              nw = 'left'
            else if(this.$cameraman.rotation.y < 2.76 && this.current != 'right') 
              nw = 'right'
            break

          case 'back':
            if(this.$cameraman.rotation.y < 3.3 && this.$cameraman.rotation.y > 2.76 && this.current != 'back')
              nw = 'back' 
            else if(this.$cameraman.rotation.y > 3.3 && this.current != 'right') 
              nw = 'right' 
            else if(this.$cameraman.rotation.y < 2.76 && this.current != 'left') 
              nw = 'left' 
            break

          case 'left':
            if(this.$cameraman.rotation.y > 3.8 && this.current != 'back')
              nw = 'back' 
            else if(this.$cameraman.rotation.y < 2.3 && this.current != 'front')
              nw = 'front'  
            else if(this.current != 'left')
              nw = 'left' 
            break

          case 'right':
            if(this.$cameraman.rotation.y < 2.3 && this.current != 'back') 
              nw = 'back'  
            else if(this.$cameraman.rotation.y > 3.8 && this.current != 'front')   
              nw = 'front'  
            else if(this.current != 'right')
              nw = 'right' 
            break
        }

        // console.log(dir, nw);

        const tm = {
          86: { name: "CameraCinematographe", regular: .9, transition: .35 },
          52: { name: "CameraBellHowell", regular: .9, transition: .35 }, 
          56: { name: "CameraBolex", regular: .9, transition: .35 }, 
          96: { name: "CameraPortapak", regular: .9, transition: .35 }, 
          94: { name: "CameraAaton", regular: .9, transition: .35 },
          88: { name: "CameraGoPro", regular: .8, transition: .35 }

        } 

        if(nw != this.current && nw != undefined) {
          this.$tween.timeScale(tm[this.camera.id] ? tm[this.camera.id].regular : .35)
          this.$store.commit('user/SET_MOTION', this.current +'_'+ nw)
          this.current = nw
        } 
        else 
          this.$tween.timeScale(tm[this.camera.id] ? tm[this.camera.id].regular : .9)
      }
      this.od = p1
    },

    reset() {

      // console.log('RESET');

      this.od = null
      if(this.current != 'idle')
        this.$store.commit('user/SET_MOTION', this.current +'_idle')
      this.current = 'idle'
      this.current_dir = null
      this.is_record = (this.$route.params.record) ? true : false
      this.InitializeTween() 
      this.$scene.activeCamera = this.$camera 
      this.setCameramanPosition()
    },
    setCameramanPosition() {

      this.$scene.activeCamera = this.$camera 

      // this.$target.setAbsolutePosition(new Vector3(0, 1, 0))
      // this.$tracking.setAbsolutePosition(new Vector3(_.random(-3,3), _.random(3,5), _.random(3,10))) 
      // this.$tracking.lookAt(this.$target.position) 

      this.$target.position = new Vector3(0, 1, 0)
      this.$tracking.position = new Vector3(2, 2.75, 2)
      this.$tracking.lookAt(this.$target.position) 

      TweenMax.set(this.$cameraman.position, { 
        x: this.$dpt.x, 
        y: this.$dpt.y, 
        z: this.$dpt.z, 
        onComplete:()=>{ 
          this.panBeforeRecording() 
        },
      })
    },
    cameramanLookAt() {
      this.$cameraman.lookAt(this.$lookAt)  
    },
    panBeforeRecording() {
      this.cameramanLookAt()


      // console.log(this.getY(this.$cameraman.position.z));


      let pdt = { x: 0, y: 2, z: -4 }
      if(Settings.scene_translations[this.lang][this.$route.params.scene] == 'parade' && this.camera.id != 88) 
        pdt = {  x: 0, y: this.getY(this.$cameraman.position.z), z: -3 }

      TweenMax.delayedCall(.1, ()=>{
        TweenMax.to(this.$tracking.position, 2.5, { ...pdt,
          onStart: ()=>{ this.count = 0 },
          onUpdate: ()=>{ this.$tracking.lookAt(this.$target.position)  },
          onComplete: ()=>{ this.$parent.camera_is_ready = true },
          delay: 2
        })
      })
    },

    getY(pz) {
      const range = 2.75
      const dpz = 1
      const max = 4.5
      const perc = (100 - ((pz/max)*100))/100
      const res = dpz + (range*perc)
      return res
    },

    onClick() {
      TweenMax.to(this.$refs.button, .20, { opacity: 0, ease: Linear.easeNone })
      this.$router.push({ params: { process: 'process' }})
    },

    onKeyPress(event) {
      switch(event.keyCode) {
        case 99:
          this.updateLookAt() // C
          break
        case 115:
          this.panCamera() // S
          break
      }
    },

    decodeHashids(arr) {
      const path = []
      _.each(arr, (val, i)=>{
        let row = Math.floor(i/2)
        if(i%2 == 0) {
          path[row] = []
          path[row][0] = val
        } else {
         path[row][1] = val 
        }
      })
      return path
    },

    InitializeTween() {

      // console.log('InitializeTween !!!');

      let path3d = new Path3D(this.$line.getPoints())
      let dist = _.sum(path3d.getDistances())
      let speed = (dist/100)*1

      // console.log(path3d.path);
      // console.log(dist);
      // console.log(speed);

      // let arr = ''

      this.pos = []
      _.each(path3d.path, (path)=>{
        let p = path
        let obj = { x: p.x, y: 0, z: p.z }
        this.pos.push(obj)

        // arr += '['+ p.x +','+ p.z +'],'

      })

      // console.log(this.pos);
      // console.log(arr)
      // console.log('xxxxxxxxxxx -- ');

      this.$tween.clear()
      this.$tween.set(this.$cameraman.position, { x: this.pos[0].x, y: this.pos[0].y, z: this.pos[0].z, onStart: ()=>{

        this.cameramanLookAt()

      } })
        .to(this.$cameraman.position, speed, { 
          bezier: { values: this.pos }, 
          ease: Linear.easeNone, 
          onStart: ()=> this.onStart(),
          onUpdate: ()=> this.onUpdate(),
          onComplete: ()=> this.tweenCompleted(),
          delay: 0.25 
        })
        .stop()
      this.cameramanLookAt()
    },

    // InitializeWalk() {
    //   this.$cameraman.position = new Vector3(this.$walk[this.current_walk][0], 0,this.$walk[this.current_walk][1]) 
    //   this.obj = new Vector3(this.$walk[this.current_walk+1][0], 0,this.$walk[this.current_walk+1][1])
    //   this.panBeforeRecording()
    //   // console.log(this.$walk);
    // },

    // addWalk() {

    //   this.current_walk += 1

    //   const pos = new Vector3(this.$walk[this.current_walk][0], 0,this.$walk[this.current_walk][1])
    //   const nxt = new Vector3(this.$walk[this.current_walk+5][0], 0,this.$walk[this.current_walk+5][1])

    //   if(!this.is_lock) {

    //     this.$tween_obj = TweenMax.to(this.obj, .5, { 
    //       x: nxt.x,
    //       y: 0,
    //       z: nxt.z,
    //       ease: Linear.easeNone ,
    //       onStart: ()=> this.is_lock = true,
    //       onUpdate: ()=>{ this.$cameraman.lookAt(this.obj) },
    //       onComplete: ()=> this.is_lock = false
    //     })
    //   }

    //   this.$tween_walk = TweenMax.to(this.$cameraman, .5, { 
    //     babylon: { ... pos }, 
    //     ease: Linear.easeNone ,
    //     onStart: ()=>{
    //       if(this.current != 'front') {
    //         this.$store.commit('user/SET_MOTION', 'idle_front')
    //         this.current = 'front'
    //       }
    //     },
    //     onUpdate: ()=>{
    //       // this.$cameraman.lookAt(nxt)
    //     },
    //     onComplete: ()=>{
    //       this.addWalk()
    //     }
    //   })


    // },

    panCamera() {
      this.locked = true
      if(!this.is_record) this.startPlaying()
      else this.pauseTwen(0)
    },

    startRecording() {
      // this.playTween(0)
    },

    startPlaying() {

      console.log('startPlaying !');

      if(this.camera.id == 88)
        this.runToStartPoint('jump')
      else 
        this.playTween(0)
    },

    stopTween() {
      this.$tween.stop()
    },
    pauseTween(tm) {
      this.$tween.pause(tm)
    },
    playTween(tm) {

      // TweenMax.set(this.$cameraman.position, { x: 2, z: -.5 })
      // this.cameramanLookAt()
      // return

      console.log('PLAY');
      // return

      
      this.$tween.play(tm)
    },

    runToStartPoint(motion) {

      this.stopTween()
      this.setMotion(this.current, 'idle') 
      if(this.$run)
        this.$run.kill()

      this.$run = new TimelineMax({})
          .to(this.$cameraman.position, .35, 
          { 
            x: this.$cue1[0], 
            z: this.$cue1[1], 
            ease: Linear.easeNone, 
            onStart: ()=> this.setMotion('idle', 'jog'), 
            onUpdate: ()=> this.cameramanLookAt(),
          })
          .to(this.$cameraman.position, 3, 
          { 
            x: this.$cue2[0], 
            z: this.$cue2[1], 
            ease: Linear.easeNone,
            onUpdate: ()=> this.cameramanLookAt(),
          })
          .to(this.$cameraman.position, .35, 
          { 
            x: this.$first[0], 
            z: this.$first[1], 
            ease: Linear.easeNone, 
            onStart: ()=> { this.setMotion('jog', motion) }, 
            onUpdate: ()=> this.cameramanLookAt(),
          })
          .set({}, { delay: 1 })
          .call(()=>{ this.setMotion(motion, 'idle') })
          .call(()=>{ this.updateLookAt() })      
    },

    // walkToStartPoint(motion) {

    //   this.stopTween()
    //   this.setMotion(this.current, 'idle') 
    //   if(this.$run)
    //     this.$run.kill()

    //   this.$run = new TimelineMax({})
    //       .to(this.$cameraman.position, .35, 
    //       { 
    //         x: this.$cue1[0], 
    //         z: this.$cue1[1], 
    //         ease: Linear.easeNone, 
    //         onStart: ()=> this.setMotion('idle', 'jog'), 
    //         onUpdate: ()=> this.cameramanLookAt(),
    //       })
    //       .to(this.$cameraman.position, 2, 
    //       { 
    //         x: this.$cue4[0], 
    //         z: this.$cue4[1], 
    //         ease: Linear.easeNone,
    //         onUpdate: ()=> this.cameramanLookAt(),
    //       })
    //       .to(this.$cameraman.position, .35, 
    //       { 
    //         ease: Linear.easeNone, 
    //         onStart: ()=> { this.setMotion('jog', motion) }, 
    //         onUpdate: ()=> this.cameramanLookAt(),
    //       })
    //       .set({}, { delay: .35 })
    //       .to(this.$cameraman.position, .35, 
    //       { 
    //         x: this.$cue3[0], 
    //         z: this.$cue3[1], 
    //         ease: Linear.easeNone, 
    //         onStart: ()=> {this.setMotion(motion, 'front') }, 
    //         onUpdate: ()=> this.cameramanLookAt(),
    //       })
    //       .to(this.$cameraman.position, 4, 
    //       { 
    //         x: this.$cue2[0], 
    //         z: this.$cue2[1], 
    //         ease: Linear.easeNone, 
    //         onUpdate: ()=> this.cameramanLookAt(),
    //       })
    //       .to(this.$cameraman.position, .35, 
    //       { 
    //         x: this.$first[0], 
    //         z: this.$first[1], 
    //         ease: Linear.easeNone, 
    //         onStart: ()=> { this.setMotion('front', 'idle') }, 
    //         onUpdate: ()=> this.cameramanLookAt(),
    //       })
    //       .call(()=>{ this.updateLookAt() })
         
    // },

    updateLookAt() {
      this.updateFocus()
      this.playTween(0)
    },

    updateFocus() {
      TweenMax.to(this.$tracking.position, 5, { x: 0, y: 2, z: -2.5 })
    },

    setMotion(from, to){
      if(from == to)
        return
      this.current = to
      this.$store.commit('user/SET_MOTION', from +'_'+ to)
    },

    resetCameramanPosition() {
      this.$cameraman.position.x = 2000
      this.$cameraman.position.z = 500
    },

    tweenCompleted() {

      // console.log('tweenCompleted '+ this.current);

      this.$store.commit('user/SET_MOTION', this.current +'_idle')
      this.current = 'idle'

      this.locked = false
      if(!this.is_record)
        TweenMax.to(this.$refs.button, .25, { opacity: 1, ease: Linear.easeNone, delay: .5 })
      else
        this.$parent.$refs.recording.recordingCompleted()
    },

    setStaticPosition() {
      TweenMax.set(this.$cameraman.position, { x: 0, y: 0, z: 5 })
    },


    playGopro() {

      // console.log('playGopro');
 
      this.$cameraman.position = new Vector3(this.$dpt.x, 0, this.$dpt.z)
      this.$cameraman.lookAt(new Vector3(0, 0, 0))

      this.$pan = new TimelineMax({})
          .to(this.$cameraman.position, 3.5, { 
            x: this.$first[0], 
            z: this.$first[1],
            ease: Linear.easeNone,
          })
          .to(this.$cameraman.position, .5, { 
            y: 2, 
            onUpdate: ()=>{
              this.$cameraman.lookAt(new Vector3(0, 0, 0))
            },
            yoyo: true,
            repeat: 1,
            ease: Back.easeIn,
            onComplete: ()=>{ 
              this.InitializeTween() 
              this.playTween(0)

            }
          })
    },


    panBellAndHowell() {

      // console.log('panBellAndHowell');
      const py =  Settings.scene_translations[this.lang][this.$route.params.scene] == 'jeu' ? 1 : 0
      let obj = { x: 1 }
      this.$cameraman.lookAt(new Vector3(obj.x, py, 0))
      this.$pan = TweenMax.to(obj, 10, { 
        x: -1, 
        onUpdate: ()=>{
          this.$cameraman.lookAt(new Vector3(obj.x, py, 0))
        },
        ease: SteppedEase.config(80),
        delay: 0
      })
    },

    getDistance(xA, yA, xB, yB) { 
      let xDiff = xA - xB 
      let yDiff = yA - yB 
      return Math.sqrt(xDiff * xDiff + yDiff * yDiff)
    },

    getNextPoint(_x1, _y1, _x2, _y2, _smallerLen) {
      let x1 = _x1
      let y1 = _y1
      let x2 = _x2
      let y2 = _y2
      let xlen = x2 - x1
      let ylen = y2 - y1
      let hlen = Math.sqrt(Math.pow(xlen,2) + Math.pow(ylen,2))
      let smallerLen = _smallerLen
      let ratio = smallerLen / hlen
      let smallerXLen = xlen * ratio
      let smallerYLen = ylen * ratio
      let smallerX = x1 + smallerXLen
      let smallerY = y1 + smallerYLen
      return [smallerX, smallerY]
    },

    resetFinal() {

      // console.log('resetFinal ======= ');

      this.InitializeTween() 

      const pos = this.getPos(this.$path[0])
      this.$cameraman.position.x = pos[0]
      this.$cameraman.position.y = 0
      this.$cameraman.position.z = pos[1]
      this.$cameraman.lookAt(new Vector3(0, 0, 0))
    },

  },

  computed: {
    path() { return this.$store.getters['user/path'] },
    camera() { return this.$store.getters['user/camera'] },
    is_recording() { return this.$store.getters['user/is_recording'] },
    viewport() { return this.$store.getters['user/viewport'] },
    changed() { return this.$store.getters['user/changed']},
    ideal_camera() { return this.$store.getters['user/ideal_camera'] },
    step() { return this.$store.getters['user/step'] },
  },

  watch: {
    // 'current_dir': 'updateDir',
    '$route': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{
          if(this.$route.params.path && this.$route.params.process == undefined)
            this.reset()
          else if(this.$route.params.single || this.$route.params.double)
            this.resetFinal()
        })
      }
    },
    is_recording(nw, od) {
      if(nw)
        this.panCamera()
      else 
        this.stopTween()
    },
    viewport(nw, od) {

      if(this.$pan) this.$pan.kill()

      if(this.$route.params.single != undefined || this.$route.params.double != undefined) {
        if(nw == 'origine' && this.camera.id == 88){
          this.stopTween()
          this.playGopro()
          // this.setStaticPosition()
        }

        else if(nw == 'compare' && this.ideal_camera == 86){
          this.stopTween()
          this.setStaticPosition()
        }
        else if(nw == 'compare' && this.ideal_camera == 52){
          this.stopTween()
          this.setStaticPosition()
          this.panBellAndHowell()
        }
        else if(this.changed != 0 || nw == 'origine' || this.step == 2) {
          TweenMax.delayedCall(.25, ()=>{  this.playTween(0) })
        }
      }
    },
  },

}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Visualisation
  position relative
  width 100%
  height 100%
  // background-color rgba(0,0,0,0.5)

  display flex
  align-items center
  justify-content center

  .Btn
    opacity 0

</style>




















