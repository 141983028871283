import Settings from '@/config/Settings'

export default {
  namespaced: true,
  state: {
    loaded: !Settings.loader,
    promises: [],
  },
  mutations: {
    'LOADER_LOADED' (state) {
      state.promises = null
      state.loaded = true
    },
    'PROMISE_PUSH' (state, promise) {
      if(state.promises)
        state.promises.push(promise)
    },
  },
  getters: {
    loaded: state => state.loaded,
    promises: state => state.promises,
  }
}
