<template>
  <div class="Page ExperiencePage">
    
    <Environment/>
    <Introduction v-if="!$route.params.introduction"/>

    <template v-if="$route.params.introduction">
      
      <!--  
      <Cameras v-if="!$route.params.camera"/>
      <Items v-if="!$route.params.items && $route.params.camera"/> 
      <Scenes v-if="!$route.params.scene && $route.params.items"/> 
      -->

      <Scenes v-if="!$route.params.scene"/> 
      <Cameras v-if="!$route.params.camera && $route.params.scene"/>
      <Items v-if="!$route.params.items && $route.params.camera && camera && camera.avatarsLoaded"/> 
      
      <template v-if="$route.params.scene
                  && $route.params.camera 
                  && $route.params.items 
                  && scene 
                  && scene.animationLoaded 
                  && camera 
                  && camera.avatarsLoaded"> 
        
        <!-- <CameraOrthographic /> -->
        <!-- <template v-if="cameraOrthographic">    -->
          
          <Scene ref="scene"/>
          <Matrix v-if="$route.params.items 
                    && !$route.params.path"/>

          <Visualisation ref="visualisation" v-if="$route.params.items && $route.params.path"/>
          <Process v-if="$route.params.process && $route.params.single == undefined"/>
          <Final v-if="$route.params.single || $route.params.double"/>
          
          <!--  
          <template v-if="$route.params.items && $route.params.path && $route.params.process">
            <Process v-if="!$route.params.single && !$route.params.double"/>
            <Final v-if="$route.params.single || $route.params.double"/>
          </template> 
          -->

        <!-- </template> -->

      </template>
    </template>

    <Breadcrumb />
    <Narration ref="narration"/>
    <!-- <Sound/> -->
    <Defilement/>

    <!-- <Narration ref="narration" v-if="!this.fullScreenVideo.isOpen" /> -->
    <!-- <WelcomeBack v-if="comeback"/>  -->
    <!-- 
    <transition name="ExperiencePage__is-loading" @leave="leave">
      <div v-if="avatarsIsLoading || sceneIsLoading" class="ExperiencePage__is-loading">
        <div class="ExperiencePage__is-loading-spin" />
      </div>
    </transition> 
    -->

  </div>
</template>

<script>

import Page from '@/core/mixins/Page'
import Settings from '@/config/Settings'
import Introduction from '@/components/introduction/Introduction'
import Scenes from '@/components/scenes/Scenes'
import Breadcrumb from '@/components/breadcrumb/Breadcrumb'
import Cameras from '@/components/cameras/Cameras'
import Avatars from '@/components/avatars/Avatars'
import Scene from '@/components/scene/Scene'
import Matrix from '@/components/matrix/Matrix'
import Visualisation from '@/components/visualisation/Visualisation'
import CameraOrthographic from '@/components/camera-babylon/CameraOrthographic'
import Items from '@/components/items/Items'
import Process from '@/components/process/Process'
import Final from '@/components/final/Final'
import Narration from '@/components/narration/Narration'
// import Sound from '@/components/sound/Sound'
import Environment from '@/components/environment/Environment'
import WelcomeBack from '@/components/experience/WelcomeBack'
import Defilement from '@/components/assets/Defilement'

import 'babylonjs-loaders'
import { SceneLoader } from 'babylonjs'

export default {
  name: 'ExperiencePage',
  mixins: [Page],
  components: { Breadcrumb, Scenes, Cameras, Avatars, Scene, Matrix, Visualisation, CameraOrthographic, Items, Narration, Process, Final, Environment, WelcomeBack, Introduction, Defilement },
  translations: {
    en: { loading: 'loading', title: 'Shoot your own Scene', subtitle: 'Shoot your own Scene' },
    fr: { loading: 'chargement', title: 'Ma caméra et moi', subtitle: 'Tourne ton film' },
  },

  head() {
    return {
      title: this.trans('subtitle'),
      titleTemplate: '%s | '+ this.trans('title'),
    }
  },

  data: () => ({
    cameraOrthographic: null,
    avatarsIsLoading: false,
    sceneIsLoading: false,
    // comeback: false,
  }),
  watch: {
    '$route': {
      immediate: true,
      handler() {

        this.$store.commit('user/ROUTE_CHANGED')


        if(this.$route.params.camera != undefined) {

          this.$compare_id = Settings.comparaisons[Settings.scene_translations[this.lang][this.$route.params.scene]][this.$route.params.camera]

          console.log(this.$compare_id);

          const ideal_camera = Settings.index_to_id[this.$compare_id]
          this.$store.commit('user/IDEAL_CAMERA', ideal_camera)

        }

        console.log(this.scene);

        if(this.camera && !this.camera.avatarsLoaded 
        || this.scene && !this.scene.animationLoaded) 
          this.loadSceneAnimationAndloadAvatars()

        // if(this.$route.params.camera && this.camera // && !this.camera.avatarsLoaded 
        // || !this.scene.animationLoaded) 
        //   this.loadSceneAnimationAndloadAvatars()
 
      }
    },
  },
  computed: {
    camera() { return this.$store.getters['user/camera'] },
    cameras() { return this.$store.getters['data/cameras'] },
    scene() { return this.$store.getters['user/scene'] },
    fullScreenVideo() { return this.$store.getters['app/fullScreenVideo'] },
    changed() { return this.$store.getters['user/changed'] },
  },
  created() {

    this.$babylon = this.$parent.$babylon
    // if(this.changed == 0) this.comeback = true
  },

  methods: {

    // onContinue() {
    //   // this.comeback = false
    //   // this.$refs.narration.onContinue()
    // },

    loadSceneAnimation: async function() {
      this.sceneIsLoading = true
      const animation = await SceneLoader.LoadAssetContainerAsync('', this.scene.animation)
      this.$store.commit('user/SET_SCENE_ANIMATION', animation)
      this.sceneIsLoading = false
    },
    loadAvatars: async function() {
      let count = 0
      const avatars = []
      this.avatarsIsLoading = true
      while(count < this.camera.avatars.length) {
        avatars.push(await SceneLoader.LoadAssetContainerAsync('', this.camera.avatars[count].mesh))
        count++
      }
      this.$store.commit('user/SET_AVATARS', avatars)
      this.avatarsIsLoading = false
    },
    loadSceneAnimationAndloadAvatars: async function() {

      console.log('loadSceneAnimationAndloadAvatars !');

      if(this.camera) {

        // let count = 0

        const avatars = []
        // const this.$compare_id = Settings.comparaisons[Settings.scene_translations[this.lang][this.$route.params.scene]][this.$route.params.camera]

        // const ideal_camera = Settings.index_to_id[this.$compare_id]
        // this.$store.commit('user/IDEAL_CAMERA', ideal_camera)
        this.$store.commit('user/OTHER_LOADING', true)

        // console.log(this.camera);
        // console.log(this.camera.avatars);
        // console.log('xxxxxxxxxxxxxx');

        // while(count < this.camera.avatars.length) {
        //   avatars.push(await SceneLoader.LoadAssetContainerAsync('', this.camera.avatars[count].mesh))
        //   count++
        // }

        avatars.push(await SceneLoader.LoadAssetContainerAsync('', this.camera.avatars[0].mesh))
        avatars.push(await SceneLoader.LoadAssetContainerAsync('', this.cameras[this.$compare_id].avatars[0].mesh))
        this.$store.commit('user/SET_AVATARS', avatars)
      }

      const animation = await SceneLoader.LoadAssetContainerAsync('', this.scene.animation)
      this.$store.commit('user/SET_SCENE_ANIMATION', animation)
      await new Promise(res => { TweenMax.delayedCall(1, ()=> res()) })
      this.$store.commit('user/OTHER_LOADING', false)
    },
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.ExperiencePage
  height 100vh

  .BtnSlider 
    position fixed

  &__is-loading
    position absolute
    top 0
    left 0
    height 100vh
    width 100vw
    background rgba(#13125b, 1)
    color white
    display flex
    justify-content center
    align-items center
    transition opacity 0s
    z-index 2000
    &-enter
    &-leave-active
      opacity 0
    &-spin
      position absolute
      top 50%
      left 50%
      margin -25px 0 0 -25px
      border 2px solid rgba(orange, 1)
      border-top 2px solid rgba(orange, .1)
      border-radius 50%
      width 50px
      height 50px
      animation Experience__is-loading-spin 2s linear infinite
      @keyframes Experience__is-loading-spin {
        from { transform: rotate(0deg) }
        to { transform: rotate(360deg) }
      }
</style>
