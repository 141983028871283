<script>

import Mixin from '@/core/mixins/CameraFinal'
import { Vector2, Viewport } from 'babylonjs'
import 'babylonjs-loaders'

export default {

  name: 'CameraSuper8',
  mixins: [ Mixin ],
  render: h => h(),

  data: () => ({
    // step: 1
  }),

  beforeDestroy() { 
  },

  created() {

    // this.$camera.rotation.x = 15 * (Math.PI/180)
    // this.$viewport[1] = new Viewport(.325, .45, .35, .40)
    // this.$viewport[2] = new Viewport(.55, .50, .35, .35)
  },

  mounted() {

    console.log('Camera Super8');

    // new BABYLON.BlurPostProcess("Horizontal blur", new Vector2(1.0, 0), 100.0, 1.0, this.$camera)
  },

  methods:{
  },
  watch: {
  },

}

</script>


