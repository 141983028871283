<template>
  <div class="Final">
    <div class="Final__right" v-if="!$route.params.double">
      <div class="Block container">  
        <div class="Final__button">
          <transition @enter="enterButton" @leave="leaveButton" mode="out-in">
            <button class="Btn" :aria-label="trans('continue')" @click="onClick()" v-if="active">{{ trans('continue') }}</button>
          </transition>
        </div>
      </div>
    </div>

    <div class="Final__view">
      <div ref="screens" class="Final__view-screens">   

        <div class="Final__content">
          <div ref="origine_container" class="Final__view-screen">
            <div ref="origine_subscreen" class="Final__view-subscreen">
              <div class="Final__wall" :class="'is-camera-'+ camera.id">
                <div 
                  ref="origine" 
                  class="Final__wall-background" 
                  :class="'is-background-'+ getName(camera.id) +'-'+ getScene() +''"

                  />  
                <!-- <div id="iris" v-if="camera.id == 86"/> -->
                <div id="iris" ref="iris" v-if="camera.id == 52"/>
                <div id="black" v-if="camera.id == 56"/>
                <div id="glitch" v-if="camera.id == 96"/>

              </div> 
              <h5 ref="thumbnail" class="Final__desc" :class="getOrigine()">{{ camera[lang].name }}</h5>
              <h6 ref="muted_origine" class="Final__view-muted">{{ trans('muted') }}</h6>
              <button 
                ref="origine_button" 
                class="Btn Final__view-button" 
                :class="{ 'enable': enable_origine }"
                @click="onClick('origine')">
                <div class="Final__view-background"></div>
                <img :src="require('@/commons/img/icon_replay.png')">
                <div class="Final__txt">{{ trans('left.title') }}</div>
                <!-- <h5 v-html="trans('left.description')"/> -->
              </button>
              </div>

            </div>
        </div>

        <div class="Final__content">
          <div ref="compare_container" class="Final__view-screen"> 
            <div ref="compare_subscreen" class="Final__view-subscreen">
              <div class="Final__wall" :class="'is-camera-'+ cameraIdeal.id">
                <div 
                  id="compare" 
                  ref="compare"
                  class="Final__wall-background" 
                  :class="'is-background-'+ getName(cameraIdeal.id) +'-'+ getScene() +''"/> 
                
                <div id="iris" ref="iris" v-if="cameraIdeal.id == 52"/>
                <div id="black" v-if="cameraIdeal.id == 56"/>
                <div id="glitch" v-if="cameraIdeal.id == 96"/>

              </div>
              <h5 ref="thumb_compare" class="Final__desc" :class="getCompare()">{{ cameraIdeal[lang].name }}</h5>
              <h6 ref="muted_compare" class="Final__view-muted">{{ trans('muted') }}</h6>
              <button 
                ref="compare_button" 
                class="Btn Final__view-button" 
                :class="{ 'enable': enable_compare }"
                @click="onClick('compare')">  
                <div class="Final__view-background"></div>
                <img :src="require('@/commons/img/icon_play.png')" v-if="step<2">
                <img :src="require('@/commons/img/icon_replay.png')" v-if="step>=2">
                <div class="Final__txt">{{ trans('right.title') }}</div>
                <!-- <h5 v-html="trans('right.description')"/> -->
              </button>
            </div>
          </div> 
        </div>

        <div ref="comparaison" class="Final__comparaison">
          <h6>{{ compare_txt }}</h6>
        </div>

        <transition name="Final__view-navigation">
          <div class="Final__view-navigation" v-if="navigation && this.$route.params.double != undefined">  
            <button class="Btn Final__view-btn" @click="onClick('scene')" v-html="trans('btn.scene')"/>
            <div class="Final__view-space"/>
            <button ref="button2" class="Btn Final__view-btn" @click="onClick('camera')" v-html="trans('btn.camera')"/>
            <div class="Final__view-space"/>
            <button ref="button3" class="Btn Final__view-btn" @click="onClick('cameras')" v-html="trans('btn.cameras')"/>
          </div>
        </transition>

      </div>
    </div>

    <comp :is="component_name" v-if="viewport =='origine'"/>
    <comp :is="cameraIdeal.component_name" v-if="viewport =='compare'"/>
    <div class="Page__header" v-if="$route.params.double === undefined">    
      <h1 ref="title">{{ trans('title.visionne') }}</h1>
      <div ref="description"></div>
    </div>
    <div class="Page__header" v-if="$route.params.double != undefined">    
      <h1 ref="title">{{ trans('title.compare') }}</h1>
      <div ref="description"><h4>{{ trans('description.compare') }}</h4></div>
    </div>
  </div>
</template>

<script>

import CameraCinematographe from '@/components/camera-babylon/CameraCinematographe'
import CameraSuper8 from '@/components/camera-babylon/CameraSuper8'
import CameraGoPro from '@/components/camera-babylon/CameraGoPro'
import CameraPortapak from '@/components/camera-babylon/CameraPortapak'
import CameraBolex from '@/components/camera-babylon/CameraBolex'
import CameraAaton from '@/components/camera-babylon/CameraAaton'
import CameraBellHowell from '@/components/camera-babylon/CameraBellHowell'

import Settings from '@/config/Settings'
import {} from 'babylonjs'
import 'babylonjs-loaders'

export default {
  name: 'Final',
  components: { CameraCinematographe, CameraSuper8, CameraGoPro, CameraBellHowell, CameraPortapak, CameraBolex, CameraAaton },
  translations: {
    fr: {
      muted: 'Film muet',
      continue: 'Poursuivre',
      title: {
        visionne: "Visionne ton film",
        compare: "Compare ton film"
      },
      description: {
        visionne: "",
        compare: "Que remarques-tu dans cette scène tournée avec une autre caméra?"
      },
      left: {
        title: 'Revoir ton film',
        description: "Visionne ton film<br>à nouveau"
      },
      right: {
        // title: 'Comparaison',
        title: 'Compare',
        description: 'Ton film avec la même scène filmée avec une autre caméra'
      },
      btn: {
        scene: 'Fais<br>un autre<br>film',
        camera: 'Choisis<br>une autre<br>caméra',
        cameras: 'Découvre<br>les caméras'
      },
      share: 'Partager',
      son_off: 'Activer le son',
      son_on: 'Désactiver le son',
      comparaison: {
        
        // CINEMATOGRAPHE

        'plongeon_86': "L'opérateur du Cinématographe est immobile au moment du tournage.",
        'plongeon_52': "La Bell & Howell permet de faire un panoramique offrant un aperçu de l'espace dans lequel se trouvent les personnages.",
        'plongeon_56': "La Bolex permet au cinéaste de mettre la lumière sur un élément en particulier grâce à son zoom.",
        'plongeon_96': "L'opérateur peut se déplacer pour enregistrer le son et l'image sur un enregistreur portable.",
        'plongeon_94': "L'équipe de tournage peut enregistrer l'image et le son des plongeurs.",
        'plongeon_88': "Avec la GoPro, le corps en mouvement de l’opérateur se ressent à l’image.",
        
        // BELL & HOWELL

        'amoureux_86': "Le Cinématographe est beaucoup moins lourd et peut s'opérer plus facilement seul !",
        'amoureux_52': "La Bell & Howell réalise des plans extrêmement stables parce qu'elle est lourde et fixée à un trépied",
        'amoureux_56': "L' opérateur de la Bolex est beaucoup plus libre de ses mouvements.",
        'amoureux_96': "L'opérateur peut se déplacer pour enregistrer le son et l'image sur un enregistreur portable.", 
        'amoureux_94': "La Aaton permet d'enregistrer de manière synchrone le son et l'image.", 
        'amoureux_88': "Avec la GoPro, la course de l’opérateur se ressent à l’image.",

        // BOLEX

        'jeu_86': "Le Cinématographe est placé loin des personnages pour pouvoir les cadrer correctement.",
        'jeu_52': "La Bell & Howell est très lourde et s'opère à l'aide d'un trépied.",
        'jeu_56': "L'opérateur de la Bolex est beaucoup plus libre de ses mouvements.",
        'jeu_96': "Avec le Portapak, la bande magnétique donne un aspect différent à l'image.",
        'jeu_94': "La Aaton est portée sur l'épaule ce qui la stabilise davantage.",
        'jeu_88': "La GoPro ne permet pas de faire des effets au moment du tournage.",

        // AATON

        'parade_86': "Le Cinématographe fait un portait à distance de la parade.",
        'parade_52': "La Bell & Howell réalise des plans extrêmement stables parce qu'elle est lourde et fixée à un trépied.",
        'parade_56': "La Bolex permet plus de liberté à l'opérateur qui l'opère seul, mais elle n'enregistre pas le son.",
        'parade_94': "La Aaton permet de s'immerger dans la réalité des personnes filmées.",
        'parade_96': "Le Portapak permet à un opérateur seul d'enregistrer le son et l'image de manière synchrone.",
        'parade_88': "La GoPro permet de ressentir l'expérience du coureur au milieu de la parade.",

        // PORTAPAK

        'manifestation_86': "L'opérateur du Cinématographe est immobile au moment du tournage.",
        'manifestation_52': "La Bell & Howell réalise des plans extrêmement stables parce qu'elle est lourde et fixée à un trépied.",
        'manifestation_56': "La Bolex permet plus de liberté à l'opérateur qui l'opère seul, mais elle n'enregistre pas le son.",
        'manifestation_94': "La Aaton est portée sur l'épaule ce qui la stabilise davantage.",
        'manifestation_96': "Le Portapak permet de visionner ce qui est filmé tout de suite après avoir rembobiné.",
        'manifestation_88': "La GoPro filme au niveau du torse du coureur un point de vue inhabituel.",
      },
    },
    en: {
      muted: 'Silent film',
      continue: 'Next',
      title: {
        visionne: "Watch your film",
        compare: "Compare the films"
      },
      description: {
        visionne: "",
        compare: "What do you notice about the same scene filmed with a different camera?"
      },
      left: {
        title: 'Watch your film again',
        description: 'Ton film avec une autre caméra que celle chosie'
      },
      right: {
        // title: 'Comparaison',
        title: 'Compare',
        description: "Ton film avec le caméra que t'a choisie"
      },
      btn: {
        scene: 'Shoot<br>another<br>scene',
        camera: 'Try<br>a different<br>camera',
        cameras: 'Discover<br>the cameras'
      },
      son_off: 'Activate sound',
      son_on: 'Disable sound',
      share: 'Share',
      comparaison: {
        
                // CINEMATOGRAPHE

        'plongeon_86': "The Cinématographe operator must remain stationary while shooting.",
        'plongeon_52': "The Bell & Howell allows you to pan across the space in which the characters are located.",
        'plongeon_56': "Thanks to its zoom lens, the Bolex allows the filmmaker to focus on a specific element.",
        'plongeon_96': "The operator can move around to record both sound and image onto a portable recorder.",
        'plongeon_94': "The film crew can record the images and the sounds of the swimmers.",
        'plongeon_88': "With the GoPro, the operator's running session is experienced via the recorded image.",
        
        // BELL & HOWELL

        'amoureux_86': "The Cinématographe is much lighter and is easier to operate alone!",
        'amoureux_52': "The Bell & Howell produces extremely stable shots because it is heavy and it is mounted on a tripod.",
        'amoureux_56': "The Bolex operator has much more freedom of movement.",
        'amoureux_96': "The operator can move around to record both sound and image onto a portable recorder.", 
        'amoureux_94': "With the Aaton, it is possible to record the sound and the image simultaneously.", 
        'amoureux_88': "With the GoPro, the operator's running session is experienced via the recorded image.",

        // BOLEX

        'jeu_86': "The Cinématographe must be placed at a distance from the characters, in order to frame them properly.",
        'jeu_52': "The Bell & Howell is very heavy and is used with a tripod.",
        'jeu_56': "The Bolex operator has much more freedom of movement.",
        'jeu_96': "With the Portapak, the magnetic tape gives the image a different aspect.",
        'jeu_94': "The Aaton is carried on the shoulder, giving it extra stability.",
        'jeu_88': "With the GoPro, special effects cannot be added at the time of shooting.",

        // AATON

        'parade_86': "The Cinématographe creates a portrait of the parade from a distance.",
        'parade_52': "The Bell & Howell produces extremely stable shots because it is heavy and it is mounted on a tripod.",
        'parade_56': "The Bolex provides more freedom to solo operators, but it does not record sound.",
        'parade_94': "The Aaton allows the viewer to become immersed in the realities of the people being filmed.",
        'parade_96': "The Portapak allows solo operators to record the sound and the image simultaneously.",
        'parade_88': "The GoPro makes it possible to share the runner's experience in the middle of the parade.",

        // PORTAPAK

        'manifestation_86': "The Cinématographe operator must remain stationary while shooting.",
        'manifestation_52': "The Bell & Howell produces extremely stable shots because it is heavy and it is mounted on a tripod.",
        'manifestation_56': "The Bolex provides more freedom to solo operators, but it does not record sound.",
        'manifestation_94': "The Aaton is carried on the shoulder, giving it extra stability.",
        'manifestation_96': "With the Portapak, the film can be viewed immediately after being rewound.",
        'manifestation_88': "The GoPro provides an unusual point of view from the runner's chest.",

      },
    },
  },

  data: ()=>({  
    active: false,
    // current: 86,
    // component_name: 'CameraCinematographe',
    sounds: [ 'visionne_ton_film', 'visionne_ton_film_b', 'compare_ton_film_b', 'compare_ton_film' ],
    navigation: false,
    enable: false,
    auto: true,
    step: 0,
    last: 0,
    enable_origine: false,
    enable_compare: false,
    compare_txt: '',
    loop_gitch: 0
  }),

  computed: {
    cameras() { return this.$store.getters['data/cameras']},
    camera() { return this.$store.getters['user/camera']},
    current() { return this.camera.id },
    component_name() { return this.camera.component_name },
    ideal_camera() { return this.$store.getters['user/ideal_camera']},
    cameraIdeal() { 

      console.log(this.ideal_camera);
      console.log('xxxxxxx -- ');

      return _.find(this.$store.getters['data/cameras'], c => c.id == this.ideal_camera)

    },
    scene() { return this.$store.getters['user/scene']},
    loop() { return this.$store.getters['user/loop']},
    changed() { return this.$store.getters['user/changed']},
    viewport() { return this.$store.getters['user/viewport']},
    animation_completed() { return this.$store.getters['user/animation_completed']},
    sound_ended() { return this.$store.getters['user/sound_ended']},
  },
  beforeDestroy() {
    if(this.$tween_audio) this.$tween_audio.kill()
    this.muteCamera(false)
    document.removeEventListener('keypress', this.onKeyPress)  
  },
  created() {

    // console.log(this.camera);

    this.$engine = this.$parent.$babylon.engine
    this.$scene = this.$parent.$babylon.scene
    this.$scene.activeCameras = []
    this.$pos = { 
      origine: [ .25, .115, -.105, .016 ],
      thumb: [ .28, .15, .035, .049 ],
      compare: [ 1, .015, -.21, -.09 ],
      thumb_compare: [ 1, .14, -.21, .035 ],
      comparaison: [ 0, 0, -.16, -.030 ],
    }
    this.$psy = { 
      thumb: [ 0, 0, -.125, 0 ],
      thumb_compare: [ -.125, -.125, 0, -.125 ],
    }    
    this.$sc = { 
      origine: [ 1, 1, .5, 1 ],
      compare: [ .5, .5, 1, .5 ],
    } 
  },
  mounted() {
    document.addEventListener('keypress', this.onKeyPress)  
    this.getTextCompare()  
  },  
  methods: {    

    getOrigine() {
      return this.camera.id != 88 ? 'origine' : 'compare'
    },
    getCompare() {  
      return this.cameraIdeal.id != 88 ? 'origine' : 'compare'
    },
    getName(id) {
      const name = {
        86: 'cinematographe',
        52: 'bell',
        56: 'bolex',
        94: 'aaton',
        96: 'portapak',
        88: 'gopro'
      }
      return name[id]
    },
    getScene() {
      const name = {
        'le-plongeon': 'plongeon',
        'les-amoureux': 'amoureux',
        'le-jeu-de-cache-cache': 'jeu',
        'lengagement-citoyen': 'manifestation',
        'la-parade': 'parade',

        'the-swim': 'plongeon',
        'the-lovers': 'amoureux',
        'hide-and-seek': 'jeu',
        'civic-engagement': 'manifestation',
        'the-parade': 'parade'

      }
      return name[this.$route.params.scene]
    },

    updateIris(val) {
      const position = (100/26)*val
      document.getElementById("iris").style.backgroundPosition = `-${position}% 0px`
      this.$iris.count += 1
      if(this.$iris.count != this.$iris.max + 1) {
        TweenMax.delayedCall(.02, ()=> this.updateIris(this.$iris.count))
      }
    },
    showIris() {
      this.$iris = { count : 0, max: 27 }
      this.updateIris(this.$iris.count)
    },

    openIris() {
      this.$store.commit('user/UPDATE_IRIS', 'open')
      // this.$iris = { count : 13, max: 27 }
      // this.onOpenIris(this.$iris.count)
      // console.log('openIris');
    },
    onOpenIris(val) {
      const position = (100/26)*val
      document.getElementById("iris").style.backgroundPosition = `-${position}% 0px`
      this.$iris.count += 1
      if(this.$iris.count != this.$iris.max + 1) {
        TweenMax.delayedCall(.02, ()=> this.onOpenIris(this.$iris.count))
      }
    },

    setIris() {
      // const position = 50
      // document.getElementById("iris").style.backgroundPosition = `-${position}% 0px`
      // console.log('setIris');
    },

    closeIris() {
      this.$store.commit('user/UPDATE_IRIS', 'close')

      // this.$iris = { count : 27, max: 13 }
      // this.onCloseIris(this.$iris.count)
      // console.log('closeIris');
    },
    onCloseIris(val) {
      const position = (100/26)*val
      document.getElementById("iris").style.backgroundPosition = `-${position}% 0px`
      this.$iris.count -= 1
      if(this.$iris.count != this.$iris.max - 1) {
        TweenMax.delayedCall(.02, ()=> this.onCloseIris(this.$iris.count))
      }
    },

    updateGlitch(val) {
      const position = (100/32)*val
      document.getElementById("glitch").style.backgroundPosition = `-${position}% 0px`
      this.$glitch.count += 1
      
      if(this.$glitch.count != this.$glitch.max) {
        TweenMax.delayedCall(.02, ()=> this.updateGlitch(this.$glitch.count))
      } 
      else {

        this.loop_gitch += 1
        if(this.loop_gitch < 3) {
          this.$glitch.count = 0
          this.updateGlitch(this.$glitch.count)
        }
      }
    },
    showGlitch() {
      this.loop_gitch = 0
      this.$glitch = { count : 0, max: 33 }
      this.updateGlitch(this.$glitch.count)
    },
    showBlack() {
      this.$tween_black = new TimelineMax()
          .to('#black', 2, { opacity: 1, ease: Cubic.easeOut })
          .to('#black', .5, { opacity: 0, delay: .5 })
    },
    getTextCompare() {
      // const order = this.viewport == 'origine' 
                  // ? 'comparaison.'+ this.camera.id +'_vs_'+ this.ideal_camera
                  // : 'comparaison.'+ this.ideal_camera +'_vs_'+ this.camera.id
      // const order = 'comparaison.'+ this.camera.id +'_vs_'+ this.ideal_camera
      const scn = Settings.scene_translations[this.lang][this.$route.params.scene]
      // const txt = this.viewport == 'origine' ? this.camera.id : this.ideal_camera
      const txt = this.camera.id
      const order = 'comparaison.'+ scn +'_'+ txt
      this.compare_txt =  this.trans(order)
    },

    onResize() {
      const orgn = this.$refs.origine_container.getBoundingClientRect()
      const comp = this.$refs.compare_container.getBoundingClientRect()
      TweenMax.set(this.$refs.origine_subscreen, { height: Math.round(orgn.width*.56) })
      TweenMax.set(this.$refs.compare_subscreen, { height: Math.round(comp.width*.56) })
    },

    onKeyPress(event) {

      if(event.keyCode == 115)
        this.setIris()

      if(event.keyCode == 113)
        this.openIris()

      if(event.keyCode == 119)
        this.closeIris()
      
      return

      TweenMax.delayedCall(.2, ()=>{
        this.step += 1
        if(this.step > 3) this.step = 2
        this.updateScreens(.5)
      })

    },

    checkToPlaySound() {

      return

      this.auto = false
      if(this.step == 2 || this.step == 3) {
        this.$audio = this.getAudio('compare_ton_film_b')
        this.$tween_audio = TweenMax.delayedCall(2, ()=>{
          this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
        })
      }

    },

    addIntro() {
      if(this.$route.params.single && this.$route.params.double === undefined) {
        this.$tl = new TimelineMax({ paused: true })  
            .staggerFrom([ this.$refs.title, this.$refs.description ], .5, { y: 75, opacity: 0, ease: Cubic.easeOut }, .25)
            .from(this.$refs.thumbnail, .5, { x: '+=75', opacity: 0, ease: Cubic.easeOut })

        TweenMax.delayedCall(1.5, ()=>{ 
          this.$tl.play() 
        })
      } 
      else {
        this.$tl = new TimelineMax({ paused: true })  
            .set(this.$refs.description, { opacity: 0 })
            .staggerFrom([ this.$refs.title, this.$refs.description ], .5, { y: 75, opacity: 0, ease: Cubic.easeOut }, .25)
            .to(this.$refs.description, .25,{ opacity: 1 })

        TweenMax.delayedCall(1.5, ()=>{ 
          this.$tl.play() 
        })
 
      }

    },

    Initialize() {

      // if(this.$route.params.double == undefined 
      // && this.camera.component_name == "CameraCinematographe")
      //   this.muteAnimation(true)

      TweenMax.delayedCall(.1, ()=>{
        if(this.navigation && this.$route.params.double != undefined) 
          this.addButtons()
        this.muteCamera(true)
        this.setScreens()
        this.addIntro()
      })

      // if(this.$route.params.single && this.$route.params.double === undefined) {
      //   this.active = false
      //   TweenMax.delayedCall(.1, this.addNarration)
      //   TweenMax.delayedCall(6, ()=>{ this.active = true })
      // } else {
      //   TweenMax.delayedCall(.1, this.addSecondNarration)
      //   TweenMax.from(this.$el, 1, { alpha: 0, delay: 1.5 })  
      // }
    },

    addButtons() {
      if(this.$route.params.double == undefined)
        return
      TweenMax.staggerFrom([ this.$refs.button2, this.$refs.button3], .25, { x : '+=400', delay: .75}, .25)
    },
    setScreens() { 
      this.enable_origine = false
      this.enable_compare = false
      if(this.$route.params.single && this.$route.params.double === undefined) 
        this.step = 0
      else if(this.$route.params.single && this.$route.params.double && this.changed == 0)
        this.step = 2
      this.updateScreens(0)
    },
    showComparaison() {
      // return
      TweenMax.to(this.$refs.comparaison, .4, {  opacity: 1, delay: .25 })
    },
    hideComparaison() {
      TweenMax.to(this.$refs.comparaison, 0, {  opacity: 0 })
    },
    updateScreens(speed) {
      this.hideComparaison()
      this.hideMuted()

      const st1 = this.$pos['origine'][this.step]
      const sc1 = this.$sc['origine'][this.step]
      const ps1 = (window.innerWidth >= 1600) ? 1600*st1 : window.innerWidth* st1
      TweenMax.to(this.$refs.origine_container, speed, { 
        x : ps1, 
        scale: sc1, 
        onComplete: ()=>{ this.completeViewport() }
      })

      let ex1 = this.camera.id == 88 || this.cameraIdeal.id == 88 ? 48 : 48

      // ?  108 : 48
      
      const scale = (sc1 != 1) ? 2 : 1
      const px = (sc1 != 1) ? ex1 : 0
      const py = (sc1 != 1) ? 24 : 0
      TweenMax.to(this.$refs.thumbnail, speed, { 
        scale: scale, 
        x: px,
        y: py,
      
      })
      const scc = (sc1 != 1) ? 1 : 2
      const cx = (sc1 != 1) ? 0 : 48
      const cy = (sc1 != 1) ? 0 : 24
      TweenMax.to(this.$refs.thumb_compare, speed, { 
        scale: scc, 
        x: cx,
        y: cy,
      
      })
      const st2 = this.$pos['compare'][this.step]
      const sc2 = this.$sc['compare'][this.step]
      const ps2 = (window.innerWidth >= 1600) ? 1600*st2 : window.innerWidth* st2
      TweenMax.to(this.$refs.compare_container, speed, { x : ps2, scale: sc2 })
      const y1 = this.$pos['comparaison'][this.step]
      const y2 = (window.innerWidth >= 1600) ? 1600*y1 : window.innerWidth*y1
      TweenMax.to(this.$refs.comparaison, 0, { x : y2 })
      this.updateAudio(1)
    },

    updateAudio(delay) {   

      if(this.step == 3 && this.last >= 1)
        return

      this.$audio = this.getAudio(this.sounds[this.step])
      this.$tween_audio = TweenMax.delayedCall(delay, ()=>{
        this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
      })
      this.last +=1 
    },

    completeViewport() {
      switch(this.step){
        case 0 :
          this.$store.commit('user/SET_VIEWPORT', 'origine')
          TweenMax.set('.Canvas',{ opacity: 1 })

          if(this.camera.id == 52)
            TweenMax.delayedCall(1.5, ()=>this.openIris())

          else if(this.camera.id == 96)
            TweenMax.delayedCall(3, ()=>this.showGlitch())

          // else if(this.camera.id == 56)
          //   TweenMax.delayedCall(1.5, ()=>this.showBlack())

          TweenMax.to(this.$refs.origine, .5, { opacity: 0, delay: 2 })
          TweenMax.to(this.$refs.compare, 0, { opacity: 1 })
          TweenMax.delayedCall(3, ()=> this.setMuted())
          this.navigation = false
          break 

        case 1 :
          this.enable_compare = true
          this.navigation = false
          break 

        case 2 :

          TweenMax.to(this.$refs.origine, .5, { 
            opacity: 1, 
            onComplete: ()=>{ this.$store.commit('user/SET_VIEWPORT', 'compare')} 
          })

          if(this.cameraIdeal.id == 52)
            TweenMax.delayedCall(.25, ()=>this.openIris())

          // else if(this.cameraIdeal.id == 52)
          //   TweenMax.delayedCall(.25, ()=>this.showBlack())

          TweenMax.to(this.$refs.compare, .5, { 
            opacity: 0, 
            onComplete: ()=>{ 

              // this.navigation = true

              this.setMuted()

              TweenMax.delayedCall(12.5, ()=>{ this.navigation = true })
              this.enable_origine = true 
              this.showComparaison()

              if(this.cameraIdeal.id == 96)
                TweenMax.delayedCall(1.75, ()=>this.showGlitch())

            },
            delay: 1.2
          })
          break

        case 3 :

          TweenMax.to(this.$refs.compare, .5, { 
            opacity: 1, 
            onComplete: ()=>{ this.$store.commit('user/SET_VIEWPORT', 'origine')}   
          })

          if(this.camera.id == 52)
            this.openIris()

          // else if(this.camera.id == 56)
          //   this.showBlack()

          TweenMax.to(this.$refs.origine, .5, { 
            opacity: 0, 
            onComplete: ()=>{ 

              // this.navigation = true

              TweenMax.delayedCall(12.5, ()=>{ this.navigation = true })
              this.enable_compare = true 
              this.showComparaison()
              this.setMuted()

              if(this.camera.id == 96)
                TweenMax.delayedCall(1.75, ()=>this.showGlitch())

            },
            delay: 1.2
          })
        
          break
      }
    },

    setMuted() {
      return
      this.showMuted()
    },
    showMuted() {

      if(this.viewport == 'origine' && this.camera.id == 86
      || this.viewport == 'origine' && this.camera.id == 52
      || this.viewport == 'origine' && this.camera.id == 56
      || this.viewport == 'compare' && this.ideal_camera == 86
      || this.viewport == 'compare' && this.ideal_camera == 52
      || this.viewport == 'compare' && this.ideal_camera == 56)
        TweenMax.to(this.$refs['muted_'+ this.viewport], .25, { opacity: 1 })
      //this.$store.commit('user/CAMERA_MUTED', true)
    },
    hideMuted() {
      TweenMax.to(this.$refs.muted_origine, 0, { opacity: 0 })
      TweenMax.to(this.$refs.muted_compare, 0, { opacity: 0 })
    },
    updateCamera(id) {
      this.current = id
    },
    getClass(id) {
      return (id == this.current) ? true : false
    },
    muteCamera(b) {
      this.$store.commit('user/CAMERA_MUTED', b)
    },

    onClick(indice) {

      switch(indice) {

        case 'scene' :
          this.$store.commit('user/USE_TRANSITION', { scene: null })
          this.$store.commit('user/SET_SOUND', 'secondary_click')
          break

        case 'camera' :
          this.$store.commit('user/USE_TRANSITION', { camera: null })
          this.$store.commit('user/SET_SOUND', 'secondary_click')
          break

        case 'cameras' :
          // this.$store.commit('user/USE_TRANSITION', { introduction: null })

          const link = this.lang == 'fr' 
            ? "https://macameraetmoi.ca/decouvre-les-cameras/"
            : "https://macameraetmoi.ca/discover-the-cameras/?lang=en"

          window.location.href = link

          this.$store.commit('user/SET_SOUND', 'secondary_click')
          break

        default : 
          let del = 0

          // console.log(indice == 'origine',this.animation_completed, this.cameraIdeal.id == 52);
          // console.log(this.step);

          if(indice == 'compare' 
          && this.animation_completed == 1 
          && this.camera.id == 52
          && this.step != 1 
          ||indice == 'origine' 
          && this.animation_completed == 1
          && this.cameraIdeal.id == 52
          && this.step != 1 )
          {
            this.closeIris()
            del += 4
          }

          // else if(indice == 'compare' 
          // && this.animation_completed == 1 
          // && this.camera.id == 56
          // ||indice == 'origine' 
          // && this.animation_completed == 1
          // && this.cameraIdeal.id == 56 )
          // {
          //   this.showBlack()
          //   del += .5
          // }

          TweenMax.delayedCall(del, ()=>{  
            this.resetBeforeTweening(indice)
            this.$store.commit('user/SET_SOUND', 'primaire_click')
            TweenMax.delayedCall(.25, ()=>{
              this.step = (indice == 'compare') ? 2 : 3
              this.updateScreens(.5) 
            })
            if(this.$route.params.double == undefined)
              this.$store.commit('user/USE_TRANSITION', { double: true })
          })
      }
    },

    resetBeforeTweening(indice) {
      this['enable_'+ indice] = false
      const old = (indice == 'origine') ? 'compare' : 'origine'
      TweenMax.to(this.$refs[old], .25, { 
        opacity: 1, 
        onComplete: ()=> this.resetCanvas(),
      }) 
    },
    enterButton(el, done) {
      TweenMax.from(el, .75, {  y: window.innerHeight*.25, alpha: 0, ease: Cubic.easeOut, onComplete: ()=>{ done() }, delay: .5 })
    },
    leaveButton(el, done) {
      TweenMax.to(el, .25, {  y: window.innerHeight*.25, alpha: 0, ease: Cubic.easeOut, onComplete: ()=>{ done() }, delay: .5 })
    },
    getRatio(ratio) {
      const vals = ratio.split(':')
      return (parseInt(vals[1]) / parseInt(vals[0]) * 100) + '%'
    },
    getBlockDirection(index) {
      return index === 0 ? 'left' : 'right'
    },
    isVisible(type) {
      if(type == 'left') return true
      if(!this.$route.params.double && type == 'right') return false
      return true
    },
    addActiveCamera(camera, reset_canvas) {
      this.resetCanvas = reset_canvas
      this.$scene.activeCameras.push(camera)
    },
    // addNarration() {
    //   if(this.$tween_audio) this.$tween_audio.kill()
    //   this.$tween_audio = new TimelineMax()
    //       .set({}, { delay: 2 })
    //       .call(()=>{
    //         this.$audio = this.getAudio('film_is_ready')
    //         this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
    //       })
    // },
    // addSecondNarration() {
    //   if(this.$tween_audio) this.$tween_audio.kill()
    //   this.$tween_audio = new TimelineMax()
    //       .set({}, { delay: 2 })
    //       .call(()=>{
    //         this.$audio = this.getAudio('perception')
    //         this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
    //       })
    //       .set({}, { delay: 6 })
    //       .call(()=>{
    //         this.$audio = this.getAudio('bye')
    //         this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
    //       })
    // },
  },

  watch: {

    '$route': {
      immediate: true,
      handler() {
        this.$nextTick(()=>{

          this.last = 0

          if(this.$refs.iris){
            TweenMax.delayedCall(.1, ()=> {
              this.setIris()})
          }

          if(this.$route.params.single && this.$route.params.double === undefined || this.changed == 0)
            this.onResize()

          if(this.step == 1) {
            this.addIntro()
            return
          }
          // this.last = 0
          this.resetCanvas()
          this.hideMuted()
          if(this.$route.params.single && this.$route.params.double === undefined) { 
            this.$store.commit('user/SET_VIEWPORT', 'none')
          }
          this.Initialize()
        })
      }
    },
    'current'(nw, od) {
      _.each(this.cameras, (cam)=>{
        if(cam.id == nw)
          this.component_name = cam.component_name
      })
    },
    'step'(nw, od) {
      this.$store.commit('user/SET_STEP', nw)
    },
    animation_completed(nw, od) {

      // console.log('x !!!'+ this.step);
      // return

      if(this.step == 0) {
        let del = 0
        if(this.camera.id == 52) {
          this.closeIris()
          del +=2
        } 
        else if(this.camera.id == 56) {
          this.showBlack()
          del +=2
        }    
        TweenMax.to(this.$refs.origine, .5, { 
            opacity: 1, 
            onComplete: ()=>{ 
              this.resetCanvas()
              this.step += 1
              this.updateScreens(.5)
            },
            delay: del
        })  
      } 
      else if(this.step == 2) {
        let del = 0
        if(this.cameraIdeal.id == 52) {
          this.closeIris()
          del +=2
        } 
        else if(this.cameraIdeal.id == 56) {
          this.showBlack()
          del +=2
        }        
        TweenMax.to(this.$refs.compare, .5, { 
            opacity: 1, 
            delay: del
        })  
      }
      else if(this.step == 3) {
        let del = 0
        if(this.camera.id == 52) {
          this.closeIris()
          del +=2
        } else if(this.camera.id == 56) {
          this.showBlack()
          del +=2
        }       
        TweenMax.to(this.$refs.origine, .5, { 
            opacity: 1, 
            delay: del
        })  
      }
    },
    'navigation'(nw, od) {
      if(nw)
        this.$nextTick(()=>{
          this.addButtons()
        })    
    },
    'sound_ended'(nw, od) {
      if(nw) 
        this.checkToPlaySound()  
    },
    // 'viewport'(nw, od) {
    //   this.getTextCompare()
    // },

    'viewport': {
      immediate: true,
      handler() {   
        this.getTextCompare()
      }
    },


  },
}

</script>

<style lang="stylus">

@import '~@/config/Settings'
@import '~@/commons/stylus/Media-queries'

.Final
  position absolute
  width 100vw
  height 100vh
  top 0
  left 0
  display flex
  align-items center
  flex-direction row
  justify-content center
  overflow hidden

  &__content 
    position relative
    height 100%

  &__desc 
    display inline-block
    text-transform uppercase
    background yellow 
    color blue-dark
    margin-top 16px
    padding 4px 16px
    border-radius 8px
    position absolute
    bottom -6vh

    &.origine
      left 7.5vw 

    &.compare
      left 0px
    
  &__comparaison
    position absolute
    background yellow 
    text-align left
    width 16%
    // height 180px
    display flex
    align-items center
    justify-content center
    color blue-dark
    padding 12px
    border-radius 16px  

  &__view
    width 100%
    height 100%
    max-width 1600px
    max-height 900px
    position absolute
    display flex
    align-items center
    justify-content center


    &-muted 
      position absolute
      top 24px
      right 90px
      background blue-dark 
      text-transform uppercase
      padding 8px 14px 
      border-radius 8px
      opacity 1

    &-screens 
      height 50%
      max-height 450px
      display flex
      align-items center
      justify-content center
      flex-direction row
      position relative

    &-navigation 
      width 19%
      position absolute
      right 0
      height 100%
      display flex
      align-items center
      justify-content space-between
      flex-direction column
      transition transform .5s
      transition-delay .5s
    
      &-enter
      &-leave-active
        transform translateX(50vw)

    &-space 
      background black 
      height 8vh

    &-btn
      width 100%
      height 100%
      border-radius unset
      border-top-left-radius 16px
      border-bottom-left-radius 16px
      text-align left
      font-size .8vw
      line-height 1.32vw

    &-screen 
      height 100%
      max-height 450px
      display flex
      align-items center
      justify-content center
      width 50vw
      max-width calc(1600px * .5)

    &-subscreen
      position absolute
      max-width 800px
      height 100%
      width 100%
      display flex
      justify-content center 
      align-items center

    &-button
      position relative
      //width 350px
      //height 350px
      //max-width 400px
      //max-height 400px

      width max(25vh, 280px)
      height max(25vh, 280px)


      border-radius 50%

      font-size 1.7vw
      display flex
      align-items center
      justify-content center
      flex-direction column
      transition opacity .25s, transform .5s 
      opacity 0
      transform scale(.95)
      pointer-events none

      &.enable
        pointer-events auto
        transform scale(1)
        opacity 1


      &:hover
        .Final__view-background
          transform scale(1.1)

      & h5
        font-size 1.32vw
        line-height 1.75vw
        text-transform initial
        font-weight 600

      & img 
        width 48px
        margin-bottom 8px

    &-background
      position absolute
      background orange
      border-radius 50%
      z-index -1
      width 100%
      height 100%
      top 0 
      left 0
      transition transform .25s 

  &__txt
    padding 16px 0

  &__wall
    position absolute
    height 100%
    width 100%
    display flex 
    justify-content center
    align-items center
    overflow hidden

    // background-size cover
    // background-position repeat repeat
    // background-image url('./img/captures/cinematographe.png')
    // border 1px solid red

    &.is-camera-86
    &.is-camera-52
    &.is-camera-56
    &.is-camera-96
    &.is-camera-94
      width 74.656%

    &.is-camera-86
    &.is-camera-56
    &.is-camera-96
      border-radius 40px
  
    &-background
      background-size cover
      // background-position repeat repeat
      width 100%
      height 100%

    &-background.is-background-cinematographe-plongeon
        background-image url('./img/captures/cinematographe-plongeon.png')
        z-index -1

    &-background.is-background-bell-plongeon
        background-image url('./img/captures/bell-plongeon.png')

    &-background.is-background-portapak-plongeon
        background-image url('./img/captures/portapak-plongeon.png')

    &-background.is-background-bolex-plongeon
        background-image url('./img/captures/bolex-plongeon.png')
       
    &-background.is-background-aaton-plongeon
        background-image url('./img/captures/aaton-plongeon.png')

    &-background.is-background-gopro-plongeon
        background-image url('./img/captures/gopro-plongeon.png')

    &-background.is-background-cinematographe-amoureux
        background-image url('./img/captures/cinematographe-amoureux.png')
        z-index -1

    &-background.is-background-bell-amoureux
        background-image url('./img/captures/bell-amoureux.png')

    &-background.is-background-portapak-amoureux
        background-image url('./img/captures/portapak-amoureux.png')

    &-background.is-background-bolex-amoureux
        background-image url('./img/captures/bolex-amoureux.png')
       
    &-background.is-background-aaton-amoureux
        background-image url('./img/captures/aaton-amoureux.png')

    &-background.is-background-gopro-amoureux
        background-image url('./img/captures/gopro-amoureux.png')

    &-background.is-background-cinematographe-jeu
        background-image url('./img/captures/cinematographe-jeu.png')
        z-index -1

    &-background.is-background-bell-jeu
        background-image url('./img/captures/bell-jeu.png')

    &-background.is-background-portapak-jeu
        background-image url('./img/captures/portapak-jeu.png')

    &-background.is-background-bolex-jeu
        background-image url('./img/captures/bolex-jeu.png')
       
    &-background.is-background-aaton-jeu
        background-image url('./img/captures/aaton-jeu.png')

    &-background.is-background-gopro-jeu
        background-image url('./img/captures/gopro-jeu.png')

    &-background.is-background-cinematographe-manifestation
        background-image url('./img/captures/cinematographe-manifestation.png')
        z-index -1

    &-background.is-background-bell-manifestation
        background-image url('./img/captures/bell-manifestation.png')

    &-background.is-background-portapak-manifestation
        background-image url('./img/captures/portapak-manifestation.png')

    &-background.is-background-bolex-manifestation
        background-image url('./img/captures/bolex-manifestation.png')
       
    &-background.is-background-aaton-manifestation
        background-image url('./img/captures/aaton-manifestation.png')

    &-background.is-background-gopro-manifestation
        background-image url('./img/captures/gopro-manifestation.png')

    &-background.is-background-cinematographe-parade
        background-image url('./img/captures/cinematographe-parade.png')
        z-index -1

    &-background.is-background-bell-parade
        background-image url('./img/captures/bell-parade.png')

    &-background.is-background-portapak-parade
        background-image url('./img/captures/portapak-parade.png')

    &-background.is-background-bolex-parade
        background-image url('./img/captures/bolex-parade.png')
       
    &-background.is-background-aaton-parade
        background-image url('./img/captures/aaton-parade.png')

    &-background.is-background-gopro-parade
        background-image url('./img/captures/gopro-parade.png')
       
  & h6
    font-weight bold

  &__cameras
    position absolute
    top 7vw
    left 80px
    padding 12px 48px

  &__camera
    padding 8px 0
    cursor pointer

    &.active
      text-decoration underline

  &__right
    position absolute
    width 36vw
    max-width 484px
    height 100vh
    top 0px
    right 0

  &__button
    height 56px

  .Block.container
    display flex
    justify-content flex-end

  &__side
    width 15vw
    height 100vh
    display flex
    flex-direction column
    a
      display flex
      height 33.33vh
      padding 1vw
      justify-content center
      align-items center
      text-align center
      color blue-dark
      text-transform uppercase
      line-height 1.4vw

      &:nth-child(1)
        background #bb5fb3
      &:nth-child(2)
        background #1296ff
      &:nth-child(3)
        background #ffff00

  &__viewport-container
    width 90vw
    height 100vh
    display flex
    align-items center
    flex-direction column
    justify-content center
    padding 0 2.5vw

  &__top-right
    height 11vw
    text-align center

  &__btns
    text-align center
    margin-top 2vw
    .Btn
      margin-bottom 1vw

  &__container
    display flex
    justify-content space-between
    width 100%
    &.is-single
      justify-content center

  &__viewport
    position relative

  &__block
    width 39vw
    position relative

  &__son
    width 60px
    height 60px
    background-repeat no-repeat
    background-size contain
    position absolute
    right -30px
    bottom -30px
    z-index 9
    &.is-off
      background-image url('./img/son_off.png')
    &.is-on
      background-image url('./img/son_on.png')

  &__share
    width 80px
    height 80px
    background-image url('./img/share.png')
    background-repeat no-repeat
    background-size contain
    position absolute
    right -40px
    top -40px
    z-index 9

  &__yourmovie
    font-size 2vw
    text-align center
    text-transform uppercase
    margin-bottom 2vw

  &__title
    font-size 2vw
    text-align center
    text-transform uppercase
    height 32px

  &__description
    font-family montserrat
    text-align center
    font-size 1.4vw
    line-height 2vw
    margin 1.5vw 0

  &__highlight
    font-size 1vw
    font-family sourcecode
    line-height 1.4vw
    color blue-dark
    text-align center
    font-family montserrat
    background orange
    width 17vw
    height 17vw
    border-radius 300px
    position absolute
    display flex
    align-items center
    padding 2vw
    font-size 1.1vw
    line-height 1.6vw
    transition opacity 1s
    &-enter
    &-leave-active
      oapcity 0
    &.is-left
      bottom 1vw
      left 1vw
    &.is-right
      top 1vw
      left -2vw

#iris
  position absolute
  height 100%
  width 100%
  background url('./img/spritesheets/iris.png') 0px 0px
  background-size cover

#glitch
  position absolute
  height 100%
  width 100%
  background url('./img/spritesheets/glitch.png') 0px 0px
  background-size cover

#black
  position absolute
  height 100%
  width 100%
  background black
  opacity 0
  
#origine
  position absolute
  width 400px
  height 300px
  top 0
  left 0

</style>
