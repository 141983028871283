<script>

import { FreeCamera, Camera, Vector3 } from 'babylonjs'
import 'babylonjs-loaders'

export default {

  name: 'CameraOrthographic',
  render: h => h(),

  beforeDestroy() { 
    const camera = this.$parent.$babylon.camera
          camera.position.x = 0
          camera.position.z = 0
          camera.position.y = 0
          camera.setTarget(Vector3.Zero())
    this.$parent.$babylon.scene.activeCamera = camera
  },

  created() {
    this.$scene = this.$parent.$babylon.scene
    this.$engine = this.$parent.$babylon.engine
    this.$camera = new FreeCamera('camera_orthographic', new Vector3(0, 0, 0), this.$scene)

    this.$camera.position.x = 0
    this.$camera.position.y = 2
    this.$camera.position.z = 0

    // this.$camera.position.x = 0
    // this.$camera.position.y = 11.52
    // this.$camera.position.z = 0
    // this.$camera.setTarget(new Vector3.Zero())
    // this.$camera.mode = Camera.ORTHOGRAPHIC_CAMERA 
    // this.resizeOrthgraphicCamera(this.$camera)
    // this.$parent.cameraOrthographic = this.$camera
    // this.$engine.onResizeObservable.add(()=>{ this.resizeOrthgraphicCamera(this.$camera) })

    this.$scene.activeCamera = this.$camera
  },

  methods: {
    
    resizeOrthgraphicCamera(camera) {
      const coef = .0064
      const scale = Math.min(window.innerWidth/1920, window.innerHeight/1080) 
      camera.orthoTop = window.innerHeight*(coef/scale)
      camera.orthoBottom = - window.innerHeight*(coef/scale)
      camera.orthoLeft = -window.innerWidth*(coef/scale)
      camera.orthoRight = window.innerWidth*(coef/scale)
    },

  },

}
</script>




















