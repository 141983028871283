export default {
  namespaced: true,
  state: {
    general: {},
    pages: {},
    cameras: {},
    scenes: {},
    avatars: {},
  },
  mutations: {
    'SET_DATA' (state, res) {

      if(res.type == 'general')
        state.general = res.data.acf

      if(res.type == 'pages') {
        _.each(res.data, data => state.pages[data.acf.admin.route_key] = {  ...data.acf, id: data.id  })
        _.each(state.pages.experience.audios, (audio, index) => {
          state.pages.experience.audios[index].fr.text = state.pages.experience.audios[index].fr.text.split('\n')
          state.pages.experience.audios[index].en.text = state.pages.experience.audios[index].en.text.split('\n')
        })
      }

      if(res.type == 'cameras') {
        const cameras = _.orderBy(_.map(res.data, d => ({ id: d.id, ...d.acf })), cam => cam.date, 'DESC')
        _.each(cameras, (camera, index) => {
          cameras[index]['avatarsLoaded'] = false
          _.each(camera.tech_items, (item, idx)=>{  
            item.fr.retroaction = item.fr.retroaction.split('\n')
            item.en.retroaction = item.en.retroaction.split('\n')
          })

        })
        state.cameras = cameras
      }

      if(res.type == 'scenes') {
        const scenes = _.map(res.data, d => ({ id: d.id, ...d.acf }))
        const filter = _.filter(scenes, (o)=> { 
          return o.id != 196 && o.id != 198 && o.id != 200
        })
        _.each(filter, (scene, index) => {
          filter[index]['animationLoaded'] = false
        })
        state.scenes = filter
      }

      if(res.type == 'avatars')
        state.avatars = _.map(res.data, d => ({ id: d.id, ...d.acf }))
    },
  },
  getters: {
    general: state => state.general,
    pages: state => state.pages,
    cameras: state => state.cameras,
    scenes: state => state.scenes,
    avatars: state => state.avatars,
  }
}
