// import { directiveAria } from "vue-a11y-utils"

export default {
  // directives: {
  //   aria: directiveAria
  // },
  computed: {
    audiosExperience() { return this.$store.getters['data/pages']['experience']['audios'] },
  },
  methods:  {
    getAudio(key) {
      return _.find(this.audiosExperience, audio => audio.key == key)
    },
    getPointAroundCircle(angle, distance){
      const radius = 1
      return {
        x: Math.cos(-angle*Math.PI/180) * distance,
        z: Math.sin(-angle*Math.PI/180) * distance
      }
    }
  }
}
