import BrowserLanguage from 'in-browser-language'
import Cookies from 'js-cookie'
import _ from 'lodash'
import Settings from '@/config/Settings'

// Cookies.remove(Settings.cookies.lang)

const defaultLanguage = Cookies.get(Settings.cookies.lang) || BrowserLanguage.pick(Settings.locale, _.head(Settings.locale)) 

export default defaultLanguage
