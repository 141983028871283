var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Page ExperiencePage"},[_c('Environment'),(!_vm.$route.params.introduction)?_c('Introduction'):_vm._e(),(_vm.$route.params.introduction)?[(!_vm.$route.params.scene)?_c('Scenes'):_vm._e(),(!_vm.$route.params.camera && _vm.$route.params.scene)?_c('Cameras'):_vm._e(),(!_vm.$route.params.items && _vm.$route.params.camera && _vm.camera && _vm.camera.avatarsLoaded)?_c('Items'):_vm._e(),(_vm.$route.params.scene
                && _vm.$route.params.camera 
                && _vm.$route.params.items 
                && _vm.scene 
                && _vm.scene.animationLoaded 
                && _vm.camera 
                && _vm.camera.avatarsLoaded)?[_c('Scene',{ref:"scene"}),(_vm.$route.params.items 
                  && !_vm.$route.params.path)?_c('Matrix'):_vm._e(),(_vm.$route.params.items && _vm.$route.params.path)?_c('Visualisation',{ref:"visualisation"}):_vm._e(),(_vm.$route.params.process && _vm.$route.params.single == undefined)?_c('Process'):_vm._e(),(_vm.$route.params.single || _vm.$route.params.double)?_c('Final'):_vm._e()]:_vm._e()]:_vm._e(),_c('Breadcrumb'),_c('Narration',{ref:"narration"}),_c('Defilement')],2)}
var staticRenderFns = []

export { render, staticRenderFns }