<script>
import { Mesh } from 'babylonjs'

export default {
  name: 'Avatars',
  render: h => h(),
  beforeDestroy() {
    this.$tween.kill()
    this.$containerMaster.dispose()
    this.$camera.attachControl(document.querySelector('canvas'))
  },
  computed: {
    avatars() { return this.$store.getters['user/camera'].avatars },
    camera() { return this.$store.getters['user/camera'] }
  },
  created() {
    this.$avatarsMeshs = []
    this.$camera = this.$parent.$babylon.camera
    this.$scene = this.$parent.$babylon.scene
    this.$scene.activeCamera = this.$camera  
    this.$containerMaster = new Mesh('avatar_container_master', this.$scene)
  },
  mounted() {
    this.$tween = TweenMax.delayedCall(1, ()=>{
      this.init3D()
    })
  },
  methods: {
    init3D() {
      TweenMax.to(this.$camera, 0, { babylon: { rotation: 0, x: 0, y: 1, z: 0 } })
      this.$camera.detachControl(document.querySelector('canvas'))  
      const avatar = this.avatars[0]
      const avatarGLTF = avatar['babylon'].instantiateModelsToScene()
            avatarGLTF.animationGroups[0].play(true)
      const mesh = new Mesh('avatar_0', this.$scene).addChild(avatarGLTF.rootNodes[0])
      const pos = ((-1 + 1) * 1.2) + .3
      TweenMax.set(mesh, { babylon: { z: 5, rotationY: -90, x: 0 } })
      mesh['scene_pos'] = 0
      mesh.parent = this.$containerMaster
      this.$avatarsMeshs.push(mesh)  
    },
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Avatars
  position absolute
  width 100vw
  height 100vh
  display flex
  justify-content center
  align-items center

  &__center
    white-space nowrap
    text-align center

  &__title
    font-size 3vw
    color orange
    margin-bottom 20vw

  &__block
    width 20vw
    display inline-block
    padding 2vw
    img
      width 100%
      display block
      margin-bottom 2vw
</style>


