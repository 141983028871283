<template>
  <div class="CameraTooltip">
    <button :aria-label="trans('btn')" @focus="onFocus" @blur="onBlur" @click="onClick" />

    <transition name="CameraTooltip__bubble">
      <div class="CameraTooltip__bubble" v-if="bubble" :style="{ top: bubble.y + 'px', left: bubble.x + 'px' }">
        <div class="CameraTooltip__bubble-container">
          <div class="CameraTooltip__bubble-wrapper">
            <button :aria-label="trans('close')" class="CameraTooltip__bubble-close" @click="bubble = null" />
            <div class="CameraTooltip__bubble-text" v-html="bubble.text" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Mesh, MeshBuilder, ActionManager, StandardMaterial, ExecuteCodeAction, Texture, Vector3 } from 'babylonjs'

export default {
  name: 'CameraTooltip',
  props: ['tooltip', 'camera'],
  beforeDestroy() {
    TweenMax.to(this.$plane, .5, { babylon: { alpha: 0}, onComplete: () => {
      this.$plane.dispose()
    }})
  },
  translations: {
    fr: {
      btn: 'Savoir plus',
      close: 'Fermer',
    },
    en: {
      btn: 'See more',
      close: 'Close',
    },
  },
  data: () => ({
    bubble: null
  }),
  mounted() {

    const scene = this.$parent.$scene
    const camera = this.$parent.camera

    const btns = {

      play: {
        material: new StandardMaterial('material_btn', this.$scene),
        texture: new Texture(require('@/commons/img/btn_play.png')),
      },
      plus: {
        material: new StandardMaterial('material_btn', this.$scene),
        texture: new Texture(require('@/commons/img/btn_plus.png')),
      },
      focus: {
        material: new StandardMaterial('material_btn', this.$scene),
        texture: new Texture(require('@/commons/img/btn_focus.png')),
      }
    }

    _.each(btns, btn => {
      btn.material.diffuseTexture =  btn.texture
      btn.material.emissiveTexture = btn.texture
      btn.material.opacityTexture = btn.texture
    })

    // this.tooltip.position = '{"x": ".5", "y": ".14", "z": "-.45"}'
    // "x": ".4", "y": ".4", "z": ".2"

    const position = JSON.parse(this.tooltip.position)
    const rotation = JSON.parse(this.tooltip.rotation)
    const plane = MeshBuilder.CreatePlane('plane', {width: .5, height: .5, sideOrientation: Mesh.DOUBLESIDE}, scene)
          plane.position = new Vector3(Number(position.x), Number(position.y), Number(position.z))

    TweenMax.set(plane, { babylon: { ...position, rotationY: Number(rotation.y) }})
    // TweenMax.set(plane, { babylon: { ...position, rotationY: -90 }})

    plane.material = this.tooltip.type == 'video' ? btns.play.material : btns.plus.material

    const planeFocus = MeshBuilder.CreatePlane('planefocus', {width: .4, height: .4, sideOrientation: Mesh.DOUBLESIDE}, scene)
    planeFocus.material = btns.focus.material
    planeFocus.material.alpha = 0

    plane.actionManager = new ActionManager(scene)
    plane.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, event => this.onClick(event)))

    plane.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, event =>{ 
      this.$store.commit('user/SET_SOUND', 'secondary_hover')
      TweenMax.to(plane, .25, { babylon: { scale: 1.25 }}) }))
    plane.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, event =>{ TweenMax.to(plane, .25, { babylon: { scale: 1 }}) }))

    TweenMax.from(plane, 1, { babylon: { alpha: 0 }})

    plane.parent = this.camera.$mesh
    planeFocus.parent = plane

    this.$plane = plane
    this.$planeFocus = planeFocus

    TweenMax.to(plane, 1, { babylon: { scale: .8 }, repeat: 5, yoyo: true, delay: 1.25 })
  },
  methods: {
    onFocus() {
      const position = JSON.parse(this.tooltip.position)
      this.$planeFocus.material.alpha = 1
      TweenMax.to(this.camera.$mesh, 1, { babylon: { rotationY: position.x > 0 ? 180 : 0, rotationX: 0, rotationZ: 0 } })
    },
    onBlur() { this.$planeFocus.material.alpha = 0 },
    onClick(event) {
      this.$store.commit('user/SET_SOUND', 'secondary_click')
      if(this.tooltip.type == 'video')
        this.$store.commit('app/OPEN_FULLSCREENVIDEO', { video: this.tooltip.video })

      if(this.tooltip.type == 'text') 
        this.$store.commit('user/ADD_MESSAGE', { type: 'message', txt: this.tooltip[this.lang].text })

      this.$parent.removeSimulation()


      
        // this.bubble = {
        //   text: this.tooltip[this.lang].text,
        //   x: event.pointerX || window.innerWidth / 2,
        //   y: event.pointerY || window.innerHeight / 2
        // }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.CameraTooltip

  &__bubble
    position absolute
    transition opacity 1s
    &-enter
    &-leave-active
      opacity 0
    &-container
      position absolute
      transform translate(-50%, -50%)
      left 50%
      top 50%
      background orange
      width 25vw
      height 25vw
      border-radius 12.5vw
      padding 5vw
    &-wrapper
      position absolute
      transform translate(-50%, -50%)
      left 50%
      top 50%
      text-align center
      width 20vw
      font-size 1.3vw
      line-height 1.5vw
      font-family montserrat
      color blue-dark
    &-close
      width 3vw
      height 3vw
      cursor pointer
      position absolute
      top -5vw
      outline-color blue-dark
      left 50%
      transform translateX(-50%)
      pointer-events all
      &:after
      &:before
        content ''
        position absolute
        height 100%
        width 2px
        background blue-dark
        left 50%
        top 50%
      &:before
        transform translate(-50%, -50%) rotate(45deg)
      &:after
        transform translate(-50%, -50%) rotate(-45deg)

</style>
