<template>
  <button 
    @mouseenter="mouseenter" 
    @mouseleave="mouseleave" 
    @click="onClick" class="BtnGeneral" :class="add" :aria-label="trans(txt)" v-if="ready">
      <div class="BtnGeneral__background" ref="background">{{ txt }}</div>
      <div class="BtnGeneral__txt">{{ txt }}</div>
  </button>
</template>

<script>
export default {
  name: 'BtnGeneral',
  props: ['txt', 'add', 'fx'],
  data: ()=>({
    ready: false
  }),

  mounted() {
    this.$nextTick(()=>{
      this.ready = true
      TweenMax.delayedCall(.2, ()=>{
        this.$tl = new TimelineMax({ paused: true })
        this.$tl.to(this.$refs.background, .25, { scale: 1.1 })
      })
    })
  },
  methods: {
    onClick() {
      const _fx = this.fx == 'secondary' ? this.fx : 'primaire'
      this.$store.commit('user/SET_SOUND', _fx +'_click')
      TweenMax.delayedCall(.25, ()=>{ this.$emit('click') })
    },
    mouseenter() {
      const _fx = this.fx == 'secondary' ? this.fx : 'primaire'
      this.$store.commit('user/SET_SOUND', _fx +'_hover')
      this.$tl.play()
    },
    mouseleave() {
      this.$tl.reverse()
    }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.BtnGeneral
  position relative

  &.is-inactive
    pointer-events none
    
  &.is-inactive > &__background
    background transparent
    opacity .2
    pointer-events none

  &.is-inactive > &__txt
    color orange
    opacity .2

  &.reverse > &__background
    background blue-dark 
    border-color blue-dark

  &.reverse > &__txt
    color orange

  &.border > &__background
    border-color blue-dark

  &__background 
    background orange
    padding 0px 30px
    text-transform uppercase
    display inline-block
    color transparent
    font-size 1vw
    border 2px solid orange
    border-radius 50px
    padding 1vw 2vw .9vw

  &__txt 
    position absolute
    text-transform uppercase
    color blue-dark
    top 50%
    left 50%
    transform translate(-50%, -50%)
    text-align center
    font-size 1vw
    padding-top 4px
    white-space nowrap

</style>
