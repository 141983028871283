<script>
import Mixin from '@/core/mixins/CameraFinal'
import { Vector2, Viewport, Vector3 } from 'babylonjs'
import 'babylonjs-loaders'

export default {
    name: 'CameraPortapak',
    mixins: [ Mixin ],
    render: h => h(),

    data: ()=>({
        fov: 1.5, // 10mm = 1.6 | 12.5mm = 1.5| 30mm  = 1.4| 35mm = 1.3| 50mm = .8 | 75mm = .6| 100mm = .4
        range: [ 1.5, .6 ]   
    }),
    beforeDestroy() {
      if(this.$tween)
        this.$tween.kill()
      this.$camera.position = new Vector3(0, 0, 0)
      this.$processing.vignetteEnabled = false
    },
    created() {
      this.$engine = this.$parent.$engine
      this.$camera.rotation.x = 0 * (Math.PI/180)
      this.$camera.fov = this.fov
      this.$target.position.y = 1.5
      this.$target.position.z = -.5
    },
    mounted() {
      console.log('Camera Portapak');
      
      this.$store.commit('user/SET_FINAL_CAMERA', 'round')
      this.$nextTick(()=>{
          // this.animation.speedRatio = 1.6
          this.$store.commit('user/SHOW_AVATAR', false)
          this.muteAnimation(false)
          this.updateCanvas()
          this.addPostProcess()
          this.addMotion()
          TweenMax.delayedCall(_.random(4,8), ()=> this.addZoom())
      })              
    },
    methods: {

      addZoom() {
        const zoom = { x: 1.5 }
        TweenMax.to(zoom, 2, { 
            x: 1.2,
            ease: Cubic.easeOut,
            onUpdate: ()=>{
                this.$camera.fov = zoom.x
            }
        })

        TweenMax.to(zoom, _.random(2,4), { 
            x: 1.5,
            onUpdate: ()=>{
                this.$camera.fov = zoom.x
            },
            ease: Cubic.easeIn,
            delay: 2
        })
      },



      addPostProcess() {
        const baw = new BABYLON.BlackAndWhitePostProcess("baw", 1.0, this.$camera)
        const blur = new BABYLON.BlurPostProcess("blur", new Vector2(1.0, 1.0), 1.0, 1.0, this.$camera)
              blur.kernel = 10
        
        const grain = new BABYLON.GrainPostProcess("grain", 1, this.$camera)
              grain.intensity = 10
              grain.animated = true   

        const tone = new BABYLON.TonemapPostProcess("tone", BABYLON.TonemappingOperator.Photographic, 1, this.$camera)
              tone._options = 1

        this.$processing = new BABYLON.ImageProcessingPostProcess("processing", 1.0, this.$camera)
        this.$processing.vignetteEnabled = false    
      },
      addMotion() {
        this.$tween = new TimelineMax({})
        for(let i=0; i<10; i++){
          this.$tween.to(this.$camera, _.random(2, 3), { 
              babylon: { 
                  x: _.random(-.1, .1),
                  y: _.random(-.25, .25),
                  z: _.random(0, .2),
              }, 
              yoyo: true, 
              ease: _.random(1,2) == 2 ? Cubic.easeOut : Linear.easeNone,
              repeat: _.random(1,3) , 
              // delay: _.random(.5,2) 
          })
          this.$tween.to(this.$camera, _.random(.2,1), { 
              babylon: { 
                  x: _.random(-.1, .1),
                  y: _.random(-.1, .1),
              } 
          })
        }
      },

    },
    
}
</script>


