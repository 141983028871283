
<script>

import * as dat from 'dat.gui'
import { Viewport, Vector2 } from 'babylonjs'
import 'babylonjs-loaders'

export default {

  name: 'DatGui',
  render: h => h(),
  props: ['options'],
  beforeDestroy() {
    if(this.$gui) {
      let el = document.getElementById('datGUI')
          el.parentNode.removeChild(el)
    }   
  },

  mounted() {

    console.log('GUI');

    this.$gui = new dat.GUI() 
    this.$gui.domElement.style.marginTop = "100px"
    this.$gui.domElement.id = "datGUI"

    let opts = {}

    _.each(this.options.postProcess, (p)=>{
      this['$folder_'+ p.name] = this.$gui.addFolder(p.name)
      // this['$folder_'+ p.name].open()
      _.each(p.options, (o)=>{
        const change = (o.change) ? o.change : 'onFinishChange'
        const attr = p.name +'_'+ o.name
        opts[o.name] = o.val
        if(o.type == 'direction')
          this['$folder_'+ p.name].add(opts, o.name, o.range[0], o.range[1]).onChange(function (v) {
            p.target.direction[o.name] = v 
          })
        else if(o.type == 'boolean')
          this['$folder_'+ p.name].add(opts, o.name).onChange(function (v) {   
            if(o.name == 'enable') {
              if(v==false) {
                console.log('enable : '+ p.name);
                p.target.dispose()
              }
              else {
                switch(p.name){
                  case 'grain':
                    p.target = new BABYLON.GrainPostProcess("grain", 1.0, p.camera)
                    p.target.animated = true
                    break
                  case 'black_and_white':
                    p.target = new BABYLON.BlackAndWhitePostProcess("baw", 1.0, p.camera)
                    break
                  case 'blur':
                    p.target = new BABYLON.BlurPostProcess("blur", new Vector2(1.0, 1.0), 1.0, 1.0, p.camera)
                    break
                  case 'tone':
                    p.target = new BABYLON.TonemapPostProcess("tone", BABYLON.TonemappingOperator.Photographic, 1, p.camera)
                    break

                  // case 'processing':
                  //   p.target = new BABYLON.ImageProcessingPostProcess("processing", 1.0, p.camera);
                  //   break
                }
              }
            }
            else  
              p.target[o.name] = v 
          })
        else if(o.type == 'integer')
          this['$folder_'+ p.name].add(opts, o.name).onChange(function (v) {
            p.target[o.name] = (v) ? 1.0 : 0.0
          })
        else 
          this['$folder_'+ p.name].add(opts, o.name, o.range[0], o.range[1])[change]((v)=> {
            p.target[o.name] = v 
          }) 
      })
    })

    console.log(opts);
  }
}

</script>























