import { FreeCamera, Camera, Vector3, Vector2, Viewport } from 'babylonjs'
import 'babylonjs-loaders'

export default {
  props: ['position'],
  beforeDestroy() {
    this.$camera.dispose()
    this.$target.position.y = 2
    this.$target.position.z = 0
    this.$store.commit('user/SHOW_AVATAR', true)
    this.resetCanvas()
    this.muteAnimation(false)
  },
  created() {
    this.$scene = this.$parent.$scene
    this.$target = this.$scene.getMeshByName('cameraman_lens')
    // this.$target.position.y = 2
    // this.$target.position.z = 0
    this.$camera = new FreeCamera(this.name, new Vector3(0, 0, 0), this.$scene)
    this.$camera.parent = this.$target

  },
  mounted() {
    this.$parent.addActiveCamera(this.$camera, this.resetCanvas)
    this.$cameraman = this.$scene.getMeshByName('cameraman')
    this.$camera_target = this.$scene.getMeshByName('camera_target')
    this.$cameraman.lookAt(this.$camera_target.position)
  },
  methods:{
    
    getViewportPos(bounding) {
      const min = 0
      const pos = {}
      _.each(['x', 'y', 'width', 'height'],  type =>{
        const max = type == 'x' || type == 'width' ? window.innerWidth : window.innerHeight
        let val = bounding[type]
        if(type == 'y')
          val = window.innerHeight - val - bounding.height
        pos[type] = (val - min) / (max - min)
      })
      return pos
    },

    resetCanvas() {
      this.$store.commit('user/SET_FINAL_CAMERA', '')
      TweenMax.set('.Canvas', { 
          x: 0, 
          y: 0, 
          width: window.innerWidth, 
          height: window.innerHeight,
          opacity: 0
      })
      this.$engine.resize()
    },

    updateCanvas() {
      const bounding = this.$parent.$refs[this.viewport].getBoundingClientRect()
      const vs = this.getViewportPos(bounding)
      TweenMax.set('.Canvas', { 
          x: window.innerWidth * vs.x, 
          y: window.innerHeight * vs.y, 
          width: Math.round(window.innerWidth * vs.width), 
          height: Math.round(window.innerHeight * vs.height),
          opacity: 1
      })
      this.$engine.resize()
    },
    muteAnimation(b) {
      console.log('muteAnimation '+ b);
      this.$store.commit('user/ANIMATION_MUTED', b)
    },
  },
  computed: {
    viewport() { return this.$store.getters['user/viewport'] },
    // animation() { return this.$store.getters['user/animation'] },
  }
}







