<template>
  <button @mouseenter="mouseenter" @mouseleave="mouseleave" @click="onClick" class="BtnSlider" :class="['is-' + type]" :aria-label="trans(type)">
    <div ref="circles" class="BtnSlider__circle" v-for="n in 4" :class="['is-' + n]" />
    <!--
    <svg viewBox="0 0 40 10.1">
      <polygon class="st0" points="40,4.1 8,4.1 8,0 0,5.1 8,10.1 8,6.1 40,6.1"/>
    </svg> 
    -->

    <svg class="BtnSlider__arrow" viewBox="0 0 60.928 17.337">
      <g id="Group_40" data-name="Group 40" transform="translate(-1296.5 -446.663)">
        <path id="Path_43" data-name="Path 43" class="cls-1" d="M3890.8,3386l7.832,11.977,7.832-11.977" transform="translate(-2042.376 4353.965) rotate(-90)"/>
        <line id="Line_7" data-name="Line 7" class="cls-1" x1="59" transform="translate(1296.5 455.5)"/>
      </g>
    </svg>

    <h6 class="BtnSlider__txt" :class="['is-' + type]">{{ trans(type) }}</h6>

  </button>
</template>

<script>
export default {
  name: 'BtnSlider',
  props: ['type'],
  translations: {
    en: {
      right: 'Next',
      left: 'Prev'
    },
    fr: {
      right: 'Suivant',
      left: 'Précèdent'
    },
  },
  mounted() {
    this.$tl = new TimelineMax({ paused: true })
    this.$tl.staggerTo(this.$refs.circles, .3, { scale: 1.3 }, .05)
  },
  methods: {
    onClick() {
      this.$store.commit('user/SET_SOUND', 'fleche_click')
      this.$emit('click')
    },
    mouseenter() {
      this.$store.commit('user/SET_SOUND', 'fleche_hover')
      this.$tl.play()
    },
    mouseleave() {
      this.$tl.reverse()
    }
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.BtnSlider
  position absolute
  top 50%
  transform translateY(-50%)
  display block
  z-index 99
  cursor pointer
  pointer-events all

  &:hover 
    .BtnSlider__circle.is-2
      opacity 1
    .BtnSlider__txt
      transform translateX(0px)
      opacity 1

  & h6 
    font-weight bold

  &.is-left
    left 0
    svg
      left 5px

  &.is-right
    right 0
    svg
      right 5px
      transform translateY(-50%) scaleX(1)

  svg
    fill blue-dark
    width 40px
    height 10px
    position absolute
    top 50%
    transform translateY(-50%) scaleX(-1)

  &__txt 
    position absolute
    top 0%
    color blue-dark
    opacity 0
    transition opacity .5s, transform .5s

    &.is-left
      top -24px
      left 24px
      transform translateX(16px)

    &.is-right
      top -24px
      right 24px
      transform translateX(-16px)


  &__circle
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    border-radius 100px
    width 50px
    height 50px
    background orange

    &.is-2
      width 195px
      height 195px
      opacity 0
      background orange
      transition opacity .5s

    &.is-3
      width 95px
      height 95px
      opacity .5
      background radial-gradient(rgba(orange), rgba(orange, 0))

    &.is-4
      width 155px
      height 155px
      opacity .5
      background radial-gradient(rgba(orange), rgba(orange, 0))

  .cls-1 
    fill none
    stroke blue-dark
    stroke-width 2px

</style>
