<template>
  <div class="Cameras" :class="[ 'is-' + $route.meta.name ]">

    <!--  
    <div class="Cameras__timeline" ref="timeline" :style="{ width: timeline.width + 'px' }">
      <div class="Cameras__timeline-spot"
        :class="{ 'is-active': camera.id == cam.id }"
        v-for="cam in cameras"
        :key="cam.id"
        @click="goToCamera(cam)" :style="{ left: getSpotXPosition(cam) + 'px' }">
        <div class="Cameras__timeline-date">{{ cam.date }}</div>
        <div class="Cameras__timeline-name">{{ cam[lang].name }}</div>
      </div>
      <div class="Cameras__timeline-drag" ref="drag" />
    </div> 
    -->

    <div ref="mouse" class="Cameras__mouse">
      <img :src="require('@/commons/img/icon_mouse.png')">
    </div>

    <div ref="icon_360" class="Cameras__icon_360">
      <img :src="require('@/commons/img/icon_360.png')">
    </div>

    <template v-if="$route.meta.name == 'experience'">
      <div class="Cameras__left" v-if="camera">

        <div class="Cameras__timeline" ref="timeline" :style="{ width: timeline.width + 'px' }">
          <div class="Cameras__timeline-spot"
            :class="{ 'is-active': camera.id == cam.id }"
            v-for="cam in cameras"
            :key="cam.id"
            @click="clickOnSpot(cam)" :style="{ left: getSpotXPosition(cam) + 'px' }">
            <div class="Cameras__timeline-date">{{ cam.date }}</div>
            <!-- <div class="Cameras__timeline-name">{{ cam[lang].name }}</div> -->
          </div>

          <div class="Cameras__timeline-drag" ref="drag" />
        </div>
    
        <!-- <div class="Cameras__title">{{ camera[lang].name }}</div> -->
        <!-- <div class="Cameras__description" v-html="camera[lang].description" /> -->

        <div class="Cameras__subs">
          <h3 ref="subtitle">{{ camera[lang].name }}</h3>
          <h4 ref="description" v-html="camera[lang].description" />
        </div>
        
        <!-- 
        <router-link 
          ref="button" :aria-label="trans('btn')" 
          v-if="camera.id == 86" 
          @click.native="onClick(camera[lang].slug)"
          to=""
          class="Btn">
          {{ trans('btn') }} 
        </router-link>
        <span v-else class="Btn is-inactive">{{ trans('btn') }}</span> 
        -->

      </div>
      <div class="Cameras__right" v-if="camera">

        <div ref="right" class="Cameras__info" v-for="(info, index) in camera.infos" :key="index">
          <div class="Cameras__info-left Vlazy" v-if="info.icon" v-lazy:background-image="info.icon.sizes.large" />
          <div class="Cameras__info-right">
            <h5>{{ info[lang].title }}</h5>
            <h6>{{ info[lang].text }}</h6>
          </div>
        </div> 

        <!-- <router-link> -->   
        <!--  <BtnGeneral :txt="trans('btn')" @click="onClick(camera[lang].slug)" class="Cameras__btn-general" :class="{ 'is-inactive': camera.id != 86 }"/> -->
        <!-- </router-link> -->

      </div>
    </template>

    <template v-if="$route.meta.name == 'cameras'">
      <div class="Cameras__camera-page" v-if="camera">
        <div class="Cameras__camera-page-title" v-if="camera[lang]">{{ camera[lang].name }}</div>
        <div class="Cameras__camera-page-subtitle" v-if="camera.page" v-html="camera.page[lang].subtitle" />
      </div>
      <CtaScroll />
    </template>

    <BtnSlider ref="slide_left" type="left" @click="goToCamera(prev)" />
    <BtnSlider ref="slide_right" type="right" @click="goToCamera(next)" />

    <CameraToolip v-for="(tooltip, index) in camera.tooltips" :key="index" :camera="camera" :tooltip="tooltip" v-if="is_ready && use_tooltip"/>

    <div class="Page__header">    
      <h1 ref="title">{{ trans('title') }}</h1>
    </div>

    <div class="Page__footer">     
      <router-link 
          :aria-label="trans('btn')" 
          @click.native="onClick(camera[lang].slug)"
          to=""
          class="Btn">
          <BtnGeneral id="button_test" ref="button" :txt="trans('btn')" :class="{ 'is-inactive': camera.id == 860 }"/>
      </router-link>   
    </div>

  </div>
</template>

<script>
import { Mesh, StandardMaterial, Color3, Texture, ActionManager, ExecuteCodeAction, Matrix } from 'babylonjs'
import CameraToolip from './CameraToolip'
import { findClosest, findClosestIndex } from 'find-closest'
import MouseSpeed from 'mouse-speed'
import CtaScroll from '@/components/cta-scroll/CtaScroll'
import BtnSlider from '@/components/btn-slider/BtnSlider'
import BtnGeneral from '@/components/assets/BtnGeneral'
import Settings from '@/config/Settings'

export default {
  name: 'Cameras',
  components: { CameraToolip, CtaScroll, BtnSlider, BtnGeneral },
  beforeDestroy() {
    if(this.$tween)
      this.$tween.kill()
    if(this.$sim)
      this.$sim.kill()
    this.$containerMaster.dispose()
    this.$camera.attachControl(document.querySelector('canvas'))
    if(this.$tween_audio) this.$tween_audio.kill()
    const canvas = document.querySelector('canvas')
    canvas.removeEventListener("pointerdown", this.onPointerDown)
    canvas.removeEventListener("pointerup", this.onPointerUp)
    canvas.removeEventListener("pointerleave", this.onPointerUp)
    canvas.removeEventListener("pointermove", this.onPointerMove)
    // this.$mouseSpeedX.destroy(this.onMouseSpeed)
    // this.$mouseSpeedY.destroy(this.onMouseSpeed)
    document.removeEventListener('keydown', this.onKeyDown)
  },
  data: () => ({
    camera: null,
    drag: false,
    face: 'front',
    mouse: { x: 0, y: 0, direction: { vertical: null, horizontal: null }, speedY: 0, speedX: 0 },
    timeline: {
      width: 0,
      multiplier: 3.3
    },
    choice: null,
    is_ready: false,
    use_tooltip: false,
    locked: false,
    camera_scale: {
      86: { name: "CameraCinematographe", scale: 15, y: 2, rotation: 180 },
      52: { name: "CameraBellHowell", scale: 4, y: 2, rotation: 135 }, 
      56: { name: "CameraBolex", scale: .9, y: 2.5, rotation: -135 }, 
      96: { name: "CameraPortapak", scale: .9, y: 2.5, rotation: 45 }, 
      94: { name: "CameraAaton", scale: 1, y: 2, rotation: -135 },
      88: { name: "CameraGoPro", scale: 3, y: 2, rotation: 90 },
    },
    camera_index: {
      plongeon: { index: 0 },
      amoureux: { index: 1 }, 
      jeu: { index: 2 },
      manifestation: { index: 3 }, 
      parade: { index: 4 }, 
    },
    rotations: {}
  }),
  computed: {
    cameras() { return this.$store.getters['data/cameras'] },
    len() { return this.cameras.length },
    index() { return _.findIndex(this.cameras, camera => this.camera.id == camera.id) },
    next() { return this.cameras[(this.index+1)%this.len]},
    prev() { return this.cameras[(this.index+this.len-1)%this.len]},
    prev_container() { return this.$store.getters['user/prev_container'] },
    changed() { return this.$store.getters['user/changed'] },
  },
  translations: {
    en: {
      btn: 'Choose this one',
      next: 'Next',
      prev: 'Prev',
      title: 'Select a camera'
    },
    fr: {
      btn: 'Choisir',
      next: 'Suivant',
      prev: 'Précèdent',
      title: 'Choisis ta caméra'
    },
  },
  watch: {
    camera() {
      this.updateTooltips(false)
      this.$nextTick(()=>{
        this.$emit('cameraChanged', this.camera)
        TweenMax.to(this.$refs.drag, 1, { x: this.getSpotXPosition(this.camera) })
      })

    },
    'mouse.x'(newX, oldX) {

      this.$mouseSpeedX.calcSpeed({ clientX: this.mouse.x, clientY: this.mouse.y })
      const speed = Math.abs(this.$mouseSpeedX.speedX) / 50

      // const perc = ((270/window.innerWidth)*Math.abs(newX-oldX))/Math.PI
      // if(perc > 3) perc = 0

      const direction = newX > oldX ? 'right' : 'left'
      if(direction == 'right')
        TweenMax.to(this.camera.$mesh.rotation, .5, { y: '-=' + speed })
      if(direction == 'left')
        TweenMax.to(this.camera.$mesh.rotation, .5, { y: '+=' + speed })
    },
    'mouse.y'(newY, oldY) {
      this.$mouseSpeedY.calcSpeed({ clientX: this.mouse.x, clientY: this.mouse.y })
      const speed = Math.abs(this.$mouseSpeedY.speedY) / 50
      const direction = newY < oldY ? 'up' : 'down'
      this.moveMeshVerticaly(direction, speed)
    },
  },
  created: async function() {

    // if(this.prev_container) {
    //   this.prev_container.dispose()
    //   this.$store.commit('user/PREV_CONTAINER', null)
    // }

    this.timeline.width = (this.cameras[this.cameras.length-1].date - this.cameras[0].date) * this.timeline.multiplier
    this.$meshes = []
    this.$childmeshes = []
    this.$camera = this.$parent.$babylon.camera
    // this.camera = this.cameras[0]
    this.camera = this.cameras[this.camera_index[Settings.scene_translations[this.lang][this.$route.params.scene]].index]
    this.$scene = this.$parent.$babylon.scene


    TweenMax.to(this.$camera, 1, { babylon: { rotation: 0, x: 0, y: 2, z: 0 } })

    if(this.$route.meta.name == 'cameras')
      TweenMax.to(this.$camera, 1, { babylon: { rotation: 0, x: -2, y: 2, z: 0 } })

    this.$containerMaster = new Mesh('camera_container_master', this.$scene)
    this.$container = new Mesh('camera_container', this.$scene)
    this.$container.parent = this.$containerMaster
    
    TweenMax.set(this.$containerMaster, { babylon: { rotationY: -90 } })

    const defaultMaterial = new StandardMaterial('default_material', this.$scene)
          defaultMaterial.diffuseColor = Color3.FromHexString('#222222')

    _.each(this.cameras, (camera, index) => {

      const mesh = new Mesh('camera_' + index, this.$scene)
      const meshCamera = camera['babylon'].instantiateModelsToScene().rootNodes[0]
            meshCamera.parent = mesh

      camera['angle'] = index * (360 / this.cameras.length)

      // const pos = this.getPointAroundCircle(camera['angle'], 10)

      const pos = { x: 10, z: 0 }
      
      TweenMax.set(meshCamera, { babylon: { scale: this.camera_scale[camera.id]['scale'] } })
      TweenMax.set(mesh, { 
        babylon: { 
          ...pos, 
          y: this.camera_scale[camera.id]['y'], 
          rotationY: (index*60)+this.camera_scale[camera.id]['rotation'] 
        }})

      const alphaMaterial = new StandardMaterial('alpha_material', this.$scene)
            alphaMaterial.alpha = 0.2

      // if(camera.id != 86)
      //   _.each(mesh.getChildMeshes(), m => m.material = defaultMaterial)
      // else

        _.each(mesh.getChildMeshes(), (m)=>{
          m.visibility = 0
          this.$childmeshes.push(m)
        }) 

      this.rotations[camera.id] = _.clone(mesh.rotation)
      mesh.parent = this.$container
      camera['$mesh'] = mesh
      this.$meshes.push(mesh)
    })

    const canvas = document.querySelector('canvas')
    canvas.addEventListener("pointerdown", this.onPointerDown)
    canvas.addEventListener("pointerup", this.onPointerUp)
    canvas.addEventListener("pointerleave", this.onPointerUp)
    canvas.addEventListener("pointermove", this.onPointerMove)

    // this.goToCamera(this.camera)

  },
  mounted() {

    // document.addEventListener('keypress', this.onKeyPress)

    TweenMax.set('.Canvas',{ opacity: 1 })
    this.$camera.detachControl(document.querySelector('canvas'))
    this.$audio = this.getAudio('choix_de_camera')
    const delay = (this.changed == 0) ? 2 : 1
    this.$tween_audio = TweenMax.delayedCall(delay, ()=>{
      this.$store.commit('user/ADD_NARRATION', {  sound : this.$audio[this.lang].url, txt: this.$audio[this.lang].text })
    })

    this.$mouseSpeedX = new MouseSpeed()
    this.$mouseSpeedX.init(this.onMouseSpeed)
    this.$mouseSpeedY = new MouseSpeed()
    this.$mouseSpeedY.init(this.onMouseSpeed)

    // document.addEventListener('keydown', this.onKeyDown)

    this.$tween = TweenMax.delayedCall(.1, ()=>{ this.init3D() })
    this.$tween_intro = TweenMax.delayedCall(.3, ()=>{ this.addIntro() })

    // this.addIntro()

  },
  methods: {

    clickOnSpot(cam) {
      this.$store.commit('user/SET_SOUND', 'secondary_click')
      this.goToCamera(cam)
    },

    updateTooltips(b) {
      if(this.$twn_tooltip)
        this.$twn_tooltip.kill()
      this.$twn_tooltip = TweenMax.delayedCall(.25, ()=>{
        this.use_tooltip = b
      })
    },

    addSimulation() {

      if(this.locked)
        return

      const speed = .75

      this.$sim = new TimelineMax({ delay: .25 })
          .set(this.$refs.icon_360, { x: window.innerWidth*.60, y: window.innerHeight*.70, scale: .5 })
          .set(this.$refs.mouse, { x: window.innerWidth*.5-20, y: window.innerHeight*.5-30 })
          .set({}, { delay: .25 })

          .to(this.$refs.icon_360, .5, { alpha: 1, scale: 1, ease: Power2.easeOut })
          .to(this.$refs.mouse, .25, { alpha: 1 })

          .set({}, { delay: .25 })
          .to(this.$refs.mouse, speed, { x: '+='+ window.innerWidth*.1 , ease: Power2.easeOut })
          .set({}, { delay: .15 })
          .to(this.$refs.mouse, .15, { scale: .9 })
          .to(this.$refs.mouse, speed, { x: '-='+ window.innerWidth*.2 , ease: Power3.easeOut, 
            onStart: ()=>{
              TweenMax.to(this.camera.$mesh.rotation, 2, { y: '+='+ 120 * (Math.PI/180), ease: Power3.easeOut })
            }
          })
          .to(this.$refs.mouse, .15, { scale: 1 })
          .to(this.$refs.mouse, speed, { x: '+='+ window.innerWidth*.2 , ease: Power2.easeOut })
          .to(this.$refs.mouse, .15, { scale: .9 })
          .to(this.$refs.mouse, speed, { x: '-='+ window.innerWidth*.2 , ease: Power3.easeOut,
            onStart: ()=>{
              TweenMax.to(this.camera.$mesh.rotation, 2, { y: '+='+ 120 * (Math.PI/180), ease: Power3.easeOut })
            }
          })
          .to(this.$refs.mouse, .15, { scale: 1 })
          .to(this.$refs.mouse, speed, { x: '+='+ window.innerWidth*.2 , ease: Power2.easeOut })
          .to(this.$refs.mouse, .15, { scale: .9 })
          .to(this.$refs.mouse, speed, { x: '-='+ window.innerWidth*.2 , ease: Power3.easeOut, 
            onStart: ()=>{
              TweenMax.to(this.camera.$mesh.rotation, 2, { y: '+='+ 120 * (Math.PI/180), ease: Power3.easeOut })
            }
          })
          .to(this.$refs.mouse, .15, { scale: 1 })
          .set({}, { delay: .25 })
          .call(() => { this.onComplete() })
    },

    onComplete() {
      TweenMax.to(this.$refs.mouse, .25, { alpha: 0 })
      TweenMax.to(this.$refs.icon_360, .25, { alpha: 0, scale: .5, delay: .5 })
    },

    removeSimulation() {
      this.locked = true
      if(this.$sim) {
        this.$sim.kill()
        this.onComplete()
        this.$sim = null
      }
    },

    onKeyPress(event) {
      this.addSimulation()
    },

    addIntro() {
      
      this.$nextTick(()=>{
        _.each(this.$meshes, (mesh, index)=>{
          const angle = index * (360 / this.cameras.length)
          const pos = this.getPointAroundCircle(angle, 10)
          TweenMax.set(mesh, { babylon: { ...pos }})
        })

        const dest = this.camera.angle == 0 ? 0 : 360-this.camera.angle
        TweenMax.set(this.$container.rotation, { directionalRotation: { y: (dest+540 * (Math.PI/180)) + '_short', useRadians: true } })
        
        // TweenMax.set(this.$container, { babylon: { z: -50 }})

        this.$tl = new TimelineMax({ paused: true })  
        this.$tl.add([
              TweenMax.to(this.$container.rotation, 4, {
                directionalRotation: { 
                  y: (dest * (Math.PI/180)) + '_short', 
                  useRadians: true 
                },
              }),
              // TweenMax.to(this.$container, 4,{ babylon: { z: 0 } })
            ]) 

        this.$tl.call(() => { this.updateTooltips(true) })
        this.$tl.staggerFrom([ 
            this.$refs.title, this.$refs.subtitle, 
            this.$refs.description, 
            this.$refs.right, 
            this.$refs.button.$el 
          ], .5, { y: 75, opacity: 0, ease: Cubic.easeOut }, .25)

        this.$tl.from([this.$refs.slide_right.$el ], .5, { x: 100, opacity: 0, ease: Cubic.easeOut })
        this.$tl.from([this.$refs.slide_left.$el ], .5, { x: -100, opacity: 0, ease: Cubic.easeOut })
        this.$tl.from([this.$refs.timeline], .75, { opacity: 0, ease: Cubic.easeOut })

        this.$tl.call(() => { this.addSimulation() })
        this.$tl.play()

        // TweenMax.delayedCall(1.5, ()=>{
        //   this.$tl.play()
        // })
      })
    },

    onClick(choice) {
      this.$store.commit('user/USE_TRANSITION', { camera: choice })
    },

    showChildMeshes() {
      _.each(this.$childmeshes, m => m.visibility = 1)
    },

    init3D() {
      this.showChildMeshes()
      this.is_ready = true
    },

    // enter(el, done) { 
    //   console.log('enter : camera '); 
    //   TweenMax.from(el, 1, { alpha: 0, delay: 5, onComplete: ()=>{ 
    //     console.log('complete camera');
    //     done() 
    //   } })

    //   // TweenMax.from(el, 1, { alpha: 0, delay: 1.5, onComplete: ()=>{ done() } })
    //   // TweenMax.from(this.$refs.timeline, 1, { alpha: 0, delay: 2 })
    // },
    
    // leave(el, done) {
    //   TweenMax.delayedCall(.75, done) 
    // },

    onMouseSpeed() {},
    onKeyDown(e) {
      if(document.activeElement.type == 'range' && document.activeElement.autocomplete == 'off')
        return
      this.findFace()
      // left
      if(e.keyCode == 37)
        TweenMax.to(this.camera.$mesh.rotation, .5, { y: '+=.3' })

      // right
      if(e.keyCode == 39)
        TweenMax.to(this.camera.$mesh.rotation, .5, { y: '-=.3' })

      // up
      if(e.keyCode == 38)
        this.moveMeshVerticaly('up', .3)

      // down
      if(e.keyCode == 40)
        this.moveMeshVerticaly('down', .3)
    },
    onPointerDown() { this.drag = true },
    onPointerUp() { this.drag = false },
    onPointerMove(e) {
      if(!this.drag) return

      this.mouse.x = e.x
      this.mouse.y = e.y
      this.findFace()
      this.removeSimulation()
    },
    moveMeshVerticaly(direction, speed) {
      let axys = this.face == 'front' || this.face == 'back' ? 'z' : 'x'
      let operator = '+='

      if(direction == 'up' && (this.face == 'back' || this.face == 'right'))
        operator = '-='

      if(direction == 'down' && (this.face == 'front' || this.face == 'left'))
        operator = '-='

      const t = {}
      t[axys] = operator + speed
      TweenMax.to(this.camera.$mesh.rotation, .5, t)
    },
    findFace() {
      let degree = this.camera.$mesh.rotation.y * (180/Math.PI)
      degree = degree % 360
      if(degree < 0) degree += 360

      const face = [
        { face: 'back', angle: 0 },
        { face: 'back', angle: 330 },
        { face: 'front', angle: 180 },
        { face: 'left', angle: 90 },
        { face: 'right', angle: 280 }
      ]

      this.face = findClosest(face, degree, ({ angle }) => angle).face
    },
    goToCamera(cam) {
      this.camera = cam
      TweenMax.to(this.$container.rotation, 2, {
        directionalRotation: { y: (-cam.angle * (Math.PI/180)) + '_short', useRadians: true },
        ease: Expo.easeOut,
        onComplete: ()=>{ this.updateTooltips(true) }
      })

      TweenMax.to(this.camera.$mesh.rotation, 2.25, {
        x: this.rotations[this.camera.id].x,
        y: this.rotations[this.camera.id].y,
        z: this.rotations[this.camera.id].z,
        ease: Expo.easeOut,
        delay: .25
      })
    },
    getSpotXPosition(cam) {
      return (cam.date - this.cameras[0].date) * this.timeline.multiplier
    }
  },
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Cameras
  height 100vh
  pointer-events none

  &.is-cameras
    .Cameras__timeline
      top 6vw

  &__camera-page
    position absolute
    top 50%
    transform translateY(-50%)
    left 5vw
    text-align center
    color orange
    width 40vw
    text-align center

    &-title
      font-size 2vw
      line-height 2.5vw
    &-subtitle
      font-size 3vw
      line-height 3.5vw
      margin-top 5vw
      letter-spacing .2vw
      position relative
      &:before
        content ''
        position absolute
        left 50%
        width 1px
        height 3vw
        background orange
        top -4vw

  &__left
    position absolute
    top 25%
    left 10vw
    color orange
    width 25vw

    & h3
      padding-bottom 48px

    & h4 
      font-family montserrat

  &__right
    position absolute
    top 25%
    right 9vw
    text-align center
    color orange
    width 23vw

  & .Btn
    margin-top 68px
    background transparent
    border none

  &__subs 
    text-align center
    padding-top 48px

  &__info
    border-top 1px solid orange
    padding 1vw 0
    display flex
    
    min-height 64px
    
    &:last-child
      border-bottom 1px solid orange

    &-left
      // width 30%
      padding-right 6vw
      // height 4.5vw

      width 4.5vw
      min-width 64px
      max-width 120px
      height 4.5vw
      min-height 64px
      max-height 120px

      background-size contain
      background-repeat no-repeat
      background-position center left

    &-right
      width 60%
      text-align left
      font-size .8vw
      line-height 1vw
      padding-left 1vw

      display flex
      flex-direction column
      justify-content flex-start


      div
        &:first-child
          color orange
        &:last-child
          color pink
          margin-top .4vw


  &__description
    font-size 1.3vw
    font-family sourcecode
    line-height 1.7vw
    margin 20px 0

  &__title
    font-size 2vw
    line-height 2.4vw

  &__mouse 
    position absolute 
    pointer-events none
    top 0
    left 0
    width 42px
    opacity 0

  &__icon_360 
    position absolute 
    top 0
    left 0
    width 42px
    opacity 0

  &__timeline
    position relative
    height 2px
    background orange
    font-size .7vw
    z-index 999

    &-spot
      position absolute
      top 0px
      left 0px
      width 7px
      cursor pointer
      pointer-events all
      transition transform .35s

      &:before
        content ' '
        position absolute
        top -4px
        width 7px
        height 7px
        background blue-dark
        border-radius 5px
        border 2px solid orange
        background orange

      &:hover
        transform scale(1.4)

      &.is-active
        .Cameras__timeline-name
        .Cameras__timeline-date
          opacity 1

    &-date
      position absolute
      top -32px
      left 50%
      transform translateX(-50%)
      opacity 0
      transition opacity .5s
    &-name
      position absolute
      top 40px
      left 50%
      transform translateX(-50%)
      font-size .5vw
      white-space nowrap
      opacity 0
      transition opacity .5s
    &-drag
      position absolute
      left 0px
      width 31px
      height 31px
      margin-top -10px
      border-radius 15px
      box-shadow inset 0 0 15px rgba(orange)
      top -4px
      left -10px



</style>

