<template>
<div id="App">
  <Experience />
  <template v-if="loaded">

    <!-- <div tabindex="0"> -->

    <!-- <a
      @focus="skip = false"
      href="#App__hidden"
      :class="{ 'is-visible': false }"></a>

    <a
      id="App__skip"
      :aria-label="trans('skip')"
      @focus="skip = true"
      href="#App__content"
      :class="{ 'is-visible': skip }" v-html="trans('skip')"/>

    <a
      @focus="skip = false"
      href="#App__hidden"
      :class="{ 'is-visible': false }"></a> -->

    <Navigation/>
    <FullscreenVideo v-if="fullScreenVideo.isOpen" />
    <div id="App__content" tabindex="-1">
      <transition
        @enter="enter" @after-enter="afterEnter"
        @leave="leave" @after-leave="afterLeave"
        @before-enter="beforeEnter"
        :css="false" appear :mode="mode">
        <router-view ref="page" v-if="babylonReady" />
      </transition>
    </div>
  </template>
  <ScreenMask/>
  <Message/>
  <Sound/>
  <!-- <Loader/> -->

  <Loader v-if="!loaded || other_loading" :type="loader_type"/>
  <Responsive v-if="$root.width <= 1024 || $root.height <= 680"/>

  <a
      @focus="skip = false"
      href="#App__hidden"
      :class="{ 'is-visible': false }"></a>

    <a
      id="App__skip"
      :aria-label="trans('skip')"
      @focus="skip = true"
      href="#App__content"
      :class="{ 'is-visible': skip }" v-html="trans('skip')"/>

    <a
      @focus="skip = false"
      href="#App__hidden"
      :class="{ 'is-visible': false }"></a>




</div>
</template>

<script>
import Loader from '@/components/loader/Loader'
import Navigation from '@/components/navigation/Navigation'
import Transition from '@/core/mixins/Transition'
import Experience from '@/components/experience/Experience'
import FullscreenVideo from '@/components/fullscreen-video/FullscreenVideo'
import ScreenMask from '@/components/experience/ScreenMask'
import Message from '@/components/experience/Message'
import Responsive from '@/components/experience/Responsive'
import Sound from '@/components/sound/Sound'

export default {
  name: 'App',
  mixins: [ Transition ],
  components: { Loader, Navigation, Experience, FullscreenVideo, ScreenMask, Message, Sound, Responsive },
  data: () => ({
    babylonReady: false,
    loader_type: 'regular',
    skip: false,
    is_first: true
  }),
  translations: {
    fr: { 
      skip: "La section <strong>Tourne ton film</strong> n’est pas optimisée pour la navigation par clavier et les technologies d’assistance. Vous pouvez poursuivre l’expérience ou visiter les sections <a href='https://macameraetmoi.ca/decouvre-les-cameras/'>Découvre les caméras</a> et <a @focus='skip = false' href='https://macameraetmoi.ca/voyages-en-balado/'>Voyages en balado</a> qui sont tout aussi enrichissantes.", 
    },
    en: { 
      skip: "The <strong>Shoot your own Scene</strong> section is not optimized for keyboard navigation or assistive technology. You may either pursue the activity or visit the <a href='https://macameraetmoi.ca/discover-the-cameras/?lang=en'>Discover the cameras</a> et <a @focus='skip = false' href='https://macameraetmoi.ca/podcast-journeys/?lang=en'>Podcast Journeys</a> sections, which are also full of interesting information." 
    },
  },
  computed: {
    loaded() { return this.$store.getters['loader/loaded'] },
    fullScreenVideo() { return this.$store.getters['app/fullScreenVideo'] },
    other_loading() { return this.$store.getters['user/other_loading'] },
  },
  watch: {
    'other_loading'(nw){
      if(nw)
        this.loader_type = 'extends'
    }
  },
  created() {
    this.$babylon = {}
  },
  mounted() {
    window.addEventListener('keydown', (key)=>{
      if(key.code == 'Tab' && this.is_first) {
        this.is_first = false
        document.getElementById("App__skip").focus()
      }
    })
  },
  methods: {
    setBabylon(options) {
      this.$babylon = options
      this.babylonReady = true
    }
  }
}
</script>

<style lang="stylus">
@import '~reset-css/reset.css'
@import '~@/config/Settings'
@import '~@/commons/fonts/Fonts'
@import '~@/commons/stylus/Global'
@import '~@/commons/stylus/Btn'
@import '~@/commons/stylus/Page'
@import '~@/commons/stylus/Vlazy'

#App
  &__skip
    position absolute
    background yellow
    color blue    
    top .5rem
    left 50%
    z-index 999999
    opacity 0
    pointer-events none
    font-family Montserrat
    color #13125B
   
    max-width 32rem
    padding 2rem
    font-size 1.05rem
    margin-top 1.05em
    margin-bottom 1.05em
    tex-align center
    border-radius 0.375rem

    font-weight 600
    font-size 1.375rem
    line-height 1.5

    transform translate(-50%, -10vw)
    transition opacity .3s, transform .3s

    & strong 
      font-weight 900

    & a 
      color #3300FF
      text-decoration underline

    &.is-visible
      transform translate(-50%,  1vw)
      opacity 1

.NoJs
  background transparent
  opacity 1
  display flex
  flex-direction column
  padding 32px
  max-width 680px

</style>
