import _ from 'lodash'
import format from 'string-template'

export default {
  translations: {},
  computed: {
    lang() { 
      return this.$store.getters['app/lang'] 
    }
  },
  methods: {
    trans(namespace, locale, objFormat) {

      locale = locale || this.lang
      let data = this.$options.translations

      // on cherche dans le components
      let value = _.get(data[locale], namespace)

      // on cherche dans les locales global
      if(!value)
        value = _.get(this.$root.commonsTranslations, locale + '.' + namespace)

      // on a rien trouver, on retourn le namespace
      if(!value)
        value = ' '

      // si un object est donnée, on le format
      if(objFormat)
        value = format(value, objFormat)
    
      return value
    }
  }
} 
