<template>
  <DatGui :options="options" v-if="ready"/>
</template>

<script>

import DatGui from '@/components/dat/DatGui'  
import Mixin from '@/core/mixins/CameraFinal'
import { Viewport, Vector2 } from 'babylonjs'
import 'babylonjs-loaders'
import Settings from '@/config/Settings'

export default {

    name: 'CameraCinematographe',
    components: { DatGui },
    mixins: [ Mixin ],
    render: h => h(),
    data: ()=>({
        ready: false,
        options: {},
        fov: .8, // 10mm = 1.6 | 12.5mm = 1.5| 30mm  = 1.4| 35mm = 1.3| 50mm = .8 | 75mm = .6| 100mm = .4
        blur_kernel: 10.0,
        grain_intensity: 20.0,
        grain_animated: true,
        camera_fov: .8,
        processing_contrast: 1.0
    }),
    beforeDestroy() {
        if(this.$processing)
            this.$processing.vignetteEnabled = false
    },
    created() {
        this.$engine = this.$parent.$engine
        this.$camera.rotation.x = 15 * (Math.PI/180)
        this.camera_fov = this.fov
        if(Settings.scene_translations[this.lang][this.$route.params.scene] == 'jeu') {
            this.$nextTick(()=>{
                this.$target.position.z = -1.5
                this.$target.position.y = 2.6
            })
        }   
    },
    mounted() {

        // ORIGINAL 

        // console.log('CameraCinematographe');

        this.$store.commit('user/SET_FINAL_CAMERA', 'round')
        this.$nextTick(()=>{
            // this.animation.speedRatio = 1.6
            this.$store.commit('user/SHOW_AVATAR', false)
            this.muteAnimation(true)
            this.updateCanvas()
            this.addPostProcess()

            // return

            // const baw = new BABYLON.BlackAndWhitePostProcess("baw", 1.0, this.$camera)
            // const blur = new BABYLON.BlurPostProcess("blur", new Vector2(1.0, 1.0), 1.0, 1.0, this.$camera)
            //       blur.kernel = 5
            
            // const grain = new BABYLON.GrainPostProcess("grain", .8, this.$camera)
            //       grain.intensity = 50
            //       grain.animated = true   

            // const tone = new BABYLON.TonemapPostProcess("tone", BABYLON.TonemappingOperator.Photographic, 2, this.$camera)
            //       tone._options = .9


        

            // this.options.camera = this.$camera
            // this.options.postProcess = [
            //   {
            //     name: 'camera',
            //     target: this.$camera,
            //     options: [ 
            //       { name: 'fov', range: [0.1, 2.0], val: this.camera_fov, change: 'onChange' },
            //     ]
            //   },
            //   // {
            //   //   name: 'animation',
            //   //   target: this.$root.animation,
            //   //   options: [ 
            //   //     { name: 'speedRatio', range: [0.1, 5.0], val: 1.0, change: 'onChange' },
            //   //   ]
            //   // },
            //   // {
            //   //   name: 'processing',
            //   //   target: processing,
            //   //   camera: this.$camera,
            //   //   options: [ 
            //   //     { name: 'contrast', range: [0.1, 5.0], val: 1.0, change: 'onChange' },
            //   //     { name: 'exposure', range: [0.1, 50.0], val: 1.0, change: 'onChange' },
            //   //     { name: 'vignetteEnabled', val: false, type: 'boolean' },
            //   //     { name: 'vignetteWeight', range: [1.0, 250.0], val: 1.0, change: 'onChange' },
            //   //     { name: 'vignetteStretch', range: [1.0, 250.0], val: 1.0, change: 'onChange' },
            //   //   ]
            //   // },
            //   {
            //     name: 'black_and_white',
            //     target: baw,
            //     camera: this.$camera,
            //     options: [ 
            //       { name: 'enable', val: true, type: 'boolean' },
            //       { name: '_options', range: [0.001, 1.0], val: 1.0 },
            //     ]
            //   },
            //   // {
            //   //   name: 'blur',
            //   //   target: blur,
            //   //   camera: this.$camera,
            //   //   options: [ 
            //   //     { name: 'enable', val: true, type: 'boolean' },
            //   //     { name: 'kernel', range: [0.1, 500.0], val: this.blur_kernel },
            //   //     { name: 'x', range: [1, 500], val: 1, type: 'direction' },
            //   //     { name: 'y', range: [1, 500], val: 1, type: 'direction' },
            //   //   ]
            //   // },
            //   {
            //     name: 'grain',
            //     target: grain,
            //     camera: this.$camera,
            //     options: [
            //       { name: 'enable', val: true, type: 'boolean' },
            //       { name: 'intensity', range: [0.1, 500.0], val: this.grain_intensity },
            //       { name: 'animated', val: this.grain_animated, type: 'boolean' },
            //     ]
            //   },
            //   {
            //     name: 'tone',
            //     target: tone,
            //     camera: this.$camera,
            //     options: [ 
            //       { name: 'enable', val: true, type: 'boolean' },
            //       { name: '_options', range: [0.001, 1.0], val: 1.0 },
            //     ]
            //   },
              
            // ]

            // console.log('X');
            // this.ready = true

            })

    },

    methods: {

        addPostProcess() {
            
            // const baw = new BABYLON.BlackAndWhitePostProcess("baw", 1.0, this.$camera)
            //       baw._options = 0.8
            // const blur = new BABYLON.BlurPostProcess("blur", new Vector2(1.0, 1.0), 1.0, 1.0, this.$camera)
            //       blur.kernel = 5
            
            // const grain = new BABYLON.GrainPostProcess("grain", 1, this.$camera)
            //       grain.intensity = 40
            //       grain.animated = true   

            // const tone = new BABYLON.TonemapPostProcess("tone", BABYLON.TonemappingOperator.Photographic, 1, this.$camera)
            //       tone._options = 1

            this.$processing = new BABYLON.ImageProcessingPostProcess("processing", 1.0, this.$camera)
            this.$processing.vignetteEnabled = true
            this.$processing.vignetteWeight = 3
            this.$processing.vignetteStretch = 3 

            const baw = new BABYLON.BlackAndWhitePostProcess("baw", 1.0, this.$camera)
            const blur = new BABYLON.BlurPostProcess("blur", new Vector2(1.0, 1.0), 1.0, 1.0, this.$camera)
                  blur.kernel = 0
            
            // const grain = new BABYLON.GrainPostProcess("grain", .8, this.$camera)
            //       grain.intensity = 0
            //       grain.animated = true   

            const tone = new BABYLON.TonemapPostProcess("tone", BABYLON.TonemappingOperator.Photographic, 3, this.$camera)
                  tone._options = .5

            // this.$processing = new BABYLON.ImageProcessingPostProcess("processing", 1.0, this.$camera)
            // this.$processing.vignetteEnabled = true
            // this.$processing.vignetteWeight = 3
            // this.$processing.vignetteStretch = 3 
        },

    },
}

</script>























