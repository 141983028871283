<template>
  <div class="SlideComp">
    <transition @enter="enterNarratrice" @leave="leaveNarratrice">  
      <div class="SlideComp__img" v-if="slide == 0 && indice == 0">
        <img ref="background" :src="require('./images/background.png')" />
        <img ref="narratrice" :src="require('./images/narratrice.png')" class="SlideComp__narratrice"/>
      </div>
    </transition>
    <transition @enter="enter2" @leave="leave2">   
      <div ref="scenes" class="SlideComp__img scenes" v-if="slide == 1 && indice == 1">
        <img ref="sc1" :src="require('./images/sc1.png')"/>
        <img ref="sc2" :src="require('./images/sc2.png')"/>
        <img ref="sc3" :src="require('./images/sc3.png')"/>
        <img ref="sc4" :src="require('./images/sc4.png')"/>
        <img ref="sc5" :src="require('./images/sc5.png')"/>
      </div>
    </transition>
    <transition @enter="enter3" @leave="leave3">  
      <div class="SlideComp__img cameramans" v-if="slide == 2 && indice == 2">
        <img ref="cam_1" :src="require('./images/cam_1.png')"/>
        <img ref="cam_2" :src="require('./images/cam_2.png')"/>
        <img ref="cam_3" :src="require('./images/cam_3.png')"/>
        <img ref="cam_4" :src="require('./images/cam_4.png')"/>
        <img ref="cam_5" :src="require('./images/cam_5.png')"/>
        <img ref="cam_6" :src="require('./images/cam_6.png')"/>
      </div>
    </transition>
    <transition @enter="enter4" @leave="leave4">  
      <div class="SlideComp__img" v-if="slide == 3 && indice == 3">
        <img ref="background_4" :src="require('./images/background_4.png')" />
        <img ref="left_4" :src="require('./images/left_4.png')" class="SlideComp__left_4"/>
        <img ref="right_4" :src="require('./images/right_4.png')" class="SlideComp__right_4"/>
      </div>
    </transition>
    <transition @enter="enter5" @leave="leave5">  
      <div class="SlideComp__img" v-if="slide == 4 && indice == 4">
        <img ref="background_5" :src="require('./images/background_5.png')" />
        <img ref="last1" :src="require('./images/last1.png')" class="SlideComp__last middle"/>
        <img ref="last2" :src="require('./images/last2.png')" class="SlideComp__last middle"/>

        <img ref="plus" :src="require('./images/plus.png')" class="SlideComp__last bubble plus"/>
        <img ref="oreille" :src="require('./images/oreille.png')" class="SlideComp__last bubble oreille"/>
        <img ref="oeil" :src="require('./images/oeil.png')" class="SlideComp__last bubble oeil"/>
        <img ref="question" :src="require('./images/question.png')" class="SlideComp__last bubble question"/>
        <img ref="exclamation" :src="require('./images/exclamation.png')" class="SlideComp__last bubble exclamation"/>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'SlideComp',
  props: ['indice'],
  data: ()=>({
    od: 0
  }),
  computed: {
    slide() { return this.$store.getters['user/slide'] },
  },
  watch: {
    'slide'(nw, od) {
      this.od = od
    },
  },
  methods:{
    enterNarratrice(el, done) {
      this.$tween = new TimelineMax({ delay: .2})
          .add([
          TweenMax.staggerFrom([this.$refs.background, this.$refs.narratrice], .5, { 
            opacity: 0, 
            scale: .2,
            ease: Back.easeOut 
          }, .1)
        ])
      done()
    },
    leaveNarratrice(el, done) {
       this.$tween = new TimelineMax({ delay: 1 })
          .call(()=>done())   
    },
    enter2(el, done) {
      const angle = this.od == 0 ? 50 : -50
      const sens = this.od == 0 ? -20 : 20
      const order = this.od == 0 
            ? [this.$refs.sc1, this.$refs.sc2, this.$refs.sc3, this.$refs.sc4, this.$refs.sc5]
            : [this.$refs.sc5, this.$refs.sc4, this.$refs.sc3, this.$refs.sc2, this.$refs.sc1]
      this.$tween = new TimelineMax({ delay: .2})
          .add([
            TweenMax.from('.scenes', 1, {
              opacity: 0,
              rotation: '+='+ angle,
              transformOrigin: '50% 500%',
              ease: Back.easeOut
            }),
            TweenMax.staggerFrom(order, 1, { 
              x: sens,
              ease: Strong.easeOut,
              delay: .5
            }, .1)
          ])
      done()
    },
    leave2(el, done) {
      this.$tween = new TimelineMax({ delay: 1 })
          .call(()=>done())   
    },
    enter3(el, done) {
      this.$tween = new TimelineMax({ delay: .2})
          .add([
            TweenMax.staggerFrom([this.$refs.cam_1, this.$refs.cam_2, this.$refs.cam_4], .5, { 
              scale: .3,
              opacity: 0,
              ease: Back.easeOut,
            }, .2),
            TweenMax.staggerFrom([this.$refs.cam_6, this.$refs.cam_5, this.$refs.cam_3], 1, { 
              x: -20,
              opacity: 0,
              ease: Strong.easeOut,
            }, .2)
          ])
      done()
    },
    leave3(el, done) {
      this.$tween = new TimelineMax({ delay: 1 })
          .call(()=>done())   
    },
    enter4(el, done) {
      this.$tween = new TimelineMax({ delay: .2})
          .add([
            TweenMax.staggerFrom([this.$refs.left_4, this.$refs.right_4], .5, { 
              scale: .1,
              ease: Back.easeOut,
            }, .1)
          ])
      done()
    },
    leave4(el, done) {
      this.$tween = new TimelineMax({ delay: 1 })
          .call(()=>done())   
    },

    enter5(el, done) {
      this.$tween = new TimelineMax({ delay: .25 })
          .add([
          TweenMax.staggerFrom([this.$refs.last1, this.$refs.last2, this.$refs.oeil, this.$refs.question, this.$refs.exclamation, this.$refs.plus, this.$refs.oreille], .5, { 
            opacity: 0, 
            scale: .2,
            ease: Back.easeOut 
          }, .1)
        ])
      done()
    },
    leave5(el, done) {
       this.$tween = new TimelineMax({ delay: 1 })
          .call(()=>done())   
    },

  },
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.SlideComp
  color orange
  text-align center

  &__img
    position relative
    height 35vh

    &.cameramans 
    &.scenes
      display flex

    &.temporaire
      width 35vh
      border-radius 50%
      background rgba(#a5a5a5, .8)

    img
      display block
      height 100%

  &__narratrice
    position absolute
    top 0

  &__left_4
    position absolute
    top 0
    left 0

  &__right_4
    position absolute
    top 0
    right 0

  &__last
    position absolute
    top 0

    &.middle
      left 0
      top 0

    &.bubble
      height 25%

    &.plus 
      top 22%
      height 24%
      opacity .5

    &.oeil 
      top 38%
      height 40%
      left 7%
      opacity .8

    &.oreille
      top 12%
      left 18%
      height 32%
      opacity .8

    &.question
      right 4%
      top 40%
      opacity .3

    &.exclamation
      height 35%
      right 7%
      top 60%
      opacity .9

</style>
