<template>
  <div class="Responsive">
    <transition name="Responsive__container">
        <div class="Responsive__container"> 
          <div class="Responsive__row" v-html="trans('description')"/>  
        </div>  
    </transition>
  </div>
</template>

<script>

export default {
  name: 'Responsive',
  translations: {
    fr: {
      description: "Bonjour!</br>Nous avons détecté que tu souhaites accéder à cette section via une résolution d’écran non supportée.</br>Nous t'invitons à visiter les sections <a href='https://macameraetmoi.ca/decouvre-les-cameras/'>Découvre les caméras</a> et <a href='https://macameraetmoi.ca/voyages-en-balado/'>Voyage en balado</a> qui sont tout aussi enrichissantes."
    },
    en: {
      description: "Hi!</br>We noticed that you’re attempting to access this section with a screen resolution that is not supported.</br>We invite you to visit the <a href='https://macameraetmoi.ca/discover-the-cameras/?lang=en'>Discover the Cameras</a> and <a href='https://macameraetmoi.ca/podcast-journeys/?lang=en'>Podcast Journeys</a> sections, which are also full of interesting information."
    },
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Responsive
  position fixed
  z-index 10000000
  top 0
  left 0

  &__container 
    width 100vw
    height 100vh 
    background rgba(blue-dark, 1)
    display flex
    align-items center
    justify-content center
    opacity 1
    transition opacity .25s 
    transition-delay .25s

    z-index 1
  
    &-enter
    &-leave-active
      opacity 0

  &__row 
    width 600px
    line-height 2.82vw
    font-size 2.4vw
    margin 32px

</style>
















