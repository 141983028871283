<template>
  <div class="CtaScroll" :class="['is-' + color]">
    <div class="CtaScroll__txt ui" v-html="trans('txt')" />
    <div ref="arrow" class="CtaScroll__arrow">
      <svg viewBox="0 0 43.035 22.932">
        <path id="Path_41" data-name="Path 41" class="cls-1" :class="['is-' + color]" d="M3890.8,3386l20.81,20.81,20.81-20.81" transform="translate(-3890.095 -3385.293)"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CtaScroll',
  props: {
    color: { default: 'orange' }
  },
  translations: {
    en: { txt: 'Défile vers</br>le bas' },
    fr: { txt: 'Défile vers</br>le bas' },
  },
  beforeDestroy() {
    this.$tween.kill()
  },
  mounted() {
    TweenMax.from(this.$el, 1, { alpha: 0, delay: 1 })
    TweenMax.set(this.$refs.arrow, { y: 20 })
    this.$tween = TweenMax.to(this.$refs.arrow, 1, {
        y: 0,
        repeat: 4,
        yoyo: true,
        ease: Power2.easeInOut
    })
  },
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.CtaScroll
  position absolute
  top 86vh
  left 50%
  transform translate(-50%)
  display flex
  flex-direction column
  align-items center
  z-index 1000

  &__txt
    text-align center

  &__arrow
    width 40px
    padding-top 16px

  &.is-blue
    color blue

  &.is-blue-dark
    color blue-dark
  
  &.is-orange
    color orange

  .cls-1 
    fill none
    stroke blue-dark
    stroke-width 2px

    &.is-blue
      stroke blue

    &.is-blue-dark
      stroke blue-dark
  
    &.is-orange
      stroke orange

</style>
