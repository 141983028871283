<script>
import Mixin from '@/core/mixins/CameraFinal'
import { Vector2, Viewport } from 'babylonjs'
import 'babylonjs-loaders'

export default {
    name: 'CameraAaton',
    mixins: [ Mixin ],
    render: h => h(),
    data: ()=>({
        fov: 1.3, // 10mm = 1.6 | 12.5mm = 1.5| 30mm  = 1.4| 35mm = 1.3| 50mm = .8 | 75mm = .6| 100mm = .4
    }),
    beforeDestroy() {
        if(this.$tween)
            this.$tween.kill()
        this.$processing.vignetteEnabled = false
    },
    created() {
        this.$engine = this.$parent.$engine
        this.$camera.rotation.x = 0 * (Math.PI/180)
        this.$camera.fov = this.fov
        this.$target.position.y = 1.5
        this.$target.position.z = -.5
    },
    mounted() {

        console.log('Camera Aaton '+ this.fov);

        this.$nextTick(()=>{
            // this.animation.speedRatio = 1
            this.$store.commit('user/SHOW_AVATAR', false)
            this.muteAnimation(false)
            this.updateCanvas()
            this.addPostProcess()
            this.addMotion()
        })

        // new BABYLON.BlurPostProcess("Horizontal blur", new Vector2(1.0, 0), 100.0, 1.0, this.$camera)
    },
    methods: {
        addPostProcess() {
            this.$processing = new BABYLON.ImageProcessingPostProcess("processing", 1.0, this.$camera)
            this.$processing.vignetteEnabled = false
        },
        addMotion() {
            this.$tween = new TimelineMax({})
            for(let i=0; i<10; i++){
                this.$tween.to(this.$camera, 2, { 
                    babylon: { 
                        x: _.random(-.2, .2),
                        y: _.random(-.35, .35),
                        z: _.random(0, .1),
                    }, 
                    yoyo: true, 
                    ease: Linear.easeNone,
                    repeat: _.random(1,3) , 
                    // delay: _.random(.5,2) 
                })
                this.$tween.to(this.$camera, 1, { 
                    babylon: { 
                        x: _.random(-.15, .15),
                        y: _.random(-.15, .15),
                    } 
                })
            }
        },
    },
}
</script>


