<template>
  <div class="Experience">
    <canvas ref="canvas" class="Canvas" :class="final_camera"/>
  </div>
</template>

<script>

import Borders from './Borders'
import 'babylonjs-materials'
import { Engine, Scene, Color3 } from 'babylonjs'

export default {
  name: 'Experience',
  components: { Borders },
  beforeDestroy() {
    if(this.$scene)
      this.$scene.dispose()
  },
  computed: {
    final_camera() { return this.$store.getters['user/final_camera'] },
  },
  watch: {
    '$root.width'() { this.$engine.resize() },
    '$root.height'() { this.$engine.resize() },
    '$route': {
      immediate: true,
      handler() {
        this.$store.commit('user/CHANGED')
      }
    },
  },

  mounted() {

    const canvas = this.$refs.canvas
    const engine = new Engine(canvas, true, { preserveDrawingBuffer: true, stencil: true })
    const scene = new Scene(engine)

    scene.createDefaultCameraOrLight(false, true, true)
    scene.clearColor = new BABYLON.Color4(0, 0, 0, 0)
    // scene.clearColor = Color3.FromHexString('#0e0b5c')


    console.log(scene.lights);

    const camera = scene.activeCamera
    camera.position.z = 0

    engine.runRenderLoop(() => {
      if(this.$route.meta.name == 'experience')
        scene.render()
    })
    engine.resize()

    this.$engine = engine
    this.$parent.setBabylon({
      camera: camera,
      engine: engine,
      scene: scene
    })
  }
}
</script>

<style lang="stylus">
@import '~@/config/Settings'

.Experience
  width 100vw
  height 100vh
  z-index -1
  overflow hidden
  background-image url('./images/background.png')
  background-size cover
  background-position repeat repeat

  canvas
    width 100%
    height 100%

    &.round
      border-radius 40px

  &__origine 
    position absolute 
    top 0
    left 0 
    width 50% !important
    height 50% !important
    border 4px solid white

  &__mask
    width 100%
    height 100%
    position fixed
    top 0
    left 0
    background #13125b
    opacity 0
    pointer-events none

</style>























