export default {
  fr: {
    Global: {
      transcription: 'Texte de remplacement',
      plus: 'Plus',
      menu: {
        home: 'Accueil',
        experience: 'À toi de faire ton cinéma',
        cameras: 'En savoir plus sur les caméras',
        balados: 'Voyage audio',
        ressources: 'Ressources pédagogiques',
        about: 'À propos et crédits',
        credits: 'Crédits',
        sitemap: 'Plan du site',
        contact: 'Contactez-nous',
        share: 'Partager',
      }
    }
  },
  en: {
    Global: {
      transcription: 'Texte de remplacement',
      plus: 'More',
      menu: {
        home: 'Home',
        experience: 'make your movie',
        cameras: 'En savoir plus sur les caméras',
        balados: 'Audio travel',
        ressources: 'Ressources',
        about: 'About and Credits',
        credits: 'Credits',
        sitemap: 'Sitemap',
        contact: 'Contact us',
        share: 'Share',
      }
    }
  }
}
